import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import {
  MatSnackBar,
} from "@angular/material";
import {
  LocalStorageService,
} from "ngx-store";

@Injectable({
  providedIn: "root",
})
export class PosBackOfficeService {
  updatePassword(payload: {
    _id: any; newPassword: any;
    oldPassword: any;
  }) {
    throw new Error('Method not implemented.');
  }
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    public ls: LocalStorageService
  ) {
    const i = this.ls.get("token");
  }
  private posBackOfficeApi: string = environment.posBackOfficeServiceUrl;
  private posBackOfficeCloudApi: string = environment.posBackOfficeServiceCloudUrl;
  private AuthApi: any = environment.authServiceUrl;
  private BaseApi: any = environment.authBaseUrl;
  public printApi: any;

  public backgroundClr = this.ls.get("color");
  authHeader = this.updateToken();

  public segmentForm = [
    { name: "Take Out", key: "take_out" },
    { name: "Car Service", key: "car_service" },
    { name: "Staff Meal", key: "staff_meal" },
    { name: "Dine In", key: "dine_in" },
    { name: "Catering", key: "catering" },
    { name: "Delivery", key: "delivery" },
    // { name: "CC_Delivery", key: "cc_delivery" },
    // { name: "CC_Pickup", key: "cc_pickup" },
  ];

  public segments = {
    take_out: false,
    car_service: false,
    staff_meal: false,
    dine_in: false,
    catering: false,
    delivery: false,
  };
  public user = new Subject<object>();
  public colorAccent = new Subject<any>();

  updateToken() {
    return {
      headers: new HttpHeaders({ "Content-Type": "application/json" }).append(
        "Authorization",
        `${this.ls.get("token") ? this.ls.get("token") : ""}`
      ),
    };
  }
  getToken() {
    return this.ls.get("token");
  }
  User(val) {
    this.user.next(val);
  }
  accent(val) {
    this.colorAccent.next(val);
  }

  openSnackBar(message) {
    this.snackBar.open(message, "close", {
      duration: 3000,
      verticalPosition: "bottom",
      horizontalPosition: "right",
      panelClass: ["snackbarStyle"],
    });
  }

  /* image */

  upload(data): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + `image`, data);
  }

  getImage(id) {
    return this.http.get(this.posBackOfficeCloudApi + `image?id=${id}`);
  }
  /* image */

  orgupload(data): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + `image`, data);
  }

  orggetImage(id) {
    return this.http.get(this.posBackOfficeCloudApi + `image?id=${id}`);
  }


  /* menu */

  Menu(rule, data: any = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeApi + `menu${query}`);
      case "post":
        return this.http.post(this.posBackOfficeApi + "menu", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "menu", data);
      case "delete":
        return this.http.delete(this.posBackOfficeApi + `menu${query}` + data);
    }
  }

  /* menu */

  newMenu(rule, data: any = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeCloudApi + `menus${query}`);
      case "post":
        return this.http.post(this.posBackOfficeCloudApi + "menus", data);
      case "put":
        return this.http.put(this.posBackOfficeCloudApi + "menus", data);
      case "delete":
        return this.http.delete(this.posBackOfficeCloudApi + `menus${query}` + data);
    }
  }

  /*items*/

  Item(rule, data: any = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeCloudApi + `items${query}`);
      case "post":
        return this.http.post(this.posBackOfficeCloudApi + "items", data);
      case "put":
        return this.http.put(this.posBackOfficeCloudApi + "items", data);
      case "delete":
        return this.http.delete(this.posBackOfficeCloudApi + "items"+ query );
    }
  }

  /* modifier catalog */

  modifierCatalog(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeApi + "modifiercatalog");
      case "post":
        return this.http.post(this.posBackOfficeApi + "modifiercatalog", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "modifiercatalog", data);
      case "delete":
        return this.http.delete(
          this.posBackOfficeApi + `modifiercatalog${query}` + data
        );
    }
  }

  /* menu item group*/

  MenuItemGroup(rule, data: any = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(
          this.posBackOfficeCloudApi + `menuitemgroups${query}`);
      case "post":
        return this.http.post(this.posBackOfficeCloudApi + "menuitemgroups", data);
      case "put":
        return this.http.put(this.posBackOfficeCloudApi + "menuitemgroups", data);
      case "delete":
        return this.http.delete( this.posBackOfficeCloudApi +  `menuitemgroups${query}` );
    }
  }

  /* modifier group*/

  modifierGroup(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(
          this.posBackOfficeApi + `modifiergroups${query}` + data
        );
      case "post":
        return this.http.post(this.posBackOfficeApi + "modifiergroups", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "modifiergroups", data);
      case "delete":
        return this.http.delete(
          this.posBackOfficeApi + `modifiergroups${query}` + data
        );
    }
  }

  /* discount */

  Discount(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeCloudApi + `discounts${query}`);
      case "post":
        return this.http.post(this.posBackOfficeCloudApi + "discounts", data);
      case "put":
        return this.http.put(this.posBackOfficeCloudApi + "discounts", data);
    }
  }
  deleteDiscount(data): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "discounts" + data);
  }

  getSalesData(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `report${query}`);
  }

  getHourlyReport(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `report/hoursReport${query}`)
  }

  reportData(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "report/groups" + query);
  }

  getUserListForManager(query): Observable<any> {
    return this.http.get(this.BaseApi + query);
  }
  getManagerShiftData(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + 'manager' + query);
  }

  getAllUser(): Observable<any> {
    return this.http.get(this.AuthApi, this.authHeader);
  }
  getAllUsers(data): Observable<any> {
    return this.http.get(this.BaseApi + data, this.authHeader);
  }
  getStockReport(query): Observable<any>{
    return this.http.get(this.posBackOfficeCloudApi + 'stock/report' + query)
  }


  deleteUser(id): Observable<any> {
    return this.http.delete(this.BaseApi + `?_id=${id}`, this.authHeader);
  }

  adminRegister(payload): Observable<any> {
    return this.http.post(this.AuthApi, payload);
  }
  adminReg(payload): Observable<any> {
    return this.http.post(this.BaseApi, payload);
  }
  // pos-cloud-registration
  adminRegisters(payload): Observable<any> {
    return this.http.post(this.BaseApi + "registration", payload);
  }
  adminResend(payload): Observable<any> {
    return this.http.put(this.BaseApi + "registration", payload);
  }
  adminOtp(payload): Observable<any> {
    return this.http.post(this.BaseApi + 'validateOtp', payload);
  }
  getAgentUsers(): Observable<any> {
    return this.http.get(this.BaseApi);
  }
  getAgentUser(query): Observable<any> {
    return this.http.get(this.BaseApi + query);
  }

  updateUser(payload): Observable<any> {
    return this.http.put(this.BaseApi, payload, this.authHeader);
  }


  login(payload): Observable<any> {
    return this.http.post(this.BaseApi + "login", payload);
  }
  /* items */

  /* brands */
  brands(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeCloudApi + `brands${query}`);
      case "post":
        return this.http.post(this.posBackOfficeCloudApi + "brands", data);
      case "put":
        return this.http.put(this.posBackOfficeCloudApi + "brands", data);
      case "delete":
        return this.http.delete(this.posBackOfficeCloudApi + "brands/" + query);
      case "get1":
        return this.http.get(
          this.posBackOfficeCloudApi + "brands/branches/" + query
        );
    }
  }

  /* Branches */
  Branches(query = ""): Observable<any> {
    return this.http.get(this.posBackOfficeApi + `branches${query}`);
  }

  /*Menu Category */
  menuCategory(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeApi + `category${query}`);
      case "post":
        return this.http.post(this.posBackOfficeApi + "category", data);
      case "put":
        return this.http.put(this.posBackOfficeApi + "category", data);
      case "delete":
        return this.http.delete(
          this.posBackOfficeApi + `category${query}` + data
        );
    }
  }
  /* Category Catalog */
  menuCategoryCatalog(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "post":
        return this.http.post(this.posBackOfficeCloudApi + "categoryCatalog", data);
      case "put":
        return this.http.put(this.posBackOfficeCloudApi + "categoryCatalog", data);
      case "delete":
        return this.http.delete(
          this.posBackOfficeCloudApi + `categoryCatalog${query}` + data
        );
        case "get":
          return this.http.get(this.posBackOfficeCloudApi + `categoryCatalog${query}` );
    }
  }

  saveBrandDetails(brand): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "brands", brand);
  }
  saveOrgDetails(organization): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "organization", organization);
  }

  getBrandDetails(): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "brands");
  }
  getBrandDetail(data): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "brands" + data);
  }
  getOrgDetails(data): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "organization" + data);
  }

  updateBrandDetails(data): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "brands", data);
  }

  deleteBrandDetails(brandId): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "brands/?id=" + brandId);
  }
  deleteWarehouse(warehouseId): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "warehouse" + warehouseId);
  }
  updateOrgDetails(data): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "organization", data);
  }

  deleteOrgDetails(data): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "organization/?id=" + data);
  }
  getPaymentData(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + 'cardTypes' + query)
  }
  updatePaymentData(data): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + 'cardTypes', data)
  }
  deletePayment(id): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + `cardTypes${id}`)
  }
  cardTypesaddPayment(payload): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + 'cardTypes', payload)
  }
  // printers

  printer(payload): Observable<any> {
    return this.http.post(this.printApi + "printers", payload);
  }

fetchPrinter(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "printers" + query);
  }

  printers(rule, data = "", query = ""): Observable<any> {
    switch (rule) {
      case "get":
        return this.http.get(this.posBackOfficeCloudApi + "printers" + query);
      case "post":
        return this.http.post(this.posBackOfficeCloudApi + "printers", data);
      case "put":
        return this.http.put(this.posBackOfficeCloudApi + "printers", data);
    }
  }
  deletePrint(data): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "printers" + data);
  }
  /* branches */
  saveBranchDetails(body): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "branches", body);
  }

  getBranchDetails(query?): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `branches${query}`);
  }
  getBranchDetail(query?): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `branches${query}`);
  }

  updateBranchDetails(data): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "branches", data);
  }

  deleteBranchDetails(brandId): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "branches?id=" + brandId);
  }
  branchDetails(data): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "branches?brand_id=" + data);
  }

  getProductMix(param) {
    return this.http.get(this.posBackOfficeCloudApi + "report/products" + param);
  }
  saveTableNames(data): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "tableConfigurations", data);
  }
  getTableNames(branchId): Observable<any> {
    return this.http.get(
      this.posBackOfficeCloudApi + "tableConfigurations?id=" + branchId
    );
  }
  updateTableNames(payload): Observable<any> {
    return this.http.put(
      this.posBackOfficeCloudApi + "tableConfigurations",
      payload
    );
  }
  deleteTableNames(branchId): Observable<any> {
    return this.http.delete(
      this.posBackOfficeCloudApi + "tableConfigurations?id=" + branchId
    );
  }
  saveDeliveryData(data): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "deliveries", data);
  }
  getDeliveryData(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "deliveries" + query);
  }
  updateDeliveryData(payload): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "deliveries", payload);
  }
  deleteDeliveryData(branchId): Observable<any> {
    return this.http.delete(
      this.posBackOfficeCloudApi + "deliveries?id=" + branchId
    );
  }
  saveDeliveryChargesData(data): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "deliveries/charges", data);
  }
  getDeliveryChargesData(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "deliveries/charges" + query);
  }
  updateDeliveryChargesData(body): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "deliveries/charges", body);
  }

  /**area and block */
  getAreaData(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "areas" + query);
  }
  getAreaDatas(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "areas/blocks" + query);
  }
 
  addArea(data): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "areas", data);
  }
  updateArea(data): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "areas", data);
  }
  addBlock(data): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "areas/blocks", data);
  }
  deletearea(area_id): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "areas?area_id=" + area_id);
  }
  deletBlock(block_id): Observable<any> {
    return this.http.delete(
      this.posBackOfficeCloudApi + "areas/blocks?block_id=" + block_id
    );
  }
  updateBlock(data): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "areas/blocks", data);
  }
  /* online setup */

  saveOnlineDeliveryData(data): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "onlineDeliveries", data);
  }
  getOnlineDeliveryData(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "onlineDeliveries" + query);
  }
  getOnlineDelivery(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "onlineDeliveries" + query);
  }
  getOnlineDeliverySource(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "onlineDeliveries" + query);
  }
  getonlinesourcePayment(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "onlinesource/paymenttype" + query);
  }
  updateonlinesourcePayment(data): Observable<any> {
    return this.http.patch(this.posBackOfficeCloudApi + "onlinesource/paymenttype", data)
  }
  deleteonlinesourcePayment(query): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "onlinesource/paymenttype" + query)
  }
  postPayment(data): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "onlinesource/paymenttype", data);
  }

  updateOnlineData(payload): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "onlineDeliveries", payload);
  }
  deleteOnlineData(data): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "onlineDeliveries" + data);
  }
  removeOnlinePayement(query): Observable<any> {
    return this.http.delete(
      this.posBackOfficeApi + "onlineDeliveries/onlinepayments" + query
    );
  }
  removeOnlineType(query): Observable<any> {
    return this.http.delete(
      this.posBackOfficeCloudApi + "onlineDeliveries/onlinetype" + query
    );
  }

  /* Inventory */

  saveCategoryInventory(payload): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "master/categories", payload);
  }
  getCategoryInventory(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "master/categories" + query);
  }
  updateCategoryInventory(payload): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "master/categories", payload);
  }
  deleteCategoryInventory(query): Observable<any> {
    return this.http.delete(
      this.posBackOfficeCloudApi + "master/categories" + query
    );
  }

  /* items */
  saveItemsInventory(payload): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "master/items", payload);
  }
  updateItemsInventory(payload): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "master/items", payload);
  }
  getItemsInventory(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "master/items" + query);
  }
  deleteItemsInventory(query): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "master/items" + query);
  }
  /* supplier */
  saveSupplierInventory(payload): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "suppliers", payload);
  }
  getSupplierInventory(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "suppliers" + query);
  }
  getSuppliergrv(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "suppliers" + query);
  }
  getitemgrv(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "grvs/report" + query);
  }
  getwastagegrv(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "wastage/report" + query);
  }
  deleteSupplierInventory(query): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "suppliers" + query);
  }
  updateSupplierInventory(payload): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "suppliers", payload);
  }
  getStocks(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "stocks" + query);
  }
  /* branReport */
  getBrandSalesReport(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "report/brands" + query);
  }
  getBrandSalesReportByDate(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "report/brands/dates" + query);
  }

  /* grvreports*/
  getGrvReport(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "grvs" + query);
  }
  /* dashboard */

  dashboard(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "dashboard" + query);
  }

  dashboardGraph(query?: any): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "dashboard/graph" + query);
  }

  dashboardTrends(query?: any): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "dashboard/trends" + query);
  }
  dashboardTrends1(query?: any): Observable<any> {
    return this.http.get(
      this.posBackOfficeApi + "dashboard/trendsyesterday" + query
    );
  }

  dashboardSalesSummary(query?: any): Observable<any> {
    return this.http.get(
      this.posBackOfficeCloudApi + "dashboard/salesSummary" + query
    );
  }

  /* transactionDate */
  transactionDate(payload): Observable<any> {
    return this.http.post(
      this.posBackOfficeCloudApi + "brands/transactions",
      payload
    );
  }

  /*oders */
  getOders(id?: any): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "orders?check=true&id=" + id);
  }

  globalCardTypes(): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "cardTypes" );
  }
  
  globalCardType(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "cardTypes" + query);
  }

  getorders1(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "orders" + query);
  }

  getTransactiondate(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "brands/transactions" + query);
  }

  fetch_refund(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "refund" + query);
  }

  refund(payload): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "refund", payload);
  }

  updateOrders(data): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "orders", data);
  }

  getDineInData(query) {
    return this.http.get(this.posBackOfficeCloudApi + "tableConfigurations" + query);
  }

  updateDineInData(payload) {
    return this.http.put(
      this.posBackOfficeCloudApi + "tableConfigurations",
      payload
    );
  }
  branchPrinters(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "branchprinter" + query);
  }
  managerEndShiftData(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + 'endShift/data' + query);
  }
  getParticularSegments(segmentId): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "/segment/" + segmentId);
  }

  getDiscountDetails(data): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "discounts" + data);
  }

  searchMenu(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "menus/search" + query);
  }
  searchRecipe(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "receipe/newrecipe/search" + query);
  }
  itemsearch(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "master/items/search" + query);
  }

  SFitemsearch(query): Observable<any> {
    return this.http.get(
      this.posBackOfficeCloudApi + "master/items/search/type" + query
    );
  }
  itemCatalogue(query): Observable<any> {
    return this.http.get(
      this.posBackOfficeCloudApi + "receipe/itemcatelog/search" + query
    );
  }

  saveMenuRecipe(payload): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "receipe/newrecipe", payload);
  }
  addMenuRecipe(payload): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "receipe", payload);
  }
  addSFRecipe(payload): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "receipe/item", payload);
  }
  additemRecipe(payload): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "receipe/itemcatelog", payload);
  }
  getMenuRecipe(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "receipe" + query);
  }
  getSFRecipe(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "receipe/item" + query);
  }
  getitemRecipe(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "receipe/itemcatelog" + query);
  }
  deleteMenuRecipe(query): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "receipe" + query);
  }
  deleteSFRecipe(query): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "receipe/item" + query);
  }
  deleteitemRecipe(query): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "receipe/itemcatelog" + query);
  }



  updateMenuRecipe(payload): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + "receipe/newrecipe", payload);
  }

  updateItemRecipe(payload): Observable<any> {
    return this.http.patch(this.posBackOfficeApi + "receipe/Item", payload);
  }

  saveItemRecipe(payload): Observable<any> {
    return this.http.post(this.posBackOfficeApi + "receipe/Item", payload);
  }

  deleteMenuRecipes(query): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "receipe/newrecipe" + query);
  }

  deleteSFRecipes(query): Observable<any> {
    return this.http.delete(this.posBackOfficeApi + "receipe/Item" + query);
  }

  GetRecipeOfMenu(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "receipe/newrecipe" + query);
  }
  GetRecipeOfSFitem(query): Observable<any> {
    return this.http.get(this.posBackOfficeApi + "receipe/Item" + query);
  }
  GetItemUnitPrice(query): Observable<any> {
    return this.http.get(
      this.posBackOfficeApi + "receipe/grvitems/unitprice" + query
    );
  }
  foodcost(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "foodcosts/test" + query);
  }
  updateUserDetailsInOrders(payload): Observable<any> {
    return this.http.patch(this.posBackOfficeCloudApi + 'orders/username/update', payload)
  }

  getComplaints(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + "complaints" + query);
  }

  addComplaint(payload): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "complaints", payload);
  }

  deleteComplaint(query): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + "complaints" + query);
  }

  updateComplaint(payload): Observable<any> {
    return this.http.patch(this.posBackOfficeCloudApi + "complaints", payload);
  }
  cresteSubcomplaint(body): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + "complaints/subcomplaints", body);
  }
  getCreatedSubComplaint(id): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `complaints/subcomplaints?complaint_id=${id}`);
  }
  deletesubCompaint(id): Observable<any> {
    return this.http.delete(this.posBackOfficeCloudApi + `complaints/subcomplaints?subcomplaint_id=${id}`)
  }
  updateSubComplaint(payload): Observable<any> {
    return this.http.patch(this.posBackOfficeCloudApi + 'complaints/subcomplaints', payload)
  }
  updateChangePassword(body): Observable<any> {
    return this.http.put(this.AuthApi + 'changepassword', body)
  }
  transaction(id): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `brands/transactions?branch_id=${id}`)
  }
  endshift(brand_id, branch_id): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `endShift/data?brand_id=${brand_id}&branch_id=${branch_id}`)
  }
  updateTransaction(body): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + 'brands/transactions', body)
  }
  updateStatus(body): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + 'endShift', body)
  }

  onlineorder(): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `onlineDeliveries`)
  }
  onlineorders(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `onlineDeliveries`+ query)
  }
  getTransactionDate(): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + 'callcenter/transactionDates')
  }
  postwarehouse(body): Observable<any> {
    return this.http.post(this.posBackOfficeCloudApi + 'warehouse', body)
  }
  getwarehouse(data): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + 'warehouse' + data)
  }
  getordersearch(querry): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `callcenter/reports${querry}`)
  }

  getAgentPerformance(querry): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `report/agentPerformance${querry}`)
  }
  getAreas(query): Observable<any> {
    return this.http.get(`${this.posBackOfficeCloudApi}areas${query}`);
  }
  getBlocksbyAreaid(query): Observable<any> {
    return this.http.get(`${this.posBackOfficeCloudApi}areas/blocks${query}`);
  }
  getCustomerReport(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `report/customer${query}`)
  }


  putwarehouse(body): Observable<any> {
    return this.http.put(this.posBackOfficeCloudApi + 'warehouse', body)
  }
  // 
  getProductFilter(query): Observable<any> {
    return this.http.get(this.posBackOfficeCloudApi + `report/products${query}`)
  }

}
