import { Component, OnInit, EventEmitter } from "@angular/core";
import { PosBackOfficeService } from "../providers/pos-back-office.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from "@angular/forms";
import { FileUploader, FileLikeObject } from "ng2-file-upload";
import { LocalStorageService, CookiesStorageService } from "ngx-store";
import { Observable, iif, empty } from "rxjs";
import { DatePipe } from '@angular/common';
import { FindValueSubscriber } from "rxjs/internal/operators/find";

@Component({
  selector: "app-recipemanagement",
  templateUrl: "./recipemanagement.component.html",
  styleUrls: ["./recipemanagement.component.scss"]
})
export class RecipemanagementComponent implements OnInit {
  menu: any;
  RecipeResult: any;
  searchedSFItem: any;
  SFItem: any;
  backuprecipeitem: any;
  brandImage: any;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService,
    private datePipe: DatePipe,
  ) { }
  recipeform: FormGroup;
  recipeEdit: FormGroup;
  recipeArray: FormArray;
  recipeArrays: FormArray;
  recipeArrayForm: any;
  filteredCode: any = [];
  searchedMenuNames;
  searchedRecipeNames;
  searchedItemCatalog;
  brandId;
  brand;
  branches: any;
  selectbrand: any;
  selectbranch: any;
  branch;
  filteredNames: any = [];
  hidetable = false;
  searchhide = true;
  semifinishedhide = true;
  totalcost = 0;
  totalcost2 = 0;
  dineInPercantage = 0;
  takeOutPercantage = 0
  dineInPercantage1 = 0;
  takeOutPercantage1 = 0;
  SFprice = 0;
  menuprice = 0;
  cost:number;
  package_Type: any;
  showrecipe = false;
  showsemifinished = false;
  showitemcatalogue = false;
  showrecipename = false;
  semifinishedorderlist;
  i: any;
  savehide = true;
  rupdate = true;
  saverecipe1 = true;
  updateRecipe1 = false;
  showSingleRecipe = false;
  recipeitems: any;
  showItemName = false;
  menurecipes: any = [];
  showQty = true;
  hideqty = false;
  showEidt = true;
  showUpdate = false;
  singleId: any;
  singleEdit: any;
  recipedata: any;
  printerhide = false;
  midtotal: any;
  systemDate;
  brandname;
  imagedata;
  logo;
  recipename: any;
  recipeData: any;
  shows = false;
  recipe_id: any;
  recipeInfo: any
  measuring: any;
  p: any;
  itemID: any;
  itemid: any;
  menu_idd: any;
  menu_namee: any;
  recipe_idd: any;
  recipe_namee: any;
  item_idd: any;
  item_namee: any;
  item_iddd: any;
  item_nameee: any;
  recipeshow = true;
  menuData: any;
  menuDatas: any;
  menuDatass: any;
  SFData: any;
  SFDatas: any;
  SFDatass: any;
  itemData: any;
  itemDatas: any;
  itemDatass: any;
  pp: any;
  ppp: any;
  pppp: any;
  cc: any;
  recipeIdDelete:any
  menutable = false;
  semitable = false;
  itemtable = false;
  menuid: any;
  recipeid: any
  menuidd: any;
  recipeidd: any
  menuiddd: any;
  recipeiddd: any;
  read = false;
  addinfo = false;
  addbtn:boolean;
  updatebtn = false;
  orgData: any;
  orgGet: any = [];
  brandGet: any = [];

  ngOnInit() {
    this.recipeform = this.fb.group({
      select_search: [""],
      brand_id: ["", [Validators.required]],
      branch_id: ["", [Validators.required]],
      search_item: ["", [Validators.required]],
      semi_finihed: ["", [Validators.required]],
      item_catalogue: ["", [Validators.required]],
      recipe_newname: ["", [Validators.required]],
      recipe_name: ["", [Validators.required]],
      recipe_name_arabic: ["", [Validators.required]],
      recipemenuradio: [""],
      semifinishedradio: [""],
      recipeArray: this.fb.array([this.ItemControl()]),

    });
    this.recipeEdit = this.fb.group({
      recipeqty: [""],
      recipecost: [""],
      recipeArrays: this.fb.array([this.ItemControls()])

    });
    this.getOrgDetails();
    this.RecipeResult = [];
  }
  ItemControl() {
    return this.fb.group({
      item_code: ["", [Validators.required]],
      item_name_english: ["", [Validators.required]],
      package_Type: ["", [Validators.required]],
      unit_price: ["", [Validators.required]],
      Cost: ["", [Validators.required]],
      quantity: ["", [Validators.required]],
      measuring_unit: ["", [Validators.required]],
      type: ["", [Validators.required]],
      category_code: ["", [Validators.required]],
      item_id: "",
      brand_id: ""
    });
  }
  ItemControls() {
    return this.fb.group({
      item_codes: ["", [Validators.required]],
      item_name_englishs: ["", [Validators.required]],
      package_Types: ["", [Validators.required]],
      unit_prices: ["", [Validators.required]],
      Costs: ["", [Validators.required]],
      quantitys: ["", [Validators.required]],
      measuring_units: ["", [Validators.required]],
      types: ["", [Validators.required]],
      category_codes: ["", [Validators.required]],
      item_id: "",
      brand_id: ""
    });
  }
  getRecipe() {
    const query = `?filterByBrand=1&brand_id=${this.selectbrand.brand_id}`;
    this.service.GetRecipeOfMenu(query).subscribe(res => {
      this.recipename = res.body;

    });
  }
  getRecipes(datas) {
    this.addinfo = false;
    this.addbtn = false;
    this.updatebtn = false;
    this.recipeData = datas;
    this.read = true;
    const query = `?filterByBrand=1&brand_id=${this.selectbrand.brand_id}`;
    this.service.GetRecipeOfMenu(query).subscribe(res => {
      this.recipename = res.body;
      for (let i = 0; i < res.body.length; i++) {
        if (this.recipeData.recipe_id == res.body[i].recipe_id) {
          this.showSingleRecipe = true;
          this.recipeshow = false;
          this.recipe_id = this.recipeData.recipe_id;
          this.measuring = this.recipeData.total_cost;
          this.recipeform.patchValue({ recipe_name: this.recipeData.recipe_name });
          this.recipeform.patchValue({ recipe_newname: this.recipeData.recipe_name });
          this.recipeform.patchValue({ recipe_name_arabic: this.recipeData.recipe_name_arabic });
          this.getmenu();
          this.getsf();
          this.getitems();
          this.menutable = true;
          this.semitable = true;
          this.itemtable = true;

        } else {
          this.recipeData = datas;
        }
      }

    });
  }
  recipeshows() {
    this.recipeshow = true;
    this.showSingleRecipe = false;
  }
  removeItem(index) {
    const form = this.recipeform.get("recipeArray") as FormArray;
    this.recipeArrayForm = this.recipeform.get("recipeArray") as FormArray;
    this.recipeArrayForm.controls[index].patchValue({
      item_code: "",
      item_name_english: "",
      package_Type: "",
      unit_price: "",
      Cost: "",
      quantity: "",
      measuring_unit: "",
      type: "",
      category_code: "",
      item_id: "",
    });
    if (form.length === 1) {
    } else {
      form.removeAt(index);
    }
    this.calculation();
  }
  removeItems(index) {
    const form = this.recipeEdit.get("recipeArrays") as FormArray;
    this.recipeArrayForm = this.recipeEdit.get("recipeArrays") as FormArray;
    this.recipeArrayForm.controls[index].patchValue({
      item_codes: "",
      item_name_englishs: "",
      package_Types: "",
      unit_prices: "",
      Costs: "",
      quantitys: "",
      measuring_units: "",
      types: "",
      category_codes: "",
      item_id: "",
    });
    if (form.length === 1) {
    } else {
      form.removeAt(index);
    }

  }

  searchMenuName() {
    if (this.searchedMenuNames != "" || this.searchedMenuNames != undefined) {
      const query = `?searchBy=menu_name_english&keyword=${this.recipeform.value.search_item}&brand_id=${this.selectbrand.brand_id}&branch_id=${this.selectbranch.branch_id}`;
      this.service.searchMenu(query).subscribe(
        result => {
          this.searchedMenuNames = result.body;
        },
        error => {
          console.log("error", error);
        }
      );
    }
  }
  searchSemiFinishedItems() {
    const keyword = this.recipeform.value.semi_finihed;
    const query = `?keyword=${keyword}&searchBy=item_name&brand_id=${this.selectbrand.brand_id}&branch_id=${this.selectbranch.branch_id}&type=SF`;
    this.service.SFitemsearch(query).subscribe(res => {
      this.searchedSFItem = res.body;
    });
  }
  searchItemCatalog() {
    const keyword = this.recipeform.value.item_catalogue;
    const query = `?brand_id=${this.selectbrand.brand_id}&keyword=${keyword}&branch_id=${this.selectbranch.branch_id}`;
    this.service.itemCatalogue(query).subscribe(res => {
      this.searchedItemCatalog = res.body;

    });
  }
  searchRecipeName() {
    if (this.searchedRecipeNames != "" || this.searchedRecipeNames != undefined) {
      const query = `?brand_id=${this.selectbrand.brand_id}&keyword=${this.recipeform.value.recipe_newname}`;
      this.service.searchRecipe(query).subscribe(
        result => {
          this.searchedRecipeNames = result.body;
        },
        error => {
          console.log("error", error);
        }
      );
    }

  }

  selectSFitem(data) {
    this.SFItem = data;
    this.SFprice = parseFloat(data.average_price);
    this.recipeform.patchValue({ semi_finihed: data.item_name_english });
    this.item_idd = data.item_id;
    this.item_namee = data.item_name_english;
    this.hidetable = true;
    this.searchedSFItem = [];
    this.menu = [];
  }
  selectmenu(data) {
    this.menu = data;
    this.menuprice = parseFloat(data.menu_price);
    this.menu_idd = parseFloat(data.menu_id);
    this.menu_namee = data.menu_name;
    this.recipeform.patchValue({ search_item: data.menu_name });
    this.searchedMenuNames = [];
    this.hidetable = true;
  }
  selectrecipe(data) {
    this.recipeform.patchValue({ recipe_newname: data.recipe_name });
    this.recipe_idd = data.recipe_id;
    this.recipe_namee = data.recipe_name;
    this.searchedRecipeNames = [];
    this.hidetable = true;
  }
  selectitem(data) {
    this.recipeform.patchValue({ item_catalogue: data.item_name });
    this.item_iddd = data.item_id;
    this.item_nameee = data.item_name;
    this.searchedItemCatalog = [];
    this.hidetable = true;
  }

  AddMenu() {
    this.menutable = true;
    this.semitable = true;
    this.itemtable = true;
    const obj = {
      menu_id: this.menu_idd,
      menu_name: this.menu_namee,
      recipe_id: this.recipe_id,
      recipe_name: this.recipeform.value.recipe_newname,
      brand_id: this.selectbrand.brand_id,
      branch_id: this.selectbranch.branch_id,
    };

    const payload = {
      Recipe: "menuRecipe",
      data: [obj]
    };

    if ((this.recipeform.value.search_item != "") && (this.recipeform.value.recipe_newname != "")) {
      this.service.addMenuRecipe(payload).subscribe(res => {
        this.recipeform.patchValue({ search_item: "" });
        this.recipeform.patchValue({ recipe_newname: "" });
        this.getmenu();

        return this.service.openSnackBar('Recipe linked to Menu  Successfully');
      },
        (error) => {
          this.service.openSnackBar('Recipe already Linked');
        }
      );
    }
    else {
      return this.service.openSnackBar("Please fill all fields");
    }
  }
  getmenu() {
    this.menuDatas = [];
    this.menuData = [];
    const query = `?branch_id=${this.selectbranch.branch_id}&brand_id=${this.selectbrand.brand_id}`;
    this.service.getMenuRecipe(query).subscribe(
      result => {
        this.menuDatas = result.body;
        for (let i = 0; i < this.menuDatas.length; i++) {
          this.menuDatass = this.menuDatas[i]
          if ((this.menuDatass.recipe_name == this.recipeform.value.recipe_name)) {
            this.menuData.push(this.menuDatas[i])

          }
        }
        // return this.service.openSnackBar('Data fetched Successfully');
      });
  }
  AddSF() {
    this.menutable = true;
    this.semitable = true;
    this.itemtable = true;

    const obj = {
      item_id: this.item_idd,
      item_name: this.item_namee,
      recipe_id: this.recipe_id,
      recipe_name: this.recipeform.value.recipe_newname,
      brand_id: this.selectbrand.brand_id,
      branch_id: this.selectbranch.branch_id
    };


    const payload = {
      Recipe: "SFRecipe",
      data: [obj]
    };

    if ((this.recipeform.value.semi_finihed != "") && (this.recipeform.value.recipe_newname != "")) {
      this.service.addSFRecipe(payload).subscribe(res => {
        this.recipeform.patchValue({ semi_finihed: "" });
        this.recipeform.patchValue({ recipe_newname: "" });

        this.getsf();
        this.searchedSFItem = [];
        this.searchedRecipeNames = [];
        return this.service.openSnackBar('Recipe linked to SF  Successfully');
      },
        (error) => {
          this.service.openSnackBar('Recipe already Linked');
        }
      );
    } else {
      return this.service.openSnackBar("Please fill all fields");
    }
  }
  getsf() {
    this.SFDatas = [];
    this.SFData = [];
    const query = `?brand_id=${this.selectbrand.brand_id}&branch_id=${this.selectbranch.branch_id}`;
    this.service.getSFRecipe(query).subscribe(
      result => {
        this.SFDatas = result.body;
        for (let i = 0; i < this.SFDatas.length; i++) {
          this.SFDatass = this.SFDatas[i]
          if ((this.SFDatass.recipe_name == this.recipeform.value.recipe_name)) {
            this.SFData.push(this.SFDatas[i])
          }
        }
        // return this.service.openSnackBar('Data fetched Successfully');
      });
  }
  Additem() {
    this.menutable = true;
    this.semitable = true;
    this.itemtable = true;

    const obj = {
      item_id: this.item_iddd,
      item_name: this.item_nameee,
      recipe_id: this.recipe_id,
      recipe_name: this.recipeform.value.recipe_newname,
      brand_id: this.selectbrand.brand_id,
      branch_id: this.selectbranch.branch_id
    };

    const payload = {
      Recipe: "itemcatelogRecipe",
      data: [obj]
    };

    if ((this.recipeform.value.item_catalogue != "") && (this.recipeform.value.recipe_newname != "")) {
      this.service.additemRecipe(payload).subscribe(res => {
        this.recipeform.patchValue({ item_catalogue: "" });
        this.recipeform.patchValue({ recipe_newname: "" });
        this.getitems();
        this.searchedRecipeNames = [];
        this.searchedItemCatalog = [];

        return this.service.openSnackBar('Recipe linked to Item Catalogue  Successfully');
      },
        (error) => {
          this.service.openSnackBar('Recipe already Linked');
        });
    }
    else {
      return this.service.openSnackBar("Please fill all fields");
    }
  }
  getitems() {
    this.itemDatas = [];
    this.itemData = [];
    const query = `?brand_id=${this.selectbrand.brand_id}&branch_id=${this.selectbranch.branch_id}`;
    this.service.getitemRecipe(query).subscribe(
      result => {
        this.itemDatas = result.body;
        for (let i = 0; i < this.itemDatas.length; i++) {
          this.itemDatass = this.itemDatas[i]
          if ((this.itemDatass.recipe_name == this.recipeform.value.recipe_name)) {
            this.itemData.push(this.itemDatas[i])
          }
        }
        // return this.service.openSnackBar('Data fetched Successfully');
      });

  }
  deletemenu(data) {
    this.menuid = data.menu_id;
    this.recipeid = data.recipe_id
    const query = `?recipe_id=${this.recipeid}&branch_id=${this.selectbranch.branch_id}&brand_id=${this.selectbrand.brand_id}&menu_id=${this.menuid}`;
    this.service.deleteMenuRecipe(query).subscribe(result => {
      this.getmenu();
      return this.service.openSnackBar('Menu deleted Successfully');

    }
    );
  }
  deletesf(data) {
    this.menuidd = data.item_id;
    this.recipeidd = data.recipe_id
    const query = `?recipe_id=${this.recipeidd}&brand_id=${this.selectbrand.brand_id}&branch_id=${this.selectbranch.branch_id}&item_id=${this.menuidd}`;
    this.service.deleteSFRecipe(query).subscribe(result => {
      this.getsf();
      return this.service.openSnackBar('Semi-finished item deleted Successfully');

    }
    );
  }
  deleteitem(data) {
    this.menuiddd = data.item_id;
    this.recipeiddd = data.recipe_id
    const query = `?recipe_id=${this.recipeiddd}&brand_id=${this.selectbrand.brand_id}&branch_id=${this.selectbranch.branch_id}&item_id=${this.menuiddd}`;
    this.service.deleteitemRecipe(query).subscribe(result => {
      this.getitems();
      return this.service.openSnackBar('Item catelogoue item deleted Successfully');

    },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  resetForm() {
    this.recipeArrayForm = this.recipeform.get("recipeArray") as FormArray;
    const form = this.recipeform.get("recipeArray") as FormArray;
    this.totalcost = 0;
    this.dineInPercantage = 0;
    this.takeOutPercantage = 0;
    // this.menuprice = 0;
    // this.SFprice = 0;

    for (let i = 0; i < form.value.length; i++) {
      if (i >= 0) {
        form.controls[i].patchValue({ item_code: "" });
        form.controls[i].patchValue({ item_name_english: "" });
        form.controls[i].patchValue({ package_Type: "" });
        form.controls[i].patchValue({ unit_price: "" });
        form.controls[i].patchValue({ quantity: "" });
        form.controls[i].patchValue({ item_id: "" });
        form.controls[i].patchValue({ brand_id: this.selectbrand.brand_id });
        form.controls[i].patchValue({ branch_id: this.selectbranch.branch_id });
        form.controls[i].patchValue({ Cost: "" });

      } else {
        form.removeAt(i);
      }
    }
  }
  resetForms() {
    this.recipeArrayForm = this.recipeEdit.get("recipeArrays") as FormArray;
    const form = this.recipeEdit.get("recipeArrays") as FormArray;

    for (let i = 0; i < form.value.length; i++) {
      if (i >= 0) {
        form.controls[i].patchValue({ item_codes: "" });
        form.controls[i].patchValue({ item_name_englishs: "" });
        form.controls[i].patchValue({ package_Types: "" });
        form.controls[i].patchValue({ unit_prices: "" });
        form.controls[i].patchValue({ quantitys: "" });
        form.controls[i].patchValue({ item_id: "" });
        form.controls[i].patchValue({ brand_id: this.selectbrand.brand_id });
        form.controls[i].patchValue({ branch_id: this.selectbranch.branch_id });
        form.controls[i].patchValue({ Costs: "" });

      } else {
        form.removeAt(i);
      }
    }
  }



  searchByCode(i) {
    const keyword = this.recipeform.value.recipeArray[i].item_code;
    if (keyword == "" || keyword == empty) {
      this.filteredCode[i] = [];
    } else {
      const query = `?keyword=${keyword}&searchBy=item_code&brand_id=${this.selectbrand.brand_id}&branch_id=${this.selectbranch.branch_id}`;
      this.service.itemsearch(query).subscribe(res => {
        this.filteredCode[i] = res.body;
      });
    }
  }
  searchByCodes(i) {
    const keyword = this.recipeEdit.value.recipeArrays[i].item_codes;
    if (keyword == "" || keyword == empty) {
      this.filteredCode[i] = [];
    } else {
      const query = `?keyword=${keyword}&searchBy=item_code&brand_id=${this.selectbrand.brand_id}&branch_id=${this.selectbranch.branch_id}`;
      this.service.itemsearch(query).subscribe(res => {
        this.filteredCode[i] = res.body;
      });
    }
  }
  searchByName(i) {
    const keyword = this.recipeform.value.recipeArray[i].item_name_english;
    if (keyword == "" || keyword == empty) {
      this.filteredNames[i] = [];
    } else {
      const query = `?keyword=${keyword}&searchBy=item_name&brand_id=${this.selectbrand.brand_id}&branch_id=${this.selectbranch.branch_id}`;
      this.service.itemsearch(query).subscribe(res => {
        this.filteredNames[i] = res.body;
      });
    }
  }
  searchByNames(i) {
    const keyword = this.recipeEdit.value.recipeArrays[i].item_name_englishs;
    if (keyword == "" || keyword == empty) {
      this.filteredNames[i] = [];
    } else {
      const query = `?keyword=${keyword}&searchBy=item_name&brand_id=${this.selectbrand.brand_id}&branch_id=${this.selectbranch.branch_id}`;
      this.service.itemsearch(query).subscribe(res => {
        this.filteredNames[i] = res.body;
      });
    }
  }
  selecteditem_code(data, i) {
    console.log("data", data)
    const form = this.recipeform.get("recipeArray") as FormArray;
    form.controls[i].patchValue({ item_code: data.item_code });
    form.controls[i].patchValue({ item_name_english: data.item_name_english });
    form.controls[i].patchValue({ package_Type: data.package_Type });
    form.controls[i].patchValue({ measuring_unit: data.measuring_unit });
    form.controls[i].patchValue({ type: data.type });
    form.controls[i].patchValue({ category_code: data.category_code });
    form.controls[i].patchValue({ quantity: data.quantity });
    form.controls[i].patchValue({ item_id: data.item_id });
    form.controls[i].patchValue({ brand_id: this.selectbrand.brand_id });
    form.controls[i].patchValue({ branch_id: this.selectbranch.branch_id });
    form.controls[i].patchValue({ unit_price: (data.average_price).toFixed(3) });
    this.filteredCode[i] = [];
    this.filteredNames[i] = [];
    this.filteredCode[i + 1] = [];
    this.filteredNames[i + 1] = [];
  }
  selecteditem_codes(data, i) {
    console.log("data", data)
    const form = this.recipeEdit.get("recipeArrays") as FormArray;
    form.controls[i].patchValue({ item_codes: data.item_code });
    form.controls[i].patchValue({ item_name_englishs: data.item_name_english });
    form.controls[i].patchValue({ package_Types: data.package_Type });
    form.controls[i].patchValue({ measuring_units: data.measuring_unit });
    form.controls[i].patchValue({ types: data.type });
    form.controls[i].patchValue({ category_codes: data.category_code });
    form.controls[i].patchValue({ quantitys: data.quantity });
    form.controls[i].patchValue({ item_id: data.item_id });
    form.controls[i].patchValue({ brand_id: this.selectbrand.brand_id });
    form.controls[i].patchValue({ branch_id: this.selectbranch.branch_id });
    form.controls[i].patchValue({ unit_prices: (data.average_price).toFixed(3) });
    this.filteredCode[i] = [];
    this.filteredNames[i] = [];
    this.filteredCode[i + 1] = [];
    this.filteredNames[i + 1] = [];
  }
  getItem() {
    this.recipeArrayForm = this.recipeform.get("recipeArray") as FormArray;
    if (this.recipeArrayForm) {
      this.recipeArrayForm.push(this.ItemControl());
    } else {
      error => {
        console.log("error", error);
      };
    }
  }
  getItems() {
    this.recipeArrayForm = this.recipeEdit.get("recipeArrays") as FormArray;
    if (this.recipeArrayForm) {
      this.recipeArrayForm.push(this.ItemControls());
    } else {
      error => {
        console.log("error", error);
      };
    }
  }
  selectBrand(brand) {
    this.selectbrand = brand;
    this.searchedMenuNames = [];
    this.searchedItemCatalog = [];
    this.searchedRecipeNames = [];
    this.hidetable = false;
    this.showSingleRecipe = false;
    this.recipeshow = true;
    this.showItemName = false;
    this.read = false;
    this.searchedSFItem = [];
    this.menu = [];
    this.recipeform.patchValue({ recipemenuradio: null });
    this.recipeform.patchValue({ semifinishedradio: null });
    this.recipeform.patchValue({ semi_finihed: "" });
    this.recipeform.patchValue({ search_item: "" });
    this.recipeform.patchValue({ recipe_name: "" });
    this.recipeform.patchValue({ recipe_name_arabic: "" });
    // this.showrecipe=false;
    // this.showsemifinished=false
  }
  selectBranch(branch) {
    this.selectbranch = branch;
    this.getRecipe();
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service
      .getOrgDetails(`?userId=${this.ls.get("userId")}`)
      .subscribe((result) => {
        this.orgData = result.body;
        for (const data of this.orgData) {
          this.orgGet.push(data.Organization_id);
        }
        this.brandData();
      });
  }
  brandData() {
    this.brandGet = [];
    this.service
      .getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`)
      .subscribe((result) => {
        this.brand = result.body;
        for (const datas of this.brand) {
          this.brandGet.push(datas.brand_id);
        }

      });
  }
  getBranch() {
    this.service
      .getBranchDetail(
        `?filterbybrand=1&brand_id=${this.recipeform.value.brand_id}`
      )
      .subscribe(
        (result) => {
          this.branches = result.body;
        },
        (error) => {
          console.log("error", error);
        }
      );
  }

  saverecipe() {
    if ((this.showsemifinished == false) && (this.recipeform.value.recipe_name != "") && (this.recipeform.value.recipe_name != null) && (this.recipeform.value.recipe_name != undefined)) {
      this.saveMenuRecipe();
    } else {
      return this.service.openSnackBar('Please enter the Recipe Name');

    }
  }



  saveMenuRecipe() {
    const menuRecipepayload = [];
    for (let i = 0; i < this.recipeform.value.recipeArray.length; i++) {
      const obj = {
        average_price: parseFloat(this.recipeform.value.recipeArray[i].unit_price),
        category_code: this.recipeform.value.recipeArray[i].category_code,
        item_code: this.recipeform.value.recipeArray[i].item_code,
        item_id: this.recipeform.value.recipeArray[i].item_id,
        item_name_arabic: this.recipeform.value.recipeArray[i].item_name_arabic,
        item_name_english: this.recipeform.value.recipeArray[i].item_name_english,
        measuring_unit: this.recipeform.value.recipeArray[i].measuring_unit,
        type: this.recipeform.value.recipeArray[i].type,
        quantity: this.recipeform.value.recipeArray[i].quantity,
        cost: parseFloat(this.recipeform.value.recipeArray[i].Cost),
        package: this.recipeform.value.recipeArray[i].package_Type
      };
      menuRecipepayload.push(obj);
    }

    const payload = {
      brand_id: this.selectbrand.brand_id,
      branch_id: this.selectbranch.branch_id,
      recipe_name: this.recipeform.value.recipe_name,
      recipe_name_arabic: this.recipeform.value.recipe_name_arabic,
      total_cost: this.totalcost,
      data: menuRecipepayload
    };
    console.log("payload", payload)
    this.recipeArrayForm = this.recipeform.get("recipeArray") as FormArray;
    console.log("this.recipeArrayForm", this.recipeArrayForm)
    // if (this.recipeArrayForm.valid) {
      this.recipeform.patchValue({ recipe_newname: this.recipeform.value.recipe_name });

      console.log("payload222", payload)
      this.service.saveMenuRecipe(payload).subscribe(res => {
        console.log("rrrrrrr", res)
        this.getRecipe();
        this.resetForm();
        //     this.searchedMenuNames = [];
        this.saverecipe1 = false;
        this.updateRecipe1 = true;
        this.savehide = false;
        //    this.getRecipeOfMenu();
        return this.service.openSnackBar('Recipe saved Successfully');
      },
      (error) => {
      this.service.openSnackBar(error.error.message);
 
      }
      );
    // } else {
    //   return this.service.openSnackBar("Please fill all fields");
    // }
  }

  updaterecipes() {
    const menuRecipepayload = [];
    for (let i = 0; i < this.recipeEdit.value.recipeArrays.length; i++) {
      const obj = {
        average_price: parseFloat(this.recipeEdit.value.recipeArrays[i].unit_prices),
        category_code: this.recipeEdit.value.recipeArrays[i].category_codes,
        item_code: this.recipeEdit.value.recipeArrays[i].item_codes,
        item_id: this.recipeEdit.value.recipeArrays[i].item_id,
        item_name_arabic: this.recipeEdit.value.recipeArrays[i].item_name_englishs,
        item_name_english: this.recipeEdit.value.recipeArrays[i].item_name_englishs,
        measuring_unit: this.recipeEdit.value.recipeArrays[i].measuring_units,
        type: this.recipeEdit.value.recipeArrays[i].types,
        quantity: this.recipeEdit.value.recipeArrays[i].quantitys,
        cost: parseFloat(this.recipeEdit.value.recipeArrays[i].Costs),
        package: this.recipeEdit.value.recipeArrays[i].package_Types
      };
      menuRecipepayload.push(obj);
    }

    const payload = {
      recipe_id: this.recipe_id,
      brand_id: this.selectbrand.brand_id,
      branch_id: this.selectbranch.branch_id,
      recipe_name: this.recipeform.value.recipe_name,
      recipe_name_arabic: this.recipeform.value.recipe_name_arabic,
      data: menuRecipepayload
    };
    console.log("payload", payload)
    this.recipeArrayForm = this.recipeEdit.get("recipeArrays") as FormArray;
    console.log("this.recipeArrayForm", this.recipeArrayForm)

    // if (this.recipeArrayForm.valid) {
      this.recipeform.patchValue({ recipe_newname: this.recipeform.value.recipe_name });

      console.log("payload222", payload)
      this.service.updateMenuRecipe(payload).subscribe(res => {
        console.log("rrrrrrr", res)
        this.getRecipe();
        this.resetForms();
        this.getRecipes('');
        this.recipeform.patchValue({ recipe_name: "" });
        this.recipeform.patchValue({ recipe_name_arabic: "" });
        this.addinfo = true;
        this.addbtn = false;
        this.updatebtn = false;
        this.recipeshows();
        return this.service.openSnackBar('Updated recipe saved Successfully');
      },
        (error) => {
          this.service.openSnackBar('Item already exist');
        }
      );
    // } else {
    //   return this.service.openSnackBar("Please fill all fields");
    // }
  }

  cal(e) {
    this.calculation();
    console.log(e)
  }



  calculation() {
    const filter = this.recipeform.value.recipeArray.filter(ele => { return ele.Cost == "" });
    if (filter.length == 0) {
      let TakeOutPercantage: any;
      let Totalsum;

      Totalsum = this.recipeform.value.recipeArray
        .map(item => parseFloat(item.Cost))
        .reduce((prev, curr) => prev + curr, 0);
      console.log("totalsum1", Totalsum);
      Totalsum = isNaN(parseFloat(Totalsum)) ? 0 : Totalsum;
      console.log("totalsum1", Totalsum);
      this.totalcost = parseFloat(Totalsum.toFixed(3));
      console.log("this.totalcost", this.totalcost);

      const checkTakeout = this.recipeform.value.recipeArray.filter(ele => {
        return ele.package_Type == "Yes";
      });

      if (checkTakeout.length >= 0) {
        if (this.showsemifinished == false) {
          TakeOutPercantage =
            (this.totalcost * parseFloat(this.menu.menu_price)) / 100;
          console.log("TakeOutPercantage if", TakeOutPercantage);
        } else {
          TakeOutPercantage =
            (this.totalcost * parseFloat(this.SFItem.average_price)) / 100;
          console.log("TakeOutPercantage else", TakeOutPercantage);
        }

        if (isNaN(parseFloat(TakeOutPercantage))) {
          TakeOutPercantage = 0;
        }
        // this.totalcost2 = 0
        console.log("TakeOutPercantage 3", TakeOutPercantage);
        this.takeOutPercantage = parseFloat(TakeOutPercantage.toFixed(3));
        console.log("this.takeOutPercantage", this.takeOutPercantage);
      } else {
        this.takeOutPercantage = 0;
      }

      const checkDineIn = this.recipeform.value.recipeArray.filter(ele => {
        return ele.package_Type == "No";
      });


      console.log("checkDineIn", checkDineIn);
      if (checkDineIn.length >= 0) {
        this.totalcost2 = 0;
        for (let i = 0; i <= this.recipeform.value.recipeArray.length; i++) {
          if (this.recipeform.value.recipeArray[i].package_Type == 'No'|| undefined || null) {
            this.totalcost2 = this.totalcost2 + parseFloat(this.recipeform.value.recipeArray[i].Cost);
            this.midtotal = this.totalcost2;
            let DineInPercantage: any;
            if (this.showsemifinished == false) {
              DineInPercantage =
                (this.midtotal * parseFloat(this.menu.menu_price)) / 100;
              console.log("DineInsum3 if", DineInPercantage);
            } else {
              DineInPercantage =
                (this.midtotal * parseFloat(this.SFItem.average_price)) / 100;
              console.log("DineInsum4 else", DineInPercantage);
            }
            console.log("DineInsum5", DineInPercantage);
            this.dineInPercantage = parseFloat(DineInPercantage.toFixed());
            console.log("this.dineInPercantage", this.dineInPercantage);
          }
        }

        let DineInsum = checkDineIn
          .map(item => parseFloat(item.Cost))
          .reduce((prev, curr) => prev + curr, 0);
        console.log("DineInsum1", DineInsum);
        DineInsum = isNaN(parseFloat(DineInsum)) ? 0 : DineInsum;
        console.log("DineInsum2", DineInsum);

      } else {
        console.log("else dinein");
        this.dineInPercantage = 0;
      }
    } else {
      this.dineInPercantage = 0;
      this.takeOutPercantage = 0;
      this.totalcost = 0
    }
  }

  searchmenu() {
    this.showrecipe = true;
    this.showsemifinished = false;
    this.showitemcatalogue = false;
    this.showrecipename = true;
    this.searchedMenuNames = "";
    this.searchedSFItem = "";
    this.searchedItemCatalog = "";
    this.searchedRecipeNames = "";
    // this.menutable=true;
    // this.semitable=false;
    // this.itemtable=false; 

  }
  searchsemifinish() {
    this.showsemifinished = true;
    this.showrecipe = false;
    this.showitemcatalogue = false;
    this.showrecipename = true;
    this.searchedMenuNames = "";
    this.searchedSFItem = "";
    this.searchedRecipeNames = "";
    this.searchedItemCatalog = "";
    // this.menutable=false;
    // this.semitable=true;
    // this.itemtable=false;
  }

  searchitemcatalogue() {
    this.showitemcatalogue = true;
    this.showsemifinished = false;
    this.showrecipe = false;
    this.showrecipename = true;
    // this.menutable=false;
    // this.semitable=false;
    // this.itemtable=true;
    this.searchedMenuNames = "";
    this.searchedSFItem = "";
    this.searchedRecipeNames = "";
    this.searchedItemCatalog = "";

  }
  resetsave() {
    this.recipeArrayForm = this.recipeform.get("recipeArray") as FormArray;
    for (let i = 0; i < this.recipeArrayForm.length; i - 1) {
      this.recipeArrayForm.removeAt(i);
    }
    this.recipeArrayForm.push(this.ItemControl());
  }

  singleRecipe(data) {
    this.showSingleRecipe = true;
    if (this.showsemifinished == false) {
      this.recipeitems = data[0].menurecipes;
      this.backuprecipeitem = this.recipeitems;
      this.singleId = "";
    } else {
      this.recipeitems = data[0].itemrecipes;
      this.backuprecipeitem = this.recipeitems;
      this.singleId = "";
    }




    for (let i = 0; i < this.recipeitems.length; i++) {
      this.recipeitems[i]["quantity"] = this.recipeitems[i].quantity.toFixed(3)
      this.recipeitems[i]["cost"] = (this.recipeitems[i].quantity * this.recipeitems[i].masteritem.average_price.toFixed(3)).toFixed(3)

    }
    console.log("recipesitems", this.recipeitems)
  }


  closesinglerecipe() {
    this.showSingleRecipe = false;
    this.read = false;
    this.recipeform.patchValue({ recipe_name: "" });
    this.recipeform.patchValue({ recipe_name_arabic: "" });
    this.recipeshows();
  }

  editdata(data) {
    this.itemID = data.item_id;
    this.showQty = false;
    this.hideqty = true;
    this.read = false;
    this.recipedata = data;
    this.recipeEdit.patchValue({ recipeqty: data.quantity });
    const costss = parseFloat(data.quantity) * parseFloat(data.average_price);
    this.recipeEdit.patchValue({ recipecost: costss.toFixed(3) });
    // this.recipeEdit.patchValue({ recipecost: data.quantity * data.average_price });
    //this.recipeEdit.patchValue(data);
  }


  updaterecipe(updaterecipe) {
    this.itemID = "";
    console.log("updaterecipe down side", this.recipeEdit.value.recipeqty)
    if (this.recipeEdit.value.recipeqty == null || this.recipeEdit.value.recipeqty == "" || this.recipeEdit.value.recipeqty == undefined) {
      return this.service.openSnackBar("please enter quantity");
    }
    if (this.showsemifinished == false) {
      this.showQty = true;
      this.hideqty = false;
      this.updateMenuRecipeperitem(updaterecipe);
    }
  }


  updateMenuRecipeperitem(updaterecipe) {

    const payload = {
      recipe_id: this.recipe_id,
      brand_id: this.selectbrand.brand_id,
      branch_id: this.selectbranch.branch_id,
      recipe_name: this.recipeform.value.recipe_name,
      recipe_name_arabic: this.recipeform.value.recipe_name_arabic,
      total_cost: this.recipeData.total_cost,
      item_id: updaterecipe.item_id,
      quantity: this.recipeEdit.value.recipeqty,
      cost: parseFloat(this.recipeEdit.value.recipecost)
      // data: obj
    };

    this.service.updateMenuRecipe(payload).subscribe(res => {
      if (res.status) {
        this.getRecipes('');
        this.recipeform.patchValue({ recipe_name: "" });
        this.recipeform.patchValue({ recipe_name_arabic: "" });
        this.recipeshows();
        return this.service.openSnackBar("Recipe updated Successfully");
      }
    });
  }

  quantitychange(recipe, i) {
    if (
      this.recipeEdit.value.recipeqty != null &&
      this.recipeEdit.value.recipeqty != undefined &&
      this.recipeEdit.value.recipeqty != ""
    ) {
      const costs = parseFloat(this.recipeEdit.value.recipeqty) * parseFloat(recipe.average_price);
      this.recipeEdit.patchValue({ recipecost: costs.toFixed(3) });

    }
  }
  getcostvalue(i) {
    const form = this.recipeform.get("recipeArray") as FormArray;
    const getquantity = this.recipeform.value.recipeArray[i].quantity;
    const getAvrprice = this.recipeform.value.recipeArray[i].unit_price;
    const cost = parseFloat(getquantity) * parseFloat(getAvrprice);
    if (isNaN(cost)) {
      form.controls[i].patchValue({ Cost: 0 });
    } else {
      form.controls[i].patchValue({ Cost: cost.toFixed(3) });
    }
    this.calculation();
  }
  getcostvalues(i) {
    const form = this.recipeEdit.get("recipeArrays") as FormArray;
    const getquantity = this.recipeEdit.value.recipeArrays[i].quantitys;
    const getAvrprice = this.recipeEdit.value.recipeArrays[i].unit_prices;
    const cost = parseFloat(getquantity) * parseFloat(getAvrprice);
    if (isNaN(cost)) {
      form.controls[i].patchValue({ Costs: 0 });
    } else {
      form.controls[i].patchValue({ Costs: cost.toFixed(3) });
    }
  }
  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }
  deleteRecipeData(data) {
    this.recipeIdDelete = data.item_id;
    this.popUp()
  }

DeleteRecipeItem(data) {
  const query = `?recipe_id=${this.recipe_id}&item_id=${this.recipeIdDelete}&brand_id=${this.selectbrand.brand_id}&branch_id=${this.selectbranch.branch_id}`;
    this.service.deleteMenuRecipes(query).subscribe(result => {
      this.getRecipe();
      this.getRecipes(data);
      this.recipeform.reset()
      this.popUpOff();
      return this.service.openSnackBar('Recipe deleted Successfully');

    });

  }
  
  addrecipe() {
    this.addinfo = true;
    this.addbtn = true;
    this.updatebtn = true;
  }

}
