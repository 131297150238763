import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService } from 'ngx-store';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {
  brands: any;
  warehouse: FormGroup;
  branches: any;
  warehouseList: any;
  edit;
  warehouseEdit: FormGroup;
  warehouseid: any;
  orgData:any;
  WarehouseId:any;
  WarehouseBrandId:any;
  brandGet:any = [];
  orgGet:any = [];

  constructor(private fb: FormBuilder, public service: PosBackOfficeService,
    public ls: LocalStorageService) { }

  ngOnInit() {
    this.warehouse = this.fb.group({
      brand: ['', Validators.required],
      warehouseName: ['', Validators.required],
      warehouseArabicName: ['', Validators.required]
    });
    this.warehouseEdit = this.fb.group({
      brand: ['', Validators.required],
      warehouseName: ['', Validators.required],
      warehouseArabicName: ['', Validators.required]
    })
    this.getOrgDetails();
    
  }
    getOrgDetails() {
      this.orgGet = [];
      this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
        this.orgData = result.body
        for (let i = 0; i < this.orgData.length; i++) {
          this.orgGet.push(this.orgData[i].Organization_id)
        }
        this.getBrand();
    });
  }
 
  getBrand() {
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(
      result => {
        this.brands = result.body;
        for(let i=0; i<this.brands.length; i++){
          this.brandGet.push(this.brands[i].brand_id)
        }
        this.displayWarehouse();
      },
      error => {
        console.log('error', error);
      }
    );
  }
  getBranch(brand) {
    this.service.Branches(`?brand_id=${brand}`).subscribe((r) => {
      this.branches = r.body;
      console.log(this.branches)
    });
  }
  deleteWarehouse(data) {
    this.WarehouseBrandId = data.brand_id;
    this.WarehouseId = data.warehouse_id;
    this.popUp();
  }

  deleteWarehouseModal() {
    this.service.deleteWarehouse(`?warehouse_id=${this.WarehouseId}&brand_id=${this.WarehouseBrandId}`).subscribe(
      result => {
            this.displayWarehouse();
            this.service.openSnackBar('Warehouse Deleted!');
            this.popUpOff();
      },
      error => {
        this.service.openSnackBar(error.error.message);
        this.popUpOff();
      }
    );
  }
  
  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }
  saveWarehouse() {
    if (!this.warehouseList.some(
      r =>
        r.warehouse_name.toUpperCase().replace(/\s/g, '') ==
        this.warehouse.value.warehouseName.toUpperCase().replace(/\s/g, '')
    )
    ) {
      if (
        !this.warehouseList.some(
          r =>
            r.warehouse_name_arabic.replace(/\s/g, '') ==
            this.warehouse.value.warehouseArabicName.replace(/\s/g, '')
        )
      ) {
        this.addWarehouse();
      } else {
        this.service.openSnackBar('Warehouse arabic name already exists');
      }
    } else {
      this.service.openSnackBar('Warehouse name already exists');
    }
  }
  addWarehouse() {
    const payload = {
      brand_id: this.warehouse.value.brand,
      warehouse_name: this.warehouse.value.warehouseName,
      warehouse_name_arabic: this.warehouse.value.warehouseArabicName
    }
    console.log("payload", payload)
    let branchTest;
    if (this.warehouse.valid) {
      if (branchTest == true) {
        this.service.openSnackBar("Branch Name already Assigned")
      } else {
        this.service.postwarehouse(payload).subscribe(res => {
          console.log("warehouse resp", res)
          if (res.status) {
            this.warehouse.reset();
            this.displayWarehouse();
            this.service.openSnackBar("Warehouse Added Successfully")
          }
        })
      }
    } else {
      this.service.openSnackBar("Please fill all the fields")
    }
  }

  displayWarehouse() {
    this.service.getwarehouse(`?filterByBrand=1&brand_id=${this.brandGet}`).subscribe(res => {
      console.log("res", res)
      this.warehouseList = res.body.rows;
    })
  }

  editWarehouse(data, i) {
    // this.getBranch(data.brand.brand_id)
   
    console.log(data, i);
    this.warehouseid = data.warehouse_id
    this.edit = i;
    this.warehouseEdit.patchValue({
      warehouseName: data.warehouse_name,
      warehouseArabicName: data.warehouse_name_arabic
    })
  }
  
  update() {
    console.log('123')
    const payload = {
      warehouse_name: this.warehouseEdit.value.warehouseName,
      warehouse_name_arabic: this.warehouseEdit.value.warehouseArabicName,
      warehouse_id: this.warehouseid
    }
    console.log(payload)
    this.service.putwarehouse(payload).subscribe(res => {
      console.log(res)
        this.edit = null;
        this.displayWarehouse();
        this.service.openSnackBar("Updated Sucessfully")
    })
  }

}
