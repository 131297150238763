import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService } from 'ngx-store';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-transaction-date',
  templateUrl: './transaction-date.component.html',
  styleUrls: ['./transaction-date.component.scss']
})
export class TransactionDateComponent implements OnInit {
  transactionForm: FormGroup;
  brand: any;
  selectedBrand: any;
  branch: any;
  transactiondate: string;
  selectedBranch: any;
  transactionDate: any;
  endshiftData: any;
  updateTrue = false;
  datepick = true;
  showUpdate = false;
  data: any;
  orgData:any;
  orgGet:any=[];
  brandGet:any=[];

  constructor(public fb: FormBuilder, public service: PosBackOfficeService, public ls: LocalStorageService,
    public datePipe: DatePipe) { }

  ngOnInit() {
    this.transactionForm = this.fb.group({
      brandname: ['', Validators.required],
      fromDate: ['', Validators.required],
      branchname: ['', Validators.required],
      status: ['']
    })
    this.getOrgDetails();
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service
      .getOrgDetails(`?userId=${this.ls.get("userId")}`)
      .subscribe((result) => {
        this.orgData = result.body;
        for (const data of this.orgData) {
          this.orgGet.push(data.Organization_id);
        }
        this.brandData();
      });
  }
  brandData() {
    this.brandGet = [];
    this.service
      .getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`)
      .subscribe((result) => {
        this.brand = result.body;
        for (const datas of this.brand) {
          this.brandGet.push(datas.brand_id);
        }
        
      });
  } 
  getBranch() {
    this.service
      .getBranchDetail(
        `?filterbybrand=1&brand_id=${this.transactionForm.value.brandname}`
      )
      .subscribe(
        (result) => {
          this.branch = result.body;
          
        },
        (error) => {
          console.log("error", error);
        }
      );
  }
  
  selectBranch(data: any) {
    this.selectedBranch = data
    console.log(this.selectedBranch)
    this.service.transaction(this.selectedBranch.branch_id).subscribe(res => {
      console.log(res)
      this.transactionDate = res.body[0].transaction_date;
      this.transactionForm.patchValue({ fromDate: this.transactionDate });
    },
    (error) => {
      console.log("error", error);
    })
    this.getEndshift()
  }
  getEndshift() {
    this.service.endshift(this.transactionForm.value.brandname, this.selectedBranch.branch_id).subscribe(res => {
      console.log("res for endshift", res)
      this.endshiftData = res.body
    })
  }
  edit() {
    console.log("clikced", this.endshiftData)
    if (this.transactionForm.valid) {
      this.datepick = false;
      this.showUpdate = true
    } else {
      this.service.openSnackBar("Please Select Brand and Branch")
    }

  }
  status() {
    console.log("123", this.transactionForm.value.status)
  }
  editStatus(data) {
    console.log(data)
    this.transactionForm.patchValue({ status: data.end_shift_status })
    this.data = data
    if (this.endshiftData.length > 0) {
      this.endshiftData.map(res => {
        console.log("res", res)
        if (res.end_shift_id == data.end_shift_id && res.end_shift_status == true) {
          this.service.openSnackBar("Status should be false to Edit")
        }
        if (res.end_shift_id == data.end_shift_id && data.end_shift_status == false) {
          console.log("123", res.end_shift_id, "321", data.end_shift_id)
          this.transactionForm.patchValue({ status: data.end_shift_status })
          console.log("formControlName", this.transactionForm.value)
          console.log()
          return res['edit'] = true
        } else {
          return res['edit'] = false
        }
      })
    }
  }
  updateStatus() {
    console.log("value", this.transactionForm.value.status)
    const payload = {
      end_shift_id: this.data.end_shift_id,
      float_amount_end: this.data.float_amount_end,
      end_shift_status: this.transactionForm.value.status,
      cashier_data: {
        totalAmount: 0,
        discountAmount: 0,
        totalNetAmount: 0,
        totalSales: 0,
        cardAmount: 0
      },
      extra_cash: this.data.extra_cash,
      short_cash: this.data.short_cash,
      cashier_due: this.data.cashier_due
    }

    this.service.updateStatus(payload).subscribe(res => {
      console.log("update status", res.body[1][0])
      if (res.status) {
        this.transactionForm.patchValue({ status: res.body[1][0].end_shift_status })
        console.log(this.transactionForm.value)
        this.getEndshift()
        this.editStatus(res.body[1][0])
      }
    },
    (error) => {
      console.log("error", error);
    })

    }

  filterByDate() {
    const fromdate = this.datePipe.transform(this.transactionForm.value.fromDate, "yyyy-MM-dd");
    this.transactiondate = fromdate
    console.log(this.transactiondate)
  }

  updateTransaction() {
    console.log(this.endshiftData)
    console.log(this.transactionForm.value.fromDate)
    let TransDate;
    if (this.transactiondate) {
      TransDate = this.transactionForm.value.fromDate + 'T00:00:00.000Z'
    } else {
      TransDate = this.transactionForm.value.fromDate
    }
    console.log(TransDate)
    let update = true;
    for (const ele of this.endshiftData) {
      if (TransDate == ele.transaction_date && ele.end_shift_status == false) {
        update = false;
      }
    }

    if (this.transactionForm.valid) {

      console.log("update", update)
      if (update) {
        const payload = {
          transaction_date: this.transactiondate,
          branch_id: this.selectedBranch.branch_id
        }
        this.service.updateTransaction(payload).subscribe(res => {
          console.log("response for update", res)
          if (res.status) {
            this.service.openSnackBar("Transaction Date Updated Successfully")
            this.datepick = true;
            this.showUpdate = false;
          }
        },
        (error) => {
          console.log("error", error);
        })
      } else {
        this.service.openSnackBar("Status Should be False")
      }
    } else {
      this.service.openSnackBar("Please Fill the Valid Fields")
    }
  }
  cancelTransaction() {
    this.datepick = true;
    this.showUpdate = false
  }
}
