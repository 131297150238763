import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray,
  AbstractControl, ValidationErrors
} from '@angular/forms';
import { Routes, Router, RouterModule } from '@angular/router';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { error } from '@angular/compiler/src/util';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';


const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';
  if (!value) {
    return null
  }

  let upperCaseCharacters = /[A-Z]+/g;
  let lowerCaseCharacters = /[a-z]+/g;
  let numberCharacters = /[0-9]+/g;
  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (upperCaseCharacters.test(value) === false || lowerCaseCharacters.test(value) === false || numberCharacters.test(value) === false || specialCharacters.test(value) === false) {
    return {
      passwordStrength: 'Password must contain at least two of the following: numbers, lowercase letters, uppercase letters, or special characters.'
    }

  }}
@Component({
  selector: 'app-main-registration',
  templateUrl: './main-registration.component.html',
  styleUrls: ['./main-registration.component.scss']
})
export class MainRegistrationComponent implements OnInit {
  hide = true;
  hide1 = true;
  register = true;
  regOtp  = false;
  mainRegistration: FormGroup;
  mainOtp: FormGroup;
  showRole  = true;
  formSubmitAttempt: boolean;
  textAlign: string;
  password:any;
  constructor(private fb: FormBuilder, public service: PosBackOfficeService, public ls: LocalStorageService,
    private router: Router) {}
    

  ngOnInit() {
    
    this.mainRegistration = this.fb.group({
      name: ['', Validators.required],
      nameArabic: ['', Validators.required],
      phone: ["", [Validators.required,Validators.pattern('^((\\+91-?)|0)?[0-9]{8,10}$')]],
      email: [
        '',
        [ 
          Validators.required, 
          Validators.pattern( 
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ 
          ), 
        ]
      ],
      userName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]{0,50}$')]],
      password: ['', [Validators.required, Validators.minLength(8),PasswordStrengthValidator]],
      // secretKey: ['', Validators.required]
    });
    this.mainOtp = this.fb.group({
      otp: ['', Validators.required],
    });
    this.inputAlignment();
  }

    inputAlignment() {
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  selectDomin(value) {
    if (value === 'backOffice') {
      this.mainRegistration.patchValue({ role: 'bo' });
      this.showRole = false;
    }
    if (value === 'pos') {
      this.showRole = true;
    }
  }

  isFieldValid(field: string) {
    return (
      (!this.mainRegistration.get(field).valid && this.mainRegistration.get(field).touched) ||
      (this.mainRegistration.get(field).untouched && this.formSubmitAttempt) 
    );
  }
  isFieldValids(field: string) {
    return (
      (!this.mainOtp.get(field).valid && this.mainOtp.get(field).touched) ||
      (this.mainOtp.get(field).untouched && this.formSubmitAttempt) 
    );
  }

  // submit() {
  //   this.mainRegistration.value.role = `bo`;
  //   this.mainRegistration.value.domin = `backOffice`;

  //   this.service.adminRegister(this.mainRegistration.value).subscribe(
  //     result => {
  //       this.service.openSnackBar('Admin Registered');
  //       this.mainRegistration.reset();
  //       this.showRole = true;
  //       this.router.navigate(['/login']);
  //     },
  //     error => {
  //       this.service.openSnackBar('Unauthorized');
  //     }
  //   );
  //   this.formSubmitAttempt = true;
  // }
  login(){
    this.router.navigate(['/login']);
  }
  submit() {
   const payload = {
    name:this.mainRegistration.value.name,
    nameArabic:this.mainRegistration.value.nameArabic,
    email:this.mainRegistration.value.email,
    userName:this.mainRegistration.value.userName ,
    phone:"+91"+this.mainRegistration.value.phone,
    
  }
    this.password = this.mainRegistration.value.password;
    this.service.adminRegisters(payload).subscribe(
      result => {
       // this.service.openSnackBar('Admin Registered');
        this.mainRegistration.reset();
        this.showRole = true;
        this.register = false;
        this.regOtp = true;
        this.ls.set('id', result.data[0]._id)
        this.ls.set('name', result.data[0].name)
        this.ls.set('nameArabic', result.data[0].nameArabic)
        this.ls.set('username', result.data[0].userName)
        this.ls.set('email', result.data[0].email)
        this.ls.set('phone', result.data[0].phone)
        this.ls.set('userid', result.data[0].userid)
        },
      error => {
        // alert(JSON.stringify(this.password))
        this.service.openSnackBar(error.error.message);
      }
    );
    this.formSubmitAttempt = true;
  }
  verify() {
    const payload = {
     otp:parseFloat(this.mainOtp.value.otp),
    _id:this.ls.get('id')
          
   }
   const payloads={
    domin: "backOffice",
    email: this.ls.get('email'),
    name: this.ls.get('name'),
    nameArabic:this.ls.get('nameArabic'),
    password: this.password,
    role: "bo",
    secretKey: "8mdqk",
    userName: this.ls.get('username'),
    admin:true
   }
     this.service.adminOtp(payload).subscribe(
       result => {
         this.service.adminReg(payloads).subscribe(
           res=>{
            this.service.openSnackBar('Admin Registered');
            this.mainOtp.reset();
            this.showRole = true;
             this.router.navigate(['/login']);
           }
         );
        
       },
       error => {
         this.service.openSnackBar(error.error.message);
       }
     );
     this.formSubmitAttempt = true;
   }
   resend() {
    const payload = {
            _id:this.ls.get('id'),
            name:this.ls.get('name') ,
            email:this.ls.get('email'),
            userName:this.ls.get('username'),
            phone:"+"+this.ls.get('phone') ,
            userid:this.ls.get('userid'),
                 
   }
     this.service.adminResend(payload).subscribe(
       result => {
        this.mainOtp.patchValue({otp:""});
        this.service.openSnackBar('OTP has been send to Your Mobile');
      
       },
       error => {
         this.service.openSnackBar(error.error.message);
       }
     );
     
   }
}
