import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MatDatepickerInputEvent } from '@angular/material';
import { LocalStorageService } from 'ngx-store';
import { PosBackOfficeService } from '../providers/pos-back-office.service';

@Component({
  selector: 'app-grv-by-item',
  templateUrl: './grv-by-item.component.html',
  styleUrls: ['./grv-by-item.component.scss']
})
export class GrvByItemComponent implements OnInit {
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  grvbyItem: FormGroup;
  systemDate: string;
  brands: any;
  branches: any;
  brandName: any;
  supplierName: any;
  maxDate = new Date();
  maxToDate = new Date();
  maxFromDate = new Date();
  minToDate: any;
  fromdate;
  todate;
  itemReport: any = [];
  p: any;
  showReports = true;
  showReport = false;
  showPrint = false;
  showPrint1 = false;
  selecteditem: any;
  showOrderDetails = false;
  totalAmountItem: any;
  totalQuantityItem: any;
  branchName: any;
  totalitemAmount: any;
  totalitemQuantity: any;
  totalitemPrice: any;
  totalPriceItem: any;
  suppllier: any;
  itemtotal: any;
  itemunitprice: any;
  orgData: any;
  orgGet: any = [];
  brandGet: any = [];


  constructor(
    private fb: FormBuilder,
    private adapter: DateAdapter<any>,
    public service: PosBackOfficeService,
    private datePipe: DatePipe,
    public ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.grvbyItem = this.fb.group({
      fromDate: [''],
      toDate: [new Date().toISOString().slice(0, 10)],
      brand: '',
      branch: '',
      suppliers: ''
    });
    this.dateAdapter();
    this.getOrgDetails();
    this.systemDate = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd HH:mm');
    this.dateValidation();
  }

  getOrgDetails() {
    this.orgGet = [];
    this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
      this.orgData = result.body
      for (let i = 0; i < this.orgData.length; i++) {
        this.orgGet.push(this.orgData[i].Organization_id)
      }
      this.getBrand();
    }, error => {
      this.service.openSnackBar(error.error.message);
    });
  }
  getBrand() {
    this.brandGet = [];
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(result => {
      this.brands = result.body;
      for (let i = 0; i < this.brands.length; i++) {
        this.brandGet.push(this.brands[i].brand_id)
      }
    }, error => {
      this.service.openSnackBar(error.error.message);
    }
    );
  }
  getBranch(brand, brandName) {
    this.brandName = brandName;
    this.service.getBranchDetail(`?filterbybrand=1&brand_id=${brand}`).subscribe(
      result => {
        this.branches = result.body;

      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  getBranchName(data) {
    let returnBranch = 'NA';
    const queryBranch = this.branches.filter(res => res.branch_id == data);
    if (queryBranch.length !== 0) {
      returnBranch = queryBranch[0].branch_name;
    }
    return returnBranch;
  }


  dateValidation() {
    this.minToDate = this.grvbyItem.value.fromDate;
    this.maxFromDate = this.grvbyItem.value.toDate;
  }
  dateAdapter() {
    this.adapter.setLocale('en-GB');
  }
  getSupplierNames(brandId) {
    this.service.getSuppliergrv(`?id=${brandId}`).subscribe(
      res => {
        this.supplierName = res.body;
      },
      error => {
        console.log(error.error.message);
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  getitem() {
    this.itemunitprice = 0;
    this.itemtotal = 0;
    let sum1 = 0;
    let sum2 = 0;
    if (this.grvbyItem.value.suppliers == "All") {
      this.suppllier = 0;
    } else {
      this.suppllier = 1;
    }
    this.service.getitemgrv(`?brand_id=${this.grvbyItem.value.brand}&branch_id=${this.grvbyItem.value.branch}&fromdate=${this.fromdate}&todate=${this.todate}&filterbysupplier=${this.suppllier}&supplier_id=${this.grvbyItem.value.suppliers}`).subscribe(
      res => {
        this.itemReport = res.body;
        this.branchName = this.grvbyItem.value.branch;
        this.showReports = false;
        this.showReport = true;
        for (let i = 0; i < res.body.length; i++) {
          for (let j = 0; j < res.body[i].item.length; j++) {
            sum1 += parseFloat(res.body[i].item[j].unit_price)
            sum2 += parseFloat(res.body[i].item[j].total_amount)
            this.itemunitprice = sum1;
            this.itemtotal = sum2;
          }
        }
      },
      error => {
        console.log(error.error.message);
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  getSum(index, array): any {
    let sum = 0;
    const calcArray = array;
    if (calcArray) {
      console.log("calcArray", calcArray)
      for (let i = 0; i < calcArray.length; i++) {
        sum += parseFloat(calcArray[i][index]);
      }
      console.log('sum', sum);
      return sum;
    }
  }
  sortDate() {
    this.dateValidation();
    this.fromdate =
      this.datePipe.transform(this.grvbyItem.value.fromDate, 'yyyy-MM-dd') ||
      this.datePipe.transform(this.grvbyItem.value.toDate, 'yyyy-MM-dd') ||
      '';
    this.todate =
      this.datePipe.transform(this.grvbyItem.value.toDate, 'yyyy-MM-dd') || this.fromdate || '';
  }
  print() {
    setTimeout(() => {
      this.printReceipt('item_list_print');
    }, 500);
    this.showPrint = true;
  }
  printReceipt(div): void {
    // let printContents, popupWin;
    const printContents = document.getElementById(div).innerHTML;
    const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Sales Report</title>
        <style>
        //........Customized style.......
        </style>
      </head>
  <body onload="window.print();window.close()">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }
}