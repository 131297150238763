import { Component, OnInit,EventEmitter } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'ngx-store';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})
export class DiscountComponent implements OnInit {
  discounts = [];
  deleteQuery: any;
  i: number;
  p = 1;
  deleteId: any;
  deleteRule: any;
  discount: FormGroup;
  updateModal: FormGroup;
  formSubmitAttempt: boolean;
  textAlign: string;
  discountID: '';
  showAvailable = true;
  brandName: any;
  branchName: any;
  discountData: any;
  brandID: any;
  showPdfErrorMsg:any;
  branchID: any;
  imagedata:any;
  orgData: any;
  brandData: any;
  branchData: any;
  logo = './../../assets/images/uploadimage.jpg';
  brandGet: any = [];
  orgGet: any = [];
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) { }
  public uploader: FileUploader = new FileUploader({});
  ngOnInit() {
    this.discount = this.fb.group({
      brand_name: ['', Validators.required],
      brand_id: ['', Validators.required],
      branch_name: ['', Validators.required],
      branch_id: ['', Validators.required],
      discount_name: ['', Validators.required],
      description: ['', Validators.required],
     discount_percentage: ['', [Validators.required,Validators.min(0), Validators.max(100)]],
     discount_price: ['', [Validators.required,Validators.min(0)]],
      discount_type: ['', Validators.required],
      discount_status: [true],
      image_id:['']
    });

    this.updateModal = this.fb.group({
      discount_id: [''],
      brand_name: ['', Validators.required],
      branch_name: ['', Validators.required],
      brand_id: [''],
      branch_id: [''],
      discount_name: ['', Validators.required],
      description: ['', Validators.required],
      discount_percentage: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      discount_price: ['', [Validators.required, Validators.pattern('^[0-9.]*')]],
      discount_type: ['', Validators.required],
      discount_status: [true],
      image_id:['']
    });
    this.getOrgDetails();
    this.inputAlignment();
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
      this.orgData = result.body
      for (const data of this.orgData) {
        this.orgGet.push(data.Organization_id);
      }
      this.getBrandDetails();
    });
  }
  getBrandDetails() {
    this.brandGet = [];
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(
      result => {
        this.brandData = result.body;
        for (const datas of this.brandData) {
          this.brandGet.push(datas.brand_id);
        }
        this.Discount('get');
      },
      error => {
        console.log(error);
      }
    );
  }

  getBranch(brand) {
    this.service.getBranchDetail(`?filterbybrand=1&brand_id=${brand}`).subscribe(
      result => {
        this.branchData = result.body;
      },
      error => {
        console.log('error', error);
      }
    );
  }
  public onFileSelected(event, i) {
    const file: File = event[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.showPdfErrorMsg = false;
    } else {
      this.showPdfErrorMsg = true;
    }
    const FileSize = file.size / 1024 / 1024;

    if (FileSize > 0.5) {
      return this.service.openSnackBar(`File size exceeds 500KB`);
    }
    this.imagedata = file.name;
    const formData = new FormData();
    formData.append('file', file);
    this.service.upload(formData).subscribe(
      result => {
        this.discount.patchValue({ image_id: result.body.image_id });
        this.service.getImage(result.body.image_id).subscribe((r: any) => {
          this.logo = r.body[0].image_data;
        });
      },
      error => error
    );
  }
  patchman1() {
    const one = this.discount.get('discount_name').value;
    this.discount.patchValue({ 'discount_name': one.replace(/^ +/gm, '') });
  }
  inputAlignment() {
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }

  bindingBrand(data) {
    this.discount.patchValue({
      brand_id: data.brand_id
    });
    this.brandID = data.brand_id
    this.discount.get('branch_id').reset()

  }

  bindingBranch(data) {
  this.discount.patchValue({
      branch_id: data.branch_id
    });
    this.branchID = data.branch_id
    console.log("patch value", this.discount)
  }
  public onFileSelected1(event: EventEmitter<File[]>, i) {
    const file: File = event[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.showPdfErrorMsg = false;
    } else {
      this.showPdfErrorMsg = true;
    }
    const FileSize = file.size / 1024 / 1024;

    if (FileSize > 0.5) {
      return this.service.openSnackBar(`File size exceeds 500KB`);
    }
    this.imagedata = file.name;
    const formData = new FormData();
    formData.append('file', file);
    this.service.upload(formData).subscribe(
      result => {
        this.updateModal.patchValue({ image_id: result.body.image_id });
        this.service.getImage(result.body.image_id).subscribe((r: any) => {
          this.discounts[i].logo = r.body[0].image_data;
        });
      },
      error => error
    );
  }

  discountPost(rule, body, query) {
    this.brandName = this.discount.value.brand_name;
    this.branchName = this.discount.value.branch_name;
    console.log("rule", rule, "body", body, "query", query)
    const arrayData = [];
    this.discounts.map(res => {
      if (res.brand_name == this.discount.value.brand_name) {
        arrayData.push(res);
      }
    });
    console.log("this.discounts", this.discounts)
    if (
      !arrayData.some(
        r =>
          (r.discount_name).toUpperCase().replace(/\s/g, '') ==
          (this.discount.value.discount_name).toUpperCase().replace(/\s/g, '')
      )
    ) {
      this.Discount(rule, body, query);
    } else {
      this.service.openSnackBar('Discount name already exists');
    }
  }
  discountPut(rule, body, query, index) {
    const arrayData = [];
    if (this.discountData.discount_name == this.updateModal.value.discount_name) {
      const payload:any = {
        branch_id: this.updateModal.value.branch_id,
        brand_id: this.updateModal.value.brand_id,
        discount_id: this.updateModal.value.discount_id,
        discount_percentage: this.updateModal.value.discount_percentage,
        discount_price: this.updateModal.value.discount_price,
        discount_status:this.updateModal.value.discount_status,
        discount_type: this.updateModal.value.discount_type,
        description:this.updateModal.value.description,
        image_id:this.updateModal.value.image_id,
      }     
      this.Discount(rule, payload, query);
    } else {
      this.Discount(rule, body, query);
      this.discounts.map(res => {
        if (res.brand_name == this.discountData.brand_name) {
          if (res.discount_id !== this.discountData.discount_id) {
            arrayData.push(res);
          }
        }
      });
    }
   
  }
  Discount(rule, payload = '', query = `?filterByBrand=1&brand_id=${this.brandGet}`, index = 1) {
    console.log("this.discounts", rule, "payload", payload, "query", query, "index", index)
    this.service.Discount(rule, payload, query).subscribe(
      result => {
        if (rule === 'get') {
          this.discounts = result.body;
          this.discounts.map(element => {
            this.service.getImage(element.image_id).subscribe((r: any) => {
              if (r.body.length !== 0) {
                element.logo = r.body[0].image_data;
              }
            });
          });
          if (this.discounts.length > 0) {
            this.showAvailable = false;
          } else {
            this.showAvailable = true;
          }
        }
        if (rule === 'post') {
         
          this.Discount('get');
          this.discount.patchValue({ brand_name: this.brandName });
          this.discount.patchValue({ brand_id: this.brandID });
          this.discount.patchValue({ branch_name: this.branchName });
          this.discount.patchValue({ branch_id: this.branchID });
          this.discount.patchValue({ discount_status: true });
          this.service.openSnackBar('Discount Added!');
          this.discount.reset();
        }
        if (rule === 'put') {
          this.service.openSnackBar('Discount Updated!');
          this.Discount('get');
          this.discountID = '';
        }
      },
      error => {
        this.discountID = '';
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  keyUpPercentage1(value) {
    if (value !== '' && value !== null) {
      this.updateModal.patchValue({ discount_price: 0 });
    } else {
      this.updateModal.patchValue({ discount_price: '' });
    }
  }

  keyUpPrice1(value) {
    if (value !== '' && value !== null) {
      this.updateModal.patchValue({ discount_percentage: 0 });
    } else {
      this.updateModal.patchValue({ discount_percentage: '' });
    }
  }

  keyUpPercentage(value) {
    if (value !== '' && value !== null) {
      this.discount.patchValue({ discount_price: 0 });
    } else {
      this.discount.patchValue({ discount_price: '' });
    }
  }

  keyUpPrice(value) {
    if (value !== '' && value !== null) {
      this.discount.patchValue({ discount_percentage: 0 });
    } else {
      this.discount.patchValue({ discount_percentage: '' });
    }
  }

  deleteDiscount(rule, id, query) {
    this.deleteRule = rule;
    this.deleteId = id;
    this.deleteQuery = query;
    this.popUp();
  }

  deleteDiscountModal() {
    this.service
      .deleteDiscount(
        `?id=${this.deleteId}`
      )
      .subscribe(
        () => {
          this.service.openSnackBar('Data Deleted!');
          this.Discount('get');
          this.service.openSnackBar('Discount Deleted!');
          this.popUpOff();
        },
        error => {
          this.service.openSnackBar(error.error.message);
        }
      );
  }

  edit(data) {
    this.discountData = data;
    this.discountID = data.discount_id;
    this.updateModal.patchValue({
      discount_id: data.discount_id,
      brand_name: data.brand_name,
      brand_id: data.brand_id,
      branch_name: data.branch_name,
      branch_id: data.branch_id,
      discount_name: data.discount_name,
      discount_type: data.discount_type,
      discount_percentage: data.discount_percentage,
      discount_price: data.discount_price,
      discount_status: data.discount_status,
      image_id:data.image_id
    });
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  isFieldValid(field: string) {
    return (
      (!this.discount.get(field).valid && this.discount.get(field).touched) ||
      (this.discount.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
