import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { LocalStorageService } from 'ngx-store';
import { PosBackOfficeService } from '../providers/pos-back-office.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  imagedata = "";
  textAlign: string;
  space: string;
  rowChange;
  rowOrder;
  logo = './../../assets/images/uploadimage.jpg';
  backupOrg;
  noMatchingResults: boolean;
  orgLogo = [];
  orgData1;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) {}
  organization: FormGroup;
  orgUpdate: FormGroup;
  i: number;
  trim: string;
  showAvailable = true;
  orgData;
  showPdfErrorMsg: boolean;
  formSubmitAttempt: boolean;
  showUpdateButton: boolean;
  showSubmitButton = true;
  orgId;
  index: number;
  orgIdDelete;
  orgIdEdit: '';
  p = 1;
  public uploader: FileUploader = new FileUploader({});

  ngOnInit() {
    this.orgUpdate = this.fb.group({
      Organization_name:  ['',[Validators.required]],   
      description: ['',[Validators.required]],
      organization_slogan: ['', Validators.required],
      image_id: [''],
      package_name:['', Validators.required],
      phone_no: ["", [Validators.required,Validators.pattern('^((\\+91-?)|0)?[0-9]{10,10}$'),]],

    });

    this.organization = this.fb.group({
      Organization_name:  ['',[Validators.required]],
      Organization_name_arabic:  [''],
      description: ['',[Validators.required]],
      organization_slogan: ['', Validators.required],
      image_id: ['', [Validators.required]],
      package_name:['', Validators.required],
      phone_no: ["", [Validators.required,Validators.pattern('^((\\+91-?)|0)?[0-9]{10,10}$')]],

    });
    this.getOrgDetails();
    this.inputAlignment();
  }

patchman(){
  const one =this.organization.get('Organization_name').value;
  this.organization.patchValue({'Organization_name':one.replace(/^ +/gm, '')})
  const two =this.organization.get('Organization_name_arabic').value;
  this.organization.patchValue({'Organization_name_arabic':two.replace(/^ +/gm, '')})
  const three =this.organization.get('description').value;
  this.organization.patchValue({'description':three.replace(/^ +/gm, '')})
  const four =this.organization.get('organization_slogan').value;
  this.organization.patchValue({'organization_slogan':four.replace(/^ +/gm, '')})

}
  inputAlignment() {
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }

  saveOrg() {
    if (!this.orgData.some(
      r =>
        r.Organization_name.toUpperCase().replace(/\s/g, '') ==
        this.organization.value.Organization_name.toUpperCase().replace(/\s/g, '')
    )
  ) {   
   

    if (
      !this.orgData.some(
        r =>
          r.phone_no ==
          this.organization.value.phone_no
      )
    ) {
      this.save();
    } else {
      this.service.openSnackBar('Phone no already exists');
    }
     
 
  } else {
    this.service.openSnackBar('Organization name already exists');
  }
  }

  save() {
    const data = {
      Organization_name: this.organization.value.Organization_name,
      Organization_name_arabic: this.organization.value.Organization_name_arabic,
      description: this.organization.value.description,
      organization_slogan: this.organization.value.organization_slogan,
      image_id: this.organization.value.image_id,
      phone_no: this.organization.value.phone_no,
      package_name:this.organization.value.package_name,
      User_id:this.ls.get('userId')
    };
    

    this.service.saveOrgDetails(data).subscribe(
      result => {
        this.getOrgDetails();
        this.service.openSnackBar('Organization Added!');
        this.organization.reset();
        this.imagedata = '';
        this.logo = './../../assets/images/uploadimage.jpg';
      },
      error => {
        this.service.openSnackBar(error.error.message);
        }
    );
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.showPdfErrorMsg = false;
    } else {
      this.showPdfErrorMsg = true;
    }
    const FileSize = file.size / 1024 / 1024;

    if (FileSize > 0.5) {
      return this.service.openSnackBar(`File size exceeds 500KB`);
    }
    this.imagedata = file.name;
    const formData = new FormData();
    formData.append('file', file);
    this.service.orgupload(formData).subscribe(
      result => {
        this.organization.patchValue({ image_id: result.body.image_id });
        this.service.orggetImage(result.body.image_id).subscribe((r: any) => {
          this.logo = r.body[0].image_data;
        });
      },
      error => error
    );
  }

  public onFileSelected1(event: EventEmitter<File[]>, i) {
    const file: File = event[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.showPdfErrorMsg = false;
    } else {
      this.showPdfErrorMsg = true;
    }
    const FileSize = file.size / 1024 / 1024;

    if (FileSize > 0.5) {
      return this.service.openSnackBar(`File size exceeds 500KB`);
    }
    this.imagedata = file.name;
    const formData = new FormData();
    formData.append('file', file);
    this.service.orgupload(formData).subscribe(
      result => {
        this.orgUpdate.patchValue({ image_id: result.body.image_id });
        this.service.orggetImage(result.body.image_id).subscribe((r: any) => {
          this.orgData[i].logo = r.body[0].image_data;
        });
      },
      error => error
    );
  }

  getOrgDetails() {
    this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
      this.orgData=result.body
      this.orgData.map(element => {
        this.service.getImage(element.image_id).subscribe((r: any) => {
          if (r.body.length !== 0) {
            element.logo = r.body[0].image_data;
          }
        });
      });
      this.backupOrg = result.body;
      if (this.orgData.length > 0) {
        this.showRegistrationData(this.backupOrg);
        this.showAvailable = false;
      } else {
        this.showAvailable = true;
      }
    });
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }
  edit(index, item) {
    console.log("item",item)
    this.orgData1 = item;
    this.orgIdEdit = item.Organization_id;
    this.index = index;
    this.orgId = item.Organization_id;
    this.orgUpdate.patchValue({
      Organization_name: item.Organization_name,
      description: item.description,
      organization_slogan: item.organization_slogan,
      image_id: item.image_id,
      phone_no: item.phone_no,
      package_name : item.package_name
    });
  }

  deleteOrg(data) {
    this.orgIdDelete = data.Organization_id;
    this.popUp();
  }

  deleteOrgModal() {
    this.service.deleteOrgDetails(this.orgIdDelete).subscribe(
      result => {
            this.getOrgDetails();
            this.service.openSnackBar('Organization Deleted!');
            this.popUpOff();
      },
      error => {
        this.service.openSnackBar(error.error.message);
        this.popUpOff();
      }
    );
  }

  updateOrgData() {
    const arrayData = [];
    if (
      this.orgData1.Organization_name == this.orgUpdate.value.Organization_name
    ) {
      return this.updateOrg();
    } else {
      this.orgData.map(res => {
        if (res.Organization_id !== this.orgData1.Organization_id) {
          arrayData.push(res);
        }
      });
    }

    if (
      !arrayData.some(
        r =>
          r.Organization_name.toUpperCase().replace(/\s/g, '') ==
          this.orgUpdate.value.Organization_name.toUpperCase().replace(/\s/g, '')
      )
    ) {
     
        this.updateOrg();
    } else {
      this.service.openSnackBar('Organization name already exists');
    }
  }

  updateOrg() {
    const updateOrg = {
      Organization_name: this.orgUpdate.value.Organization_name,
      description: this.orgUpdate.value.description,
      id: this.orgId,
      User_id:this.ls.get('userId'),
      organization_slogan: this.orgUpdate.value.organization_slogan,
      image_id: this.orgUpdate.value.image_id,
      phone_no: this.orgUpdate.value.phone_no,
      package_name:this.orgUpdate.value.package_name
    };
    this.service.updateOrgDetails(updateOrg).subscribe(
      result => {
        this.service.openSnackBar('Organization details Updated!');
        this.getOrgDetails();
        this.orgIdEdit = '';
        this.organization.reset();
        this.imagedata = '';
      },
      error => {
             this.orgIdEdit = '';
             this.service.openSnackBar(error.error.message);
                    
      }
    );
  }

  isFieldValid(field: string) {
    return (
      (!this.organization.get(field).valid && this.organization.get(field).touched) ||
      (this.organization.get(field).untouched && this.formSubmitAttempt)
    );
  }

  showRegistrationData(data) {
    if (data.length > 10) {
      this.noMatchingResults = false;
    } else {
      if (data.length === 0) {
        this.noMatchingResults = true;
      } else {
        this.noMatchingResults = false;
      }
    }
  }
  pagination(){
    if(window.innerHeight >= 800 && window.innerHeight<=900){
return 22
    }
    return 10
  }
 
  applyFilter(filter: string) { 
    this.space=filter.trim()
    const Orgfilter = this.backupOrg;
    this.orgData = Orgfilter.filter(item => {
      if (
        item.Organization_name
          .toString()
          .toLowerCase()
          .indexOf(this.space.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
    this.showRegistrationData(this.orgData);
  }
}
