import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { PosBackOfficeService } from "../providers/pos-back-office.service";
import { LocalStorageService, CookiesStorageService } from "ngx-store";
@Component({
  selector: "app-online-setup",
  templateUrl: "./online-setup.component.html",
  styleUrls: ["./online-setup.component.scss"],
})
export class OnlineSetupComponent implements OnInit {
  onlineSetUp: FormGroup;
  onlineSources: FormGroup;
  brands: any;
  branches: any;
  formAttemptSubmit: boolean;
  populateData: any;
  showOnlineData: boolean;
  showUpdateButton: boolean;
  branchID: any;
  disableInput: boolean;
  showType: boolean;
  onlinetype: any;
  onlinePaymentSource: FormGroup;
  data: any;
  onlineTypeId: number;
  branchId: any;
  p = 1;
  onlinesource_payment_name:boolean
  onlinesource_payment_id:any;
  onlineSourcePayment: any;
  show: boolean;
  onlineId: any;
  onlinePayment: FormGroup;
  radioValue: any;
  orgData: any;
  orgGet: any = [];
  brandGet: any = [];
  deleteDeliveryDatas: any;
  onlinID: any;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) { }
  ngOnInit() {
    this.onlineSetUp = this.fb.group({
      brand_id: ["", Validators.required],
      branch_id: ["", Validators.required],
      online_type_english: ["", [Validators.required]],
      online_type_arabic: ["", [Validators.required]],
    });
    this.onlineSources = this.fb.group({
      brand_id: [""],
      branch_id: [""],
      online_type_english: ["", [Validators.required]],
      online_type_arabic: ["", [Validators.required]],
      online_status: [true],
      onlinetype_id: [""],
    });
    this.onlinePaymentSource = this.fb.group({
      onlineTypeEng: ["", Validators.required],
      paymentTypeEng: ["", Validators.required],
      paymentTypeArabic: ["", Validators.required],
    });
    this.onlinePayment = this.fb.group({
      brand_id: [""],
      branch_id: [""],
      paymentEnglish: [""],
      paymentArabic: [""],
    });
    this.getOrgDetails();
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service
      .getOrgDetails(`?userId=${this.ls.get("userId")}`)
      .subscribe((result) => {
        this.orgData = result.body;
        for (const data of this.orgData) {
          this.orgGet.push(data.Organization_id);
        }
        this.getBrand();
      });
  }
  getBrand() {
    this.brandGet = [];
    this.service
      .getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`)
      .subscribe((result) => {
        this.brands = result.body;
        for (const datas of this.brands) {
          this.brandGet.push(datas.brand_id);
        }
        this.getOnlineSetup();
        this.onlineSource();
      });
  }
  patchman() {
    const one = this.onlineSetUp.get("brandName").value;
    this.onlineSetUp.patchValue({ brandName: one.replace(/^ +/gm, "") });
  }
  getBranch() {
    this.service
      .getBranchDetail(
        `?filterbybrand=1&brand_id=${this.onlineSetUp.value.brand_id}`
      )
      .subscribe(
        (result) => {
          this.branches = result.body;
        },
        (error) => {
          console.log("error", error);
        }
      );
  }

  submitOnlineSetup() {
    this.service.saveOnlineDeliveryData(this.onlineSetUp.value).subscribe(
      (res) => {
        this.getOnlineSetup();
        this.onlineSetUp.reset();
        this.service.openSnackBar("Data Saved!");
        this.onlineSource();
      },
      (error) => {        
          this.service.openSnackBar(error.error.message);
      }
    );
  }
  selectonlineSource(data) {
    this.data = data;
    this.service
      .getonlinesourcePayment(
        `?orderby=onlinetype&onlinetype_id=${data.onlinetype_id}`
      )
      .subscribe((res) => {
        this.onlineSourcePayment = res.body;
        this.show = true;
      });
  }
  radioButton(card) {
    this.radioValue = card;
  }
  paymentSubmit() {
    const payload = {
      brand_id: this.data.brand_id,
      branch_id: this.data.branch_id,
      payment_type_english: this.onlinePaymentSource.value.paymentTypeEng,
      payment_type_arabic: this.onlinePaymentSource.value.paymentTypeArabic,
      onlinetype_id: this.data.onlinetype_id,
      online_source_payment_type: this.radioValue,
    };
    if (this.onlinePaymentSource.valid) {
      this.service.postPayment(payload).subscribe((res) => {
        this.onlinePaymentSource.reset();
        this.selectonlineSource(this.data);
      },
      
      (error) => {        
          this.service.openSnackBar(error.error.message);
      }
      );
    } else {
      this.service.openSnackBar("Please fill all the feilds");
    }
  }
  editPayment(data) {
    this.onlineId = data.onlinesource_payment_id;
    this.onlinePayment.patchValue({
      paymentEnglish: data.payment_type_english,
      paymentArabic: data.payment_type_arabic,
    });
  }
  updatePayment(data) {
    if (data.payment_type_english == this.onlinePayment.value.paymentEnglish) {
      const payload = {
        payment_type_arabic: this.onlinePayment.value.paymentArabic,
        onlinesource_payment_id: this.onlineId,
      };
      this.service.updateonlinesourcePayment(payload).subscribe(
        (res) => {
          this.selectonlineSource(this.data);
          this.onlineId = "";
          this.service.openSnackBar("Data Updated!");
        },
        (error) => {
          this.service.openSnackBar(error.error.message);
        }
      );
    } else {
      const payload = {
        payment_type_english: this.onlinePayment.value.paymentEnglish,
        payment_type_arabic: this.onlinePayment.value.paymentArabic,
        onlinesource_payment_id: this.onlineId,
      };
      this.service.updateonlinesourcePayment(payload).subscribe(
        (res) => {
          this.selectonlineSource(this.data);
          this.onlineId = "";
          this.service.openSnackBar("Data Updated!");
        },
        (error) => {
          this.service.openSnackBar(error.error.message);
        }
      );
    }
  }
  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }
  deletePayments(data) {
    this.onlineTypeId=data.onlinetype_id
    this.popUp();
  }
  deletePayment(data) {
this.onlinesource_payment_id=data.onlinesource_payment_id
this.popUp();
this.onlinesource_payment_name=true
  }
  deletePayment1(data) {
    const query = `?onlinesource_payment_id=${this.onlinesource_payment_id}`;
    this.service.deleteonlinesourcePayment(query).subscribe(
      (res) => {
        this.onlinesource_payment_name=false
        this.selectonlineSource(this.data);
        this.service.openSnackBar("Data Deleted!");
        this.popUpOff();
      },
      (error) => {
        this.service.openSnackBar(error.error.message);
      
      }
    );
  }
  // online payment type
  deleteOnlines(data) {
    console.log("data",data)
    const query = `/onlinetype?onlinetype_id=${this.onlineTypeId}`;
    this.service.deleteOnlineData(query).subscribe(
      (res) => {
        this.getOnlineSetup();
        this.onlineSource();
        this.service.openSnackBar("Data Deleted!");
        this.popUpOff();
      },
      (error) => {
        this.service.openSnackBar(error.error.message);
        this.popUpOff();
      }
    );
  }
  updateOnlineSetup(data) {
    console.log("this.onlineSetUp.value", this.onlineSetUp.value);
    if (data.online_type_english == this.onlineSources.value.online_type_english) {
      const payload = {
        brand_id:data.brand_id,
        branch_id:data.branch_id,
        online_type_arabic: this.onlineSources.value.online_type_arabic ,
        online_status: this.onlineSources.value.online_status,
        onlinetype_id: data.onlinetype_id,
   
      };
      this.service.updateOnlineData(payload).subscribe(
        (res) => {
          this.getOnlineSetup();
          this.onlineSource();
          this.service.openSnackBar("Data Updated!");
          this.onlineSetUp.reset();
          this.onlinID = "";
          this.disableInput = false;
        },
        (error) => {
          this.onlinID = "";
          this.service.openSnackBar(error.error.message);
        }
      );
    }else{
    
      this.service.updateOnlineData(this.onlineSources.value).subscribe(
        (res) => {
          this.getOnlineSetup();
          this.onlineSource();
          this.service.openSnackBar("Data Updated!");
          this.onlineSetUp.reset();
          this.onlinID = "";
          this.disableInput = false;
        },
        (error) => {
          this.onlinID = "";
          this.service.openSnackBar(error.error.message);
        }
      );
    }
   
  }
  getSavedOnlineDetails() {
    this.onlineSource();
    this.showType = true;
    this.getOnlineSetup();
  }
  onlineSource() {
    this.service
      .getOnlineDeliverySource(`?filterbybrand=1&brand_id=${this.brandGet}`)
      .subscribe((res) => {
        this.onlinetype = res.body;
      });
  }
  getOnlineSetup() {
    this.service
      .getOnlineDeliveryData(`?filterbybrand=1&brand_id=${this.brandGet}`)
      .subscribe((res) => {
        this.populateData = res.body;
      });
  }
  edit(data) {
    this.onlinID = data.onlinetype_id;
    this.onlineSources.patchValue({
      brand_id: data.branch_id,
      branch_id: data.branch_id,
      online_type_english: data.online_type_english,
      online_type_arabic: data.online_type_arabic,
      onlinetype_id: data.onlinetype_id,
    });
    this.disableInput = true;
  }
  isFieldValid(field: string) {
    return (
      (this.onlineSetUp.get(field).invalid &&
        this.onlineSetUp.get(field).touched) ||
      (this.onlineSetUp.get(field).untouched && this.formAttemptSubmit)
    );
  }
}
