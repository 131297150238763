import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { PosBackOfficeService } from '../../app/providers/pos-back-office.service';
import { LocalStorageService } from 'ngx-store';

@Component({
  selector: 'app-modifier-group',
  templateUrl: './modifier-group.component.html',
  styleUrls: ['./modifier-group.component.scss']
})
export class ModifierGroupComponent implements OnInit {
  modifierGroup: FormGroup;
  modifierGroupModal: FormGroup;
  formSubmitAttempt: boolean;
  brand: any;
  rule: any;
  payload: any;
  query: any;
  p = 1;
  index: any;
  i: number;
  modifierGroupID: '';
  showItem: boolean;
  showItemDetails: boolean;
  textAlign: string;
  modifierGroupData: any[];
  modifierCatalogData: any;
  brandName: any;
  showAvailable = true;
  modifierGroupName: any;
  localIndex: any;
  groupNameModifier: any;
  LocalItems = [];
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) {}

  ngOnInit() {
    this.modifierGroup = this.fb.group({
      modifier_group_name: ['', Validators.required],
      modifier_group_name_arabic: ['', Validators.required],
      brand_id: ['', Validators.required]
    });
    this.modifierGroupModal = this.fb.group({
      modifier_group_name: ['', Validators.required],
      modifier_group_name_arabic: ['', Validators.required],
      brand_id: ['', Validators.required],
      modifier_group_id: ''
    });
    this.getBrandDetails();
    this.inputAlignment();
    this.getModifierItems('get');
  }

  getModifierItems(rule) {
    this.service.modifierCatalog(rule).subscribe(res => {
      this.modifierCatalogData = res.body;
    });
  }

  inputAlignment() {
    const lang = this.ls.get('language');
    if (lang === 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  getBrandDetails() {
    this.service.getBrandDetails().subscribe(result => {
      this.brand = result.body;
    });
  }

  addModifierItems(data, index) {
    this.brandName = data.brand.brand_name;
    this.modifierGroupName = data.modifier_group_name;
    this.unselectAll();
    this.localIndex = index;
    this.showItem = true;
  }

  viewModifierItems(data, index) {
    this.modifierGroup1('get', this.modifierGroup.value.brand_id, '?verbose=true&brand_id=');
    this.addModifierItems(data, index);
    this.localIndex = index;
    this.unselectAll();
    this.groupNameModifier = data.modifier_group_name;
    this.showItemDetails = true;
  }

  unselectAll() {
    this.LocalItems = [];
    this.modifierCatalogData.map((data: any) => {
      data.checked = false;
    });
  }

  closeViewModifier() {
    this.showItemDetails = false;
  }

  deleteItem(i) {
    this.modifierGroupData[this.localIndex].modifiers.splice(i, 1);
    this.modifierGroup1('put', this.modifierGroupData[this.localIndex]);
    this.service.openSnackBar('Item Deleted');
  }

  deleteModifierGroup(rule, payload, query, index) {
    this.rule = rule;
    this.payload = payload;
    this.query = query;
    this.index = index;
    this.popUp();
  }

  editModifierGroup(data) {
    this.modifierGroupID = data.modifier_group_id;
    this.modifierGroupModal.patchValue(data);
  }

  deleteModifierGroup1() {
    this.modifierGroup1(this.rule, this.payload, this.query, this.index);
  }

  checkBoxData(status, item) {
    if (status) {
      const result = this.modifierGroupData[this.localIndex].modifiers.find(
        data => data.modifier_id === item.modifier_id
      );

      if (!result) {
        return this.LocalItems.unshift(item);
      }

      return this.service.openSnackBar('Duplicate item');
    }

    for (let i = 0; i < this.LocalItems.length; i++) {
      if (this.LocalItems[i].modifier_id === item.modifier_id) {
        this.LocalItems.splice(i, 1);
        break;
      }
    }
  }

  async addItems() {
    if (this.LocalItems.length <= 0) {
      return;
    }
    this.modifierGroupData[this.localIndex].modifiers.unshift(...this.LocalItems);
    await this.unselectAll();
    this.modifierGroup1('put', this.modifierGroupData[this.localIndex]);
    this.modifierGroup1('get', this.modifierGroup.value.brand_id, '?verbose=true&brand_id=');
    this.service.openSnackBar('Items Added');
  }

  modifierGroup1(rule, payload = '', query = '', index = 1) {
    this.service.modifierGroup(rule, payload, query).subscribe(res => {
      if (rule === 'get') {
        this.modifierGroupData = res.body;
        if (this.modifierGroupData.length > 0) {
          this.showAvailable = false;
        } else {
          this.showAvailable = true;
        }
      }
      if (rule === 'post') {
        this.modifierGroup1('get', this.modifierGroup.value.brand_id, '?verbose=true&brand_id=');
        this.modifierGroup.reset();
        this.service.openSnackBar('Modifier Group Added');
      }
      if (rule === 'delete') {
        this.modifierGroupData.splice(index, 1);
        this.service.openSnackBar('Modifier Group Deleted');
        this.popUpOff();
      }
      if (rule === 'put') {
        this.modifierGroup1(
          'get',
          this.modifierGroupModal.value.brand_id,
          '?verbose=true&brand_id='
        );
        this.modifierGroupID = '';
        this.service.openSnackBar('Modifier Group Updated');
        // callback();
      }
    });
  }

  clear() {
    this.modifierGroup.reset();
    this.modifierGroupData = [];
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  isFieldValid(field: string) {
    return (
      (!this.modifierGroup.get(field).valid && this.modifierGroup.get(field).touched) ||
      (this.modifierGroup.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
