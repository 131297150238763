import { Component, OnInit } from '@angular/core';
import { PosBackOfficeService } from '../../app/providers/pos-back-office.service';
import { FormBuilder,  FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'ngx-store';

@Component({
  selector: 'app-menu-group',
  templateUrl: './menu-group.component.html',
  styleUrls: ['./menu-group.component.scss']
})
export class MenuGroupComponent implements OnInit {
  showItemDetails: boolean;
  groupName: any;
  brandName: any;
  i: number;
  p = 1;
  menuItemGroup1: any;
  group: any;
  textAlign: string;
  textRight: string;
  showAvailable = true;
  itemDataBackup: any;
  showAdd = true;
  showPagination: boolean;
  menuGroupData: any;
  menugrp: any;
  filteredData: any[];
  updateMenu: any;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) { }
  menuItemGroup: FormGroup;
  menuGroupModal: FormGroup;
  showItem: boolean;
  rule: any;
  payload: any;
  query: any;
  index: any;
  formSubmitAttempt: boolean;
  selectedMenu: any = [];
  menuItemGroupInfo: any = [];
  AddItemsMenu = [];
  LocalItems = [];
  itemData: any = [];
  groupID: '';
  buttonClick = false;
  showAddGroup = false;
  menuName = false;
  orgData:any;
  orgGet:any=[];
  brandGet:any=[];
  branchGet:any=[];
  brand:any;
  branch:any;

  localIndex;
  async ngOnInit() {
    this.menuItemGroup = this.fb.group({
      group_name: this.fb.array[''],
      group_name_arabic: this.fb.array[''],
      brand_id: ['', Validators.required],
      branch_id: ['', Validators.required]
    });
    this.menuGroupModal = this.fb.group({
      brand_id: [''],
      branch_id: [''],
      group_name: ['', Validators.required],
      group_name_arabic: ['', Validators.required],
      group_id: ['']
    });
    this.getOrgDetails();
    this.inputAlignment();
  }
  inputAlignment() {
    this.textRight = 'textRight';
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service
      .getOrgDetails(`?userId=${this.ls.get("userId")}`)
      .subscribe((result) => {
        this.orgData = result.body;
        for (const data of this.orgData) {
          this.orgGet.push(data.Organization_id);
        }
        this.brandData();
      });
  }
  brandData() {
    this.brandGet = [];
    this.service
      .getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`)
      .subscribe((result) => {
        this.brand = result.body;
        for (const datas of this.brand) {
          this.brandGet.push(datas.brand_id);
        }
        this.MenuItemGroupCRUD('get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`);
        this.activateAdd();
      });
  }

  getBranch() {
    this.branchGet = [];
    this.service
      .getBranchDetail(
        `?filterbybrand=1&brand_id=${this.menuItemGroup.value.brand_id}`
      )
      .subscribe(
        (result) => {
          this.branch = result.body;
          for (let i = 0; i < this.branch.length; i++) {
            this.branchGet.push(this.branch[i].branch_id)
          }
        },
        (error) => {
          this.service.openSnackBar(error.error.message)
        }
      );

  }
  item(rule, payload, query) {
    this.service.Item(rule, payload, query).subscribe(
      result => {
        this.itemData = result.body;
        this.itemDataBackup = result.body;
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  viewItemDetails(item, index) {
    this.brandName = item.brand.brand_name;
    this.menuItemGroup1 = item.group_name;
    this.groupName = item.group_name;
    this.localIndex = index;
    this.unselectAll();
    this.showItem = true;
    this.showItemDetails = true;
  }

  viewItemDetails1(index, item) {
    this.MenuItemGroupCRUD('get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`);
    this.item('get', this.brandGet, `?brand_id=${item.brand_id}&branch_id=${item.branch_id}`);
    this.viewItemDetails(item, index);
    this.groupName = item.group_name;
    this.localIndex = index;
    this.unselectAll();
    this.showItemDetails = true;
  }

  activateAdd() {
    this.buttonClick = true;
    this.showAdd = true;
    this.showAddGroup = false;
    this.showItem = false;
    this.showItemDetails = false;
  }

  clear() {
    this.menuItemGroup.patchValue({ group_name: null, group_name_arabic: null });
  }

  createMenuGroup() {
    this.showAddGroup = true;
    this.showAdd = false;
  }

  checkBoxData(event, item) {
    // Adds items if checked
    if (event.checked) {
      // Returns undefined on not found ;
      const result = this.menuItemGroupInfo[this.localIndex].items.find(
        data => data.item_id === item.item_id
      );

      if (!result) {
        return this.LocalItems.unshift(item);
      }
      event.source._checked = false;
      return this.service.openSnackBar('Item already added');
    }

    // Removes items if unchecked
    for (let i = 0; i < this.LocalItems.length; i++) {
      if (this.LocalItems[i].item_id === item.item_id) {
        this.LocalItems.splice(i, 1);
        break;
      }
    }
  }

    addItems() {
      this.showItemDetails=true

    if (this.LocalItems.length <= 0) {
      return;
    }
    this.menuItemGroupInfo[this.localIndex].items.unshift(...this.LocalItems);
    console.log('this.LocalItems', this.LocalItems)
    this.unselectAll();
    this.MenuItemGroupCRUD('put', this.menuItemGroupInfo[this.localIndex]);
    this.service.openSnackBar('Items Added');
    // this.menu()
  }

  updateGroup(i) {
    if (
      this.menuGroupModal.value.group_name === '' ||
      this.menuGroupModal.value.group_name === null ||
      this.menuGroupModal.value.group_name === undefined
    ) {
      this.service.openSnackBar('Please enter menu item group');
    } else {
      if (
        this.menuGroupModal.value.group_name_arabic === '' ||
        this.menuGroupModal.value.group_name_arabic === null ||
        this.menuGroupModal.value.group_name_arabic === undefined
      ) {
        this.service.openSnackBar('Please enter arabic menu item group');
      } else {
        if (
          this.menuGroupData.group_name === this.menuGroupModal.value.group_name &&
          this.menuGroupData.group_name_arabic === this.menuGroupModal.value.group_name_arabic
        ) {
          console.log('true');
          this.MenuItemGroupCRUD('put', this.menuGroupModal.value, '', i, () => {
            this.MenuItemGroupCRUD('get', this.menuItemGroup.value.brand_id, '?brand_id=');
          });
        } else {
          const arrayData = [];
          this.menuItemGroupInfo.map(res => {
            if (res.group_id !== this.menuGroupData.group_id) {
              arrayData.push(res);
            }
          });

          if (
            !arrayData.some(
              res =>
                res.group_name.toUpperCase().replace(/\s/g, '') ===
                this.menuGroupModal.value.group_name.toUpperCase().replace(/\s/g, '')
            )
          ) {
            if (
              !arrayData.some(
                res =>
                  res.group_name_arabic.toUpperCase().replace(/\s/g, '') ===
                  this.menuGroupModal.value.group_name_arabic.toUpperCase().replace(/\s/g, '')
              )
            ) {
              this.MenuItemGroupCRUD('put', this.menuGroupModal.value, '', i, () => {
                this.MenuItemGroupCRUD('get', this.menuItemGroup.value.brand_id, '?brand_id=');
              });
            } else {
              this.service.openSnackBar('Menu item group arabic already exists!');
            }
          } else {
            this.service.openSnackBar('Menu item group already exists!');
          }
        }
      }
    }
  }

  closeItemDetails() {
    this.showItemDetails = false;
  }

  deleteItem(i) {
    this.menuItemGroupInfo[this.localIndex].items.splice(i, 1);
    this.MenuItemGroupCRUD('put', this.menuItemGroupInfo[this.localIndex]);
    this.service.openSnackBar('Item Deleted');
  }

  deleteMenuGroup(rule, data, query, index) {
    this.rule = rule;
    this.payload = data;
    this.query = `?group_id=${data.group_id}&brand_id=${data.brand_id}&branch_id=${data.branch_id}`;
    this.index = index;
    this.popUp();
    this.showItemDetails = false;
  }

  deleteMenuGroup1() {
    this.MenuItemGroupCRUD(this.rule, this.payload, this.query, this.index);
  }

  unselectAll() {
    this.LocalItems = [];
    this.itemData.map((data: any) => {
      data.checked = false;
    });
  }

  menuItemGroupPost(rule, payload) {
    if (
      this.menuItemGroup.value.group_name === '' ||
      this.menuItemGroup.value.group_name === null ||
      this.menuItemGroup.value.group_name === undefined
    ) {
      this.service.openSnackBar('Please enter menu item group');
    } else {
      if (
        this.menuItemGroup.value.group_name_arabic === '' ||
        this.menuItemGroup.value.group_name_arabic === null ||
        this.menuItemGroup.value.group_name_arabic === undefined
      ) {
        this.service.openSnackBar('Please enter arabic menu item group');
      } else {
        if (
          !this.menuItemGroupInfo.some(
            res =>
              res.group_name.toUpperCase().replace(/\s/g, '') ===
              this.menuItemGroup.value.group_name.toUpperCase().replace(/\s/g, '')
          )
        ) {
          if (
            !this.menuItemGroupInfo.some(
              res =>
                res.group_name_arabic.toUpperCase().replace(/\s/g, '') ===
                this.menuItemGroup.value.group_name_arabic.toUpperCase().replace(/\s/g, '')
            )
          ) {
            this.MenuItemGroupCRUD(rule, payload);
          } else {
            this.service.openSnackBar('Menu item group arabic already exists!');
          }
        } else {
          this.service.openSnackBar('Menu item group already exists!');
        }
      }
    }
  }

  MenuItemGroupCRUD(rule, payload = '', query = '', index = 1, callback?) {
    this.service.MenuItemGroup(rule, payload, query).subscribe(
      result => {
        if (rule === 'get') {
          this.menuItemGroupInfo = result.body;
          if (this.menuItemGroupInfo.length > 0) {
            if (this.menuItemGroupInfo.length > 14) {
              this.showPagination = true;
            } else {
              this.showPagination = false;
            }
            this.showAvailable = false;
          } else {
            this.showAvailable = true;
            this.showPagination = false;
          }
        }
        if (rule === 'delete') {
          this.menuItemGroupInfo.splice(index, 1);
          this.MenuItemGroupCRUD('get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`);
          this.popUpOff();
          this.service.openSnackBar('Menu Item Group Deleted');
        }
        if (rule === 'post') {
          this.MenuItemGroupCRUD('get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`);
          this.menuItemGroup.patchValue({ group_name: '', group_name_arabic: '' });
          this.service.openSnackBar('Menu Item Group Added');
        }
        if (rule === 'put') {
          this.groupID = '';
          this.MenuItemGroupCRUD('get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`);
        }
      },
      error => {
        this.groupID = '';
        this.service.openSnackBar(error.error.message);
      }
    );

  }
  grp(i) {
    console.log(i)
  }
  getItem() {
    this.service.Item('get').subscribe(
      result => {
        this.itemData = result.body;
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  edit(data, index) {
    this.menuGroupData = data;
    this.groupID = data.group_id;
    this.menuGroupModal.patchValue(data);
  }

  isFieldValid(field: string) {
    return (
      (!this.menuItemGroup.get(field).valid && this.menuItemGroup.get(field).touched) ||
      (this.menuItemGroup.get(field).untouched && this.formSubmitAttempt)
    );
  }

  applyFilter(filter: string) {
    this.itemData = this.itemDataBackup.filter(item => {
      if (
        item.item_name
          .toString()
          .toLowerCase()
          .indexOf(filter.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
  }
}
