import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PosBackOfficeService } from './pos-back-office.service';
import { LocalStorageService } from 'ngx-store';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {
  constructor(public auth: PosBackOfficeService,public ls: LocalStorageService, ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.ls.get('token');
    const userId = this.ls.get('im')
    request = request.clone({
      setHeaders: {
        Authorization: token || '',
        userid: userId || ''
      }
    });
    return next.handle(request);
  }
}
