import { PosBackOfficeService } from './../providers/pos-back-office.service';
import { Router } from '@angular/router';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
import { FormBuilder, FormGroup, Validators, AbstractControlDirective } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-left-navbar',
  templateUrl: './left-navbar.component.html',
  styleUrls: ['./left-navbar.component.scss']
})
export class LeftNavbarComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  color: any;
  changePswd: FormGroup;
  show: boolean;
  newpswd: boolean;
  confirmpswd: boolean;

  constructor(
    public service: PosBackOfficeService,
    public ls: LocalStorageService,
    public router: Router,
    private translate: TranslateService,
    public ck: CookiesStorageService,
    private breakpointObserver: BreakpointObserver,
    public fb : FormBuilder
  ) { translate.setDefaultLang('en');}
  useLanguage(language: string) {
    this.translate.use(language);
  }
  showLeftNavBar = true;
  username = '';
  localData: any = null;
  brandSetupleft: any;
  branchSetupleft: any;
  addUserLeft: any;
  itemCatalogLeft: any;
  menuItemGroupLeft: any;
  menuCategoryLeft: any;
  menuMasterLeft: any;
  discountLeft: any;
  printerLeft: any;
  paymentTypeLeft: any;
  english = true;
  lnSales: any;
  boaccess

  ngOnInit() {
    // this.checkLanguage();
    this.service.user.subscribe((r: any) => {
      this.username = r.name;
    });

    this.localData = this.ls.get('bouser');

    this.service.updateToken();

    if (this.localData) {
      this.username = this.localData.name;
    }
    this.service.colorAccent.subscribe(res =>{
      this.color = res;
      this.ls.set('color', this.color);
    })
    this.changePswd = this.fb.group({
      currentPaswd: ['', Validators.required],
      newPaswd: ['', Validators.required],
      confirmPaswd: ['', Validators.required]
    })

    // shows content based on  user roles 
  //  const bouser = this.ls.get("bouser")
  //   console.log("",name)
  //   this.boaccess=bouser.boaccess[0]
  //   console.log("bbbbbbbbb",this.boaccess)

  }
  languageChange(event) {
    if (event.checked) {
      this.translate.use('de');
    } else {
      this.translate.use('en');
    }
  }
  checkLanguage() {
    let data: any = document.cookie;
    data = data.split(';');
    const split = [];
    data.map((r: any) => {
      split.push(...r.split('='));
    });
    const a = split.indexOf('googtrans');
    if (a !== -1) {
      split[a + 1] === '/en/ar' ? (this.english = false) : (this.english = true);
    }
  }
  addUser() {
    this.brandSetupleft = '';
    this.branchSetupleft = '';
    this.addUserLeft = 'activeClass';
    this.itemCatalogLeft = '';
    this.menuItemGroupLeft = '';
    this.menuCategoryLeft = '';
    this.menuMasterLeft = '';
    this.printerLeft = '';
    this.discountLeft = '';
    this.paymentTypeLeft = '';
    this.lnSales = '';
  }
  brandSetup() {
    this.brandSetupleft = 'activeClass';
    this.branchSetupleft = '';
    this.addUserLeft = '';
    this.itemCatalogLeft = '';
    this.menuItemGroupLeft = '';
    this.menuCategoryLeft = '';
    this.menuMasterLeft = '';
    this.printerLeft = '';
    this.discountLeft = '';
    this.paymentTypeLeft = '';
    this.lnSales = '';
  }

  branchSetup() {
    this.brandSetupleft = '';
    this.branchSetupleft = 'activeClass';
    this.addUserLeft = '';
    this.itemCatalogLeft = '';
    this.menuItemGroupLeft = '';
    this.menuCategoryLeft = '';
    this.menuMasterLeft = '';
    this.discountLeft = '';
    this.printerLeft = '';
    this.paymentTypeLeft = '';
    this.lnSales = '';
  }

  itemCatalog() {
    this.brandSetupleft = '';
    this.branchSetupleft = '';
    this.addUserLeft = '';
    this.itemCatalogLeft = 'activeClass';
    this.menuItemGroupLeft = '';
    this.menuCategoryLeft = '';
    this.menuMasterLeft = '';
    this.printerLeft = '';
    this.discountLeft = '';
    this.paymentTypeLeft = '';
    this.lnSales = '';
  }

  menuItemGroup() {
    this.brandSetupleft = '';
    this.branchSetupleft = '';
    this.addUserLeft = '';
    this.itemCatalogLeft = '';
    this.menuItemGroupLeft = 'activeClass';
    this.menuCategoryLeft = '';
    this.menuMasterLeft = '';
    this.printerLeft = '';
    this.discountLeft = '';
    this.paymentTypeLeft = '';
    this.lnSales = '';

  }

  menuCategory() {
    this.brandSetupleft = '';
    this.branchSetupleft = '';
    this.addUserLeft = '';
    this.itemCatalogLeft = '';
    this.menuItemGroupLeft = '';
    this.menuCategoryLeft = 'activeClass';
    this.menuMasterLeft = '';
    this.printerLeft = '';
    this.discountLeft = '';
    this.paymentTypeLeft = '';
    this.lnSales = '';

  }
  menuMaster() {
    this.brandSetupleft = '';
    this.branchSetupleft = '';
    this.addUserLeft = '';
    this.itemCatalogLeft = '';
    this.menuItemGroupLeft = '';
    this.menuCategoryLeft = '';
    this.printerLeft = '';
    this.menuMasterLeft = 'activeClass';
    this.discountLeft = '';
    this.paymentTypeLeft = '';
    this.lnSales = '';

  }

  printer() {
    this.brandSetupleft = '';
    this.branchSetupleft = '';
    this.addUserLeft = '';
    this.itemCatalogLeft = '';
    this.menuItemGroupLeft = '';
    this.menuCategoryLeft = '';
    this.printerLeft = 'activeClass';
    this.discountLeft = '';
    this.menuMasterLeft = '';
    this.paymentTypeLeft = '';
    this.lnSales = '';

  }

  discount() {
    this.brandSetupleft = '';
    this.branchSetupleft = '';
    this.addUserLeft = '';
    this.itemCatalogLeft = '';
    this.menuItemGroupLeft = '';
    this.menuCategoryLeft = '';
    this.printerLeft = '';
    this.menuMasterLeft = '';
    this.discountLeft = 'activeClass';
    this.paymentTypeLeft = '';
    this.lnSales = '';

  }


  paymentType() {
    this.brandSetupleft = '';
    this.branchSetupleft = '';
    this.addUserLeft = '';
    this.itemCatalogLeft = '';
    this.menuItemGroupLeft = '';
    this.menuCategoryLeft = '';
    this.printerLeft = '';
    this.menuMasterLeft = '';
    this.discountLeft = '';
    this.paymentTypeLeft = 'activeClass';
    this.lnSales = '';

  }

  logout() {
    this.ls.clear();
    this.router.navigate(['cloud/login']);
  }

  sales() {
   this.brandSetupleft = '';
    this.branchSetupleft = '';
    this.addUserLeft = '';
    this.itemCatalogLeft = '';
    this.menuItemGroupLeft = '';
    this.menuCategoryLeft = '';
    this.printerLeft = '';
    this.menuMasterLeft = '';
    this.discountLeft = '';
    this.paymentTypeLeft = '';
    this.lnSales = 'activeClass';
  }
  popUpLogout() {
    document.getElementById('id03').style.display = 'block';
  }

  popUpLogoutOff() {
    document.getElementById('id03').style.display = 'none';
  }
  colorChange(value) {
    // this. ls.get('color') = value;
    this.service.accent(value);
  }
  palletShow() {
    document.getElementById('colorPallet').style.display = 'block';
  }

  palletOff() {
    document.getElementById('colorPallet').style.display = 'none';
  }
  chooseAccent(value) {
    console.log('value', value);
    this.palletOff();
    switch (value) {
      case 1:
        this.service.accent('black');
        return this.ls.set('color', "black");
      case 2:
        this.service.accent('#800040');
        return this.ls.set('color', "#800040");
      case 3:
        this.service.accent('#004000');
        return this.ls.set('color', "#004000");
      case 4:
        this.service.accent('#dd6f00');
        return this.ls.set('color', "#dd6f00");
      case 5:
        this.service.accent('#000040');
        return this.ls.set('color', "#000040");
      case 6:
        this.service.accent('#400040');
        return this.ls.set('color', "#400040");
      case 7:
        this.service.accent('#808000');
        return this.ls.set('color', "#808000");
      case 8:
        this.service.accent('#005555');
        return this.ls.set('color', "#005555");
      case 9:
        this.service.accent('#442b11');
        return this.ls.set('color', "#442b11");
      case 10:
        this.service.accent('#ae00ae');
        return this.ls.set('color', "#ae00ae");
      case 11:
        this.service.accent('#218d39');
        return this.ls.set('color', "#218d39");
      case 12:
        this.service.accent('#808080');
        return this.ls.set('color', "#808080");
    }
  }

  changePassword() {
    document.getElementById('changePassword').style.display = 'block';
  }
  pswdCancel() {
    document.getElementById('changePassword').style.display = 'none';
  }
  current(event) {
    this.show = !this.show;
  }
  newPswd(event) {
    this.newpswd = !this.newpswd;
  }
  confirmPswd(event) {
    this.confirmpswd = !this.confirmpswd;
  }
  update() {
    const id = this.ls.get('user');
    const payload = {
      _id: id.id,
      newPassword: this.changePswd.value.newPaswd,
      oldPassword: this.changePswd.value.currentPaswd
    }
    console.log(payload)
    if(this.changePswd.valid){
      this.service.updateChangePassword(payload).subscribe(res => {
        console.log("res for update res", res)
        if (res) {
          document.getElementById('changePassword').style.display = 'none';
          this.service.openSnackBar(res.msg)
        }
      },err=>{
        console.log(err)
        this.service.openSnackBar(err.error.message)
      })
    }else{
      this.service.openSnackBar("Please Fill All the Field")
    } 
  }

}
