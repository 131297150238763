import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PosBackOfficeService } from "../providers/pos-back-office.service";
import { FormBuilder, Validators } from "@angular/forms";
import { LocalStorageService } from "ngx-store";

@Component({
  selector: "app-printer",
  templateUrl: "./printer.component.html",
  styleUrls: ["./printer.component.scss"],
})
export class PrinterComponent implements OnInit {
  printCategory: FormGroup;
  printerEdit: FormGroup;
  formSubmitAttempt: boolean;
  printerDetails: any;
  brand: any;
  branch: any;
  printId: "";
  printerData: any;
  payload: any;
  p = 1;
  showAvailable = true;
  editPrintData: any;
  orgData: any;
  orgGet: any = [];
  brandGet: any = [];
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.printCategory = this.fb.group({
      brand_id: ["", Validators.required],
      branch_id: ["", Validators.required],
      printer_name: ["", Validators.required],
      printer_ip: ["", Validators.required],
      printer_description: ["", Validators.required],
      printer_status: [true],
    });
    this.printerEdit = this.fb.group({
      printer_name: ["", Validators.required],
      printer_ip: ["", Validators.required],
      printer_description: ["", Validators.required],
      printer_status: "",
      printer_id: "",
    });
    this.getOrgDetails();
  }
  patchman1() {
    const one = this.printCategory.get("printer_name").value;
    this.printCategory.patchValue({ printer_name: one.replace(/^ +/gm, "") });
    const two = this.printCategory.get("printer_ip").value;
    this.printCategory.patchValue({ printer_ip: two.replace(/^ +/gm, "") });
    const three = this.printCategory.get("printer_ip").value;
    this.printCategory.patchValue({ printer_ip: three.replace(/^ +/gm, "") });
    const four = this.printCategory.get("printer_description").value;
    this.printCategory.patchValue({
      printer_description: four.replace(/^ +/gm, ""),
    });
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service
      .getOrgDetails(`?userId=${this.ls.get("userId")}`)
      .subscribe((result) => {
        this.orgData = result.body;
        for (let i = 0; i < this.orgData.length; i++) {
          this.orgGet.push(this.orgData[i].Organization_id);
        }
        this.brandData();
      });
  }

  brandData() {
    this.brandGet = [];
    this.service
      .getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`)
      .subscribe((result) => {
        this.brand = result.body;
        for (let i = 0; i < this.brand.length; i++) {
          this.brandGet.push(this.brand[i].brand_id);
        }
        this.printDetails("get");
      });
  }
  getBranch(brand) {
    this.service
      .getBranchDetail(`?filterbybrand=1&brand_id=${brand}`)
      .subscribe(
        (result) => {
          this.branch = result.body;
        },
        (error) => {
          this.service.openSnackBar(error.error.message);
        }
      );
  }
  isFieldValid(field: string) {
    return (
      (!this.printCategory.get(field).valid &&
        this.printCategory.get(field).touched) ||
      (this.printCategory.get(field).untouched && this.formSubmitAttempt)
    );
  }

  popUp() {
    document.getElementById("id01").style.display = "block";
  }

  popUpOff() {
    document.getElementById("id01").style.display = "none";
  }

  deletePrinter(data) {
    this.popUp();
    this.printerData = data;
  }

  delete() {
    this.service
      .deletePrint(`?printer_id=${this.printerData.printer_id}`)
      .subscribe(
        (result) => {
          if (result.status) {
            this.printDetails("get");
            this.service.openSnackBar("Printer Deleted!");
            this.popUpOff();
          }
        },
        (error) => {
          this.service.openSnackBar(error.error.message);
          this.popUpOff();
        }
      );
  }

  editPrinter(data) {
    this.printId = data.printer_id;
    this.editPrintData = data;
    this.printerEdit.patchValue(data);
  }

  updatePrinter(datas, data) {
    const data1 = datas;
    const arrayData = [];
    if (data1.printer_name == data.printer_name) {
      this.payload = {
        brand_id: data.brand_id,
        branch_id: data.branch_id,
        printer_ip: data1.printer_ip,
        printer_description: data1.printer_description,
        printer_status: data1.printer_status,
        printer_id: data1.printer_id,
      };
    } else {
      this.payload = {
        brand_id: data.brand_id,
        branch_id: data.branch_id,
        printer_name: data1.printer_name,
        printer_ip: data1.printer_ip,
        printer_description: data1.printer_description,
        printer_status: data1.printer_status,
        printer_id: data1.printer_id,
      };
    }
    console.log("data1", data1);
    console.log("editPrintData", this.editPrintData);
    if (
      data1.printer_name.toUpperCase().replace(/\s/g, "") ===
      this.editPrintData.printer_name.toUpperCase().replace(/\s/g, "") &&
      data1.printer_ip.toUpperCase().replace(/\s/g, "") ===
      this.editPrintData.printer_ip.toUpperCase().replace(/\s/g, "")
    ) {
      this.printDetails("put", this.payload, "");
      console.log("1");
    } else {
      this.printerDetails.forEach((element) => {
        if (element.printer_id !== data.printer_id) {
          arrayData.push(element);
        }
      });
      console.log(arrayData);
      this.printDetails("put", this.payload, "");
    }
  }

  printDetails1(rule, payload) {
    console.log("payload", payload);
    if (this.printerDetails.length > 0) {
      this.printDetails(rule, payload);
    } else {
      this.printDetails(rule, payload);
      console.log("2");
    }
  }

  printDetails(
    rule,
    payload = "",
    query = `?filterByBrand=1&brand_id=${this.brandGet}`
  ) {
    this.service.printers(rule, payload, query).subscribe(
      (result) => {
        if (rule === "get") {
          this.printerDetails = result.body;
          if (this.printerDetails.length > 0) {
            this.showAvailable = false;
          } else {
            this.showAvailable = true;
          }
        }

        if (rule === "post") {
          this.service.openSnackBar("Printer Added!");
          this.printDetails("get");
          this.printCategory.reset();
          this.printCategory.patchValue({ printer_status: true });
        }
        if (rule === "put") {
          this.service.openSnackBar("Printer Updated!");
          this.printDetails("get");
          this.printId = "";
        }
      },
      (error) => {
        this.service.openSnackBar(
          error.error.message || "Internal Server Error"
        );
      }
    );
  }
}
