import { Component, OnInit } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
import { Observable, iif, empty } from "rxjs";
@Component({
  selector: 'app-semifinish',
  templateUrl: './semifinish.component.html',
  styleUrls: ['./semifinish.component.scss']
})
export class SemifinishComponent implements OnInit {

semiform:FormGroup;
semiArrayForm:FormArray;
semiArray:any
i:any;
maxDate

  constructor(private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService) { }

  ngOnInit() {
    this.semiform = this.fb.group({
      branchData:[""],
      branchTo:[""],
      fromDate:[""],
      toDate:[""],
      semiArrayForm: this.fb.array([this.ItemControl()]),
    })
  }

  ItemControl() {
    return this.fb.group({
      item_code: ["", [Validators.required]],
      item_name_english: ["", [Validators.required]],
      Unit_of_measurment:["",[Validators.required]],
      avaliableQty: ["", [Validators.required]],
      transferQty: ["", [Validators.required]],
      // item_id: "",
    });
  }
   getItem() {
    this.semiArray = this.semiform.get("semiArrayForm") as FormArray;
    if (this.semiArray.valid) {
      this.semiArray.push(this.ItemControl());
    } else {
      // this.service.openSnackbar("Please enter all the fields are Invalid");
    }
  }
   removeItem(index) {
    const form = this.semiform.get("semiArrayForm") as FormArray;
    this.semiArray = this.semiform.get("semiArrayForm") as FormArray;
    console.log("this.semiArray", this.semiArray);
    this.semiArray.controls[index].patchValue({
      item_code: "",
      item_name_english: "",
      Unit_of_measurment:"",
      avaliableQty: "",
      transferQty: "",
      stock_id: ""
    });
    if (form.length === 1) {
    } else {
      form.removeAt(index);
    }
  }

}
