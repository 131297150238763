import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { LocalStorageService } from 'ngx-store';
import { PosBackOfficeService } from '../providers/pos-back-office.service';

@Component({
  selector: 'app-daily-sales-report',
  templateUrl: './daily-sales-report.component.html',
  styleUrls: ['./daily-sales-report.component.scss']
})
export class DailySalesReportComponent implements OnInit {
  @ViewChild('myCanvas')
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;
  public chartType = 'line';
  public chartData: any = [];
  public chartLabels: any = ['19-08', '20-08', '21-08', '22-08', '23-08'];
  public chartColors: any = [];
  public finalChartData: any = [];
  total;
  showPrint = false;
  brands: any;
  branches: any;
  maxToDate = new Date();
  maxFromDate = new Date();
  minToDate: any;
  dailyReport: FormGroup;
  dailyReports: any;
  dailyReports02: any;
  transaction_Date: any;
  brandID:any;
  branchID:any;
  user:any;
  totalSales;
  totalDiscount;
  totalDelivery;
  totalNetAmount;
  maxDate = new Date();
  totalCardAmount;
  totalextraCash;
  totalshortCash;
  totalcashierdue;
  totalCash;
  totalSum;
  showReport = false;
  card_types = [];
  enableBranch;
  p = 1;
  defaultQuery;
  fromdate;
  todate;
  showReport1: boolean;
  orgData:any;
  orgGet:any=[];
  brandGet:any=[];
  queryParams = {
    Brand: 0,
    Branch: 0,
    cardType: 1,
  };
  printArray: any = [];
  localStorage: any;
  todayDate;

  constructor(
    public service: PosBackOfficeService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private adapter: DateAdapter<any>,
    public ls: LocalStorageService
  ) {}

  ngOnInit() {
    this.dailyReport = this.fb.group({
      brand: '',
      branch: '',
      fromDate: [new Date().toISOString().slice(0, 10)],
      toDate: [new Date().toISOString().slice(0, 10)],

    });
    this.getOrgDetails();
    this.dateAdapter();
    this.chartData = [{ data: [65, 59, 80, 81, 56, 55, 40], label: 'Sales', fill: false }];

    this.chartColors = [
      {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderColor: ' gray'
      }
    ];
    this.dateValidation();
  }

  dateAdapter() {
    this.adapter.setLocale('en-GB');
  }
  dateValidation() {
    this.minToDate = this.dailyReport.value.fromDate;
    this.maxFromDate = this.dailyReport.value.toDate;
   
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
      this.orgData = result.body
      for (let i = 0; i < this.orgData.length; i++) {
        this.orgGet.push(this.orgData[i].Organization_id)
      }
      this.getBrand();
    });
  }
  getBrand() {
    this.brandGet = [];
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(result => {
      this.brands = result.body;
      for (let i = 0; i < this.brands.length; i++) {
        this.brandGet.push(this.brands[i].brand_id)
      }
    });
  }
  getBranch(brand) {
    this.fromdate = this.datePipe.transform(this.dailyReport.value.fromDate, 'yyyy-MM-dd');
    this.todate = this.datePipe.transform(this.dailyReport.value.toDate, 'yyyy-MM-dd');

    this.dailyReport.patchValue({
      branch: ''
    });
    this.enableBranch = true;
    this.service.getBranchDetail(`?filterbybrand=1&brand_id=${brand}`).subscribe(
      result => {
        this.branches = result.body;

      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  getReport(value) {
    this.fromdate = this.datePipe.transform(this.dailyReport.value.fromDate, 'yyyy-MM-dd');
    this.todate = this.datePipe.transform(this.dailyReport.value.toDate, 'yyyy-MM-dd');

    const queryBrand = this.dailyReport.value.brand || '';
    const queryBranch = this.dailyReport.value.branch || '';
    let query = '?'
    
    if (value == 'brand' || this.dailyReport.value.brand) {
      this.queryParams.Brand = 1;
      query = query + `filterbyBrand=${this.queryParams.Brand !== 0 ? 1 : 0}&brandId=${queryBrand}&`
    }
    if (value == 'branch' || this.dailyReport.value.branch) {
      this.queryParams.Branch = 1;
      query = query + `filterbyBranch=${this.queryParams.Branch != 0 ? 1 : 0}&branchId=${queryBranch}&`
    }
  
    query = `${value}`
    console.log("query", query.slice(0, -1))
    this.defaultQuery = `/daily?brand_id=${this.dailyReport.value.brand}&branch_id=${this.dailyReport.value.branch}&sdate=${this.fromdate}T00:00:00.000Z&edate=${this.todate}T00:00:00.000Z`
    if (!query) {
      query = this.defaultQuery;
    }
    this.dailyReports= [];
    this.dailyReports02= [];
    this.service.getSalesData(query).subscribe(r => {
      if (r.body) {
        this.dailyReports = r.body.response1;
        this.dailyReports02 = r.body.response2;
        if (this.dailyReports.length > 0) {
          this.showReport1 = true;
        } else {
          this.showReport1 = false;
        }
        this.transaction_Date = this.dailyReports[0].transaction_date;
        this.brandID = this.dailyReport.value.brand;
        this.branchID = this.dailyReport.value.branch;
        this.user = this.dailyReports[0].cashier_name;
        this.findTotal(this.dailyReports);
        this.findTotal02(this.dailyReports02);
        this.showReport = true;
      }
    });
  }


  filterByDate() {
    this.branchPrinterData();
    this.fromdate =
      this.datePipe.transform(this.dailyReport.value.fromDate, 'yyyy-MM-dd') ||
      '';
      this.todate =
      this.datePipe.transform(this.dailyReport.value.toDate, 'yyyy-MM-dd') ||
      '';
    
    this.getReport(
      this.defaultQuery = `/daily?brand_id=${this.dailyReport.value.brand}&branch_id=${this.dailyReport.value.branch}&sdate=${this.fromdate}T00:00:00.000Z&edate=${this.todate}T00:00:00.000Z`
      );
    
  }

  findTotal(data) {
    this.totalSales = data.reduce(function(cnt, o) {
      return cnt + o.totalamount;
    }, 0);
    this.totalDiscount = data.reduce(function(cnt, o) {
      return cnt + o.discountamount;
    }, 0);
    this.totalDelivery = data.reduce(function(cnt, o) {
      return cnt + o.delivery_amount;
    }, 0);
    this.totalNetAmount = data.reduce(function(cnt, o) {
      return cnt + o.totalnetamount;
    }, 0);
    this.totalCardAmount = data.reduce(function(cnt, o) {
      return cnt + (o.cardamount);
    }, 0);
    this.totalextraCash = data.reduce(function(cnt, o) {
      return cnt + (o.extra_cash);
    }, 0);
    this.totalshortCash = data.reduce(function(cnt, o) {
      return cnt + (o.short_cash);
    }, 0);
    this.totalcashierdue = data.reduce(function(cnt, o) {
      return cnt + (o.cashier_due);
    }, 0);
    this.totalCash = data.reduce(function(cnt, o) {
      return cnt + (o.float_amount_end);
    }, 0);
  }
  findTotal02(data) {
    this.totalSum = data.reduce(function(cnt, o) {
      return cnt + o.sum;
    }, 0);
 
  }
  getBranchName(data) {
    let returnBranch = 'NA';
    const queryBranch = this.branches.filter(res => res.branch_id == data);
    if (queryBranch.length !== 0) {
      returnBranch = queryBranch[0].branch_name;
    }
    return returnBranch;
  }
  getBrandName(data) {
    let returnBrand = 'NA';
    const queryBrand = this.brands.filter(res => res.brand_id == data);
    if (queryBrand.length !== 0) {
      returnBrand = queryBrand[0].brand_name;
    }
    return returnBrand;

  }
  print() {
    const today = Date.now();
    this.todayDate = this.datePipe.transform(today, "dd-MM-yyyy HH:mm");
    setTimeout(() => {
      this.printReceipt('daily_sales_report');
    }, 1000);
    this.showPrint = true;
  }
  printReceipt(div): void {
    // let popupWin;
    // let printContents;
    const printContents = document.getElementById(div).innerHTML;
    const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Sales Report</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
    const payload = {
      printer_name: this.ls.get("manager_printer"),
      printer_data: printContents,
    };
    this.printArray.push(payload);
    this.service.printer(this.printArray).subscribe(
      res => {
        this.printArray = [];
      },
      err => {
        this.printArray = [];
        console.log("error", err);
      });
    }
    branchPrinterData() {
      this.service
        .branchPrinters(`?id=${this.dailyReport.value.branch}`)
        .subscribe(
          r => {
            if (r.body.length > 0) {
              console.log("print api res", r);
              this.ls.set("manager_printer", r.body[0].printer_name);
              this.ls.set("printerIp", r.body[0].printer_ip);
              this.service.printApi = `http://${r.body[0].printer_ip}:5000/`;
            }
          },
          error => {
            this.service.openSnackBar(error.error.message);
          }
        );
    }
  

}
