import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts/charts/charts';

@Component({
  selector: 'pos-reports-chart',
  templateUrl: './reports-chart.component.html',
  styleUrls: ['./reports-chart.component.scss']
})
export class ReportsChartComponent implements OnInit {
  _chartData;
  _chartLabel;
  // @ViewChild('myCanvas')
  // public  canvas: ElementRef;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  public context: CanvasRenderingContext2D;
  @Input() chartTitle;
  @Input() chartTitleCustomClass;
  @Input() chartTitleFontSize;
  @Input() chartTitleColor;
  @Input() chartType: any;
  @Input()
  get chartData() {
    return this._chartData;
  }
  set chartData(val) {
    console.log('_chartData', val);
    this._chartData = val;
  }
  @Input() chartLabels1: any;
  @Input()
  get chartLabels() {
    return this._chartLabel;
  }
  set chartLabels(val) {
    console.log('_chartLabel', val);
    this._chartLabel = [];
    this._chartLabel = val;
    setTimeout(() => {
      if (this.chart && this.chart.chart && this.chart.chart.config) {
        this.chart.chart.config.data.labels = this._chartLabel;
        this.chart.chart.update();
      }
    });
  }
  @Input() chartColors: any;
  @Input() chartOptions;
  @Input() chartHeight;
  @Input() chartId: any;
  @Input() barChartOptions: any;
  c: any;
  ngOnInit() {
    this.c = document.getElementById("chartId");
    this.clearCanvas();
  }

  clearCanvas() {
    this.c.height = this.chartHeight;
  }
}
