// const url = "http://3.92.189.126:8547/";
// const url = 'http://62.215.168.194:5001/';
// // const url = 'http://localhost:3000/';
// const url = 'http://localhost:3000/';
// const url = "http://3.92.189.126:8545/api/bo/";
// const url = 'http://localhost/api/bo';
// const url = "https://pointof-sale.herokuapp.com"
// const url = "http://a06ed8732784.ngrok.io/";
// const url = "http://192.168.1.39:3000/"
// var url = "http://"+ window.location.hostname;
// const auth = 'http://62.215.168.194:5002/';
// const auth = "https://auth-ccs.herokuapp.com/";
// const auth = "http://3.92.189.126:8545/api/auth/";
// const url = "http://641de5b28c0b.ngrok.io/";
const auth = "https://rms.comorin.co/api/auth/"
const baseUrl = "https://rms.comorin.co/api/auth/"
const url = "https://rms.comorin.co/api/bo/"
const cloudUrl = "https://rms.comorin.co/api/"

// const auth = "http://035a8003.ngrok.io/";

const envObject = {
  posBackOfficeServiceUrl: url,
  posBackOfficeServiceCloudUrl: cloudUrl,
  authServiceUrl: auth,
  authBaseUrl:baseUrl
};

export const environment = {
  production: false,
  posBackOfficeServiceUrl: envObject.posBackOfficeServiceUrl,
  posBackOfficeServiceCloudUrl: envObject.posBackOfficeServiceCloudUrl,
  authServiceUrl: envObject.authServiceUrl,
  authBaseUrl:envObject.authBaseUrl
};
