import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
import { DatePipe, JsonPipe } from '@angular/common';
import { DateAdapter } from '@angular/material';

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss']
})
export class OrderSearchComponent implements OnInit {
  brands: any;
  allBranch: any;
  allusers: any;
  avaAgent: any;
  tableFieldFomate: any[];
  tableField: any;
  cashier: any;
  driver: any;
  onlineresorce: any[];
  transactiondate: any;
  dateValue: any;
  order_fromDate: any = new Date().toISOString();
  order_toDate: any = new Date().toISOString();
  orderSearch: any;

  queryParams = {
    customer_id: 0,
    order_id: 0,
    brand_id: 0,
    branch_id: 0,
    segment_id: 0,
    segment_name: '',
    user_name: '',
    agentname: '',
    agent_id: 0,
    status_id: 0,
    onlineType_id: 0,
    onlineType: '',
    user_id: 0,
    status_name: '',
    customerName: '',
    customer_number: ''
  };
  searchType: any;
  showTable = false;
  displaySelectData: any;
  listOfSegments: any = [];
  brand: string;
  cashierr: any;
  cashiers: any;
  userList: any;
  selectedBrandName: string;
  selectedBranchName: string;
  selectedSegmentName: string;
  selectedAgentName: string;
  selectedCustomerName: string;
  selectedCustomerNumber: string;
  selectedStatus: string;
  selectedOnlineType: string;
  selectedDriver: string;
  selectedUser: string;
  orderReport: FormGroup;
  maxToDate = new Date();
  maxFromDate = new Date();
  minToDate: any;
  logo;
  orgData: any;
  orgGet: any = [];
  brandGet: any = [];
  brandId: any;
  p: any;

  constructor(private fb: FormBuilder, public service: PosBackOfficeService, private adapter: DateAdapter<any>,
    public ls: LocalStorageService, private datePipe: DatePipe,) {
    this.listOfSegments = service.segmentForm;
  }

  ngOnInit() {
    this.orderReport = this.fb.group({
      fromDate: [new Date().toISOString().slice(0, 10)],
      toDate: [new Date().toISOString().slice(0, 10)],
      brandName: [''],
      branchName: [''],
      segmentName: ['']

    });
    this.dateAdapter();
    this.dateValidation();
    this.ordersearchGet();
    this.getOrgDetails();
    this.getTransactionDate();

  }
  dateAdapter() {
    this.adapter.setLocale('en-GB');
  }
  dateValidation() {
    this.minToDate = this.orderReport.value.fromDate;
    this.maxFromDate = this.orderReport.value.toDate;
  }

  enterName(event, key) {
    console.log("event", event);
    this.queryParams.customer_id = 1
    if (key == 'customerNumber') {
      this.queryParams.customer_number = event
      console.log("this.queryParams.customer_number", this.queryParams.customer_number)
    } else {
      this.queryParams.customerName = event
      console.log("this.queryParams.customerName", this.queryParams.customerName)
    }
    this.ordersearchGet()
  }

  ordersearchGet() {
    // const query = `?filterbyorder=0&filterbycustomer=0&filterByComplaints=0&filterByBrand=0&filterbybranch=0&filterbybrand=0&filterbystatus=0&filterbysegment=0&filterbyuser=0&complaint_id=0&filterbydriver=0&filterbyrole=0&order_id=0&brand_id=0&branch_id=0`
    const query = `?filterbybrand=${this.queryParams.brand_id !== 0 ? 1 : 0}&brand_id=${this.queryParams.brand_id}&filterbybranch=${this.queryParams.branch_id !== 0 ? 1 : 0}&branch_id=${this.queryParams.branch_id}&filterbysegment=${this.queryParams.segment_id !== 0 ? 1 : 0}&segment=${this.queryParams.segment_name}&filterbycustomer=${this.queryParams.customer_id !== 0 ? 1 : 0}&customer_name=${this.queryParams.customerName}&customer_number=${this.queryParams.customer_number}&filterbyuser=${this.queryParams.agent_id !== 0 ? 1 : 0}
    &user_id=${this.queryParams.agent_id}&filterbystatus=${this.queryParams.status_id !== 0 ? 1 : 0}&status=${this.queryParams.status_name}&filterbyonlineType=${this.queryParams.onlineType_id !== 0 ? 1 : 0}&online_type=${this.queryParams.onlineType}&filterbycashier=${this.queryParams.user_id !== 0 ? 1 : 0}&cashier_id=${this.queryParams.user_name}&fromDate=${this.order_fromDate}&toDate=${this.order_toDate}`
    console.log("query", query)

    this.service.getordersearch(query).subscribe(res => {
      console.log("res", res)
      this.orderSearch = res.body.rows;
      console.log("this.orderSearch", this.orderSearch)
    }, err => {
      console.log("err", err)
      if (err.error.status == false) {
        this.orderSearch.length = 0;
      }
    })
  }
  fromDate(event) {
    this.dateValidation();
    console.log("from event", event.value)
    this.order_fromDate = new Date(event.target.value).toISOString();
    this.ordersearchGet();
  }
  toDate(event) {
    this.dateValidation();
    console.log("to event", event.value)
    this.order_toDate = new Date(event.target.value).toISOString();
    this.ordersearchGet();
  }
  selectedBrand(key, type) {
    console.log("key", key, "value", type)
    this.searchType = type;
    this.queryParams.brand_id = 0;
    if (this.searchType == "brand") {
      this.queryParams.brand_id = key;
      console.log("this.queryParams.brand_id", this.queryParams.brand_id);
      // this.selectBrand(key)
    }
    this.ordersearchGet();
  }
  callcenterBranch(key, type) {
    console.log("key", key, "value", type)
    this.searchType = type;
    this.queryParams.branch_id = 0;
    if (this.searchType == "branch") {
      this.queryParams.branch_id = key;
      console.log("this.queryParams.branch_id", this.queryParams.branch_id);
    }
    this.ordersearchGet();
  }
  ccsegment(key, type) {
    console.log("key", key, "type", type)
    this.searchType = type;
    if (this.searchType == "segment") {
      this.queryParams.segment_id = 1;
      this.queryParams.segment_name = key;
      console.log("this.queryParams.branch_id", this.queryParams.segment_id);
    } else {
      this.queryParams.segment_id = 0;
      this.queryParams.segment_name = ''
    }
    this.ordersearchGet();

  }
  ccuser(key, type) {
    console.log("key", key, "type", type)
    this.searchType = type;
    if (this.searchType == "user") {
      this.queryParams.user_id = 1;
      this.queryParams.user_name = key;
      console.log("this.queryParams.user_id", this.queryParams.user_id);
    } else {
      this.queryParams.user_id = 0;
      this.queryParams.user_name = ''
    }
    this.ordersearchGet();

  }

  callcenterAgent(key, type) {
    console.log("key", key, "value", type)
    this.searchType = type;
    this.queryParams.agent_id = 1;
    if (this.searchType == "agent") {
      this.queryParams.agent_id = key;
      console.log("this.queryParams.agent_id", this.queryParams.agent_id);
    } else {
      this.queryParams.agent_id = 0;
    }
    this.ordersearchGet();
  }
  ccStatus(key, type) {
    console.log("key", key, "type", type)
    this.searchType = type;
    this.queryParams.status_id = 0;
    if (this.searchType == "status") {
      this.queryParams.status_id = key;
      if (this.queryParams.status_id == 1) {
        this.queryParams.status_name = 'Saved'
      } else if (this.queryParams.status_id == 2) {
        this.queryParams.status_name = 'Encashed'
      } else if (this.queryParams.status_id == 3) {
        this.queryParams.status_name = 'driver_out'
      } else if (this.queryParams.status_id == 4) {
        this.queryParams.status_name = 'driver_in'
      } else if (this.queryParams.status_id == 5) {
        this.queryParams.status_name = 'cancelled'
      } else if (this.queryParams.status_id == 0) {
        this.queryParams.status_name = 'null'
      }

      console.log("this.queryParams.branch_id", this.queryParams.status_id);
    }
    this.ordersearchGet();
  }
  ccOnlineType(key, type) {
    console.log("key", key, "type", type)
    this.searchType = type;
    this.queryParams.onlineType_id = 0;
    if (this.searchType == "online_type") {
      this.queryParams.onlineType_id = 1;
      this.queryParams.onlineType = key;
    } else {
      this.queryParams.onlineType_id = 0;
      this.queryParams.onlineType = 'null'
    }
    this.ordersearchGet();
  }

  Orderdetails(data) {
    console.log("data", data)
    this.displaySelectData = data;
    this.showTable = true;
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
      this.orgData = result.body
      for (let i = 0; i < this.orgData.length; i++) {
        this.orgGet.push(this.orgData[i].Organization_id)
      }
      this.getBrand();
    });
  }
  getBrand() {
    this.brandGet = [];
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(result => {
      this.brands = result.body;
      for (let i = 0; i < this.brands.length; i++) {
        this.brandGet.push(this.brands[i].brand_id)
      }
    });
  }
  getBranch(brandId) {
    this.brandId = brandId.brand_id;
    this.service.getBranchDetail(`?filterbybrand=1&brand_id=${brandId.brand_id}`).subscribe(
      (data) => {
        if (data.status) {
          this.allBranch = data.body;
          this.getBrandImage(brandId.image_id)
          console.log("thia.allBrabch", this.allBranch)
        }
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  getBrandImage(imageid) {
    this.service.getImage(imageid).subscribe((r: any) => {
      if (r.body.length !== 0) {
        this.logo = r.body[0].image_data;
      }
    });
  }

  getAllUsers(branch) {
    this.service.getAgentUser(`?brand_id=${this.brandId}&branch_id=${branch}`).subscribe(res => {
      console.log("res for all", res);
      this.allusers = res;
      this.avaAgent = this.allusers.filter(function (res) {
        return res.role == 'cc_agent'
      })
      this.cashier = this.allusers.filter(function (res) {
        return res.role == "cashier"
      })
      this.driver = this.allusers.filter(function (res) {
        return res.role == "driver"
      })
      console.log("driver", this.driver)
    })
  }
  getUserDetails(branch) {
    this.cashiers = "cashier"
    this.service
      .getUserListForManager(
        `?brand_id=${this.brandId}&branch_id=${branch}`
      )
      .subscribe(res => {
        this.userList = res;
      });
  }
  onlineSourceApi(branch) {
    const abc = [];
    this.service.onlineorders(`?brand_id=${this.brandId}&branch_id=${branch}`).subscribe(res => {
      console.log(res.body.onlinetypes)
      res.body.forEach(ele => {
        console.log(ele);
        if (ele.online_status == true) {
          abc.push(ele)
        }
      })
      this.onlineresorce = abc;
    });
  }
  onColumnStatusChange(event) {
    this.tableFieldFomate = []
    this.tableField.forEach(fieldValue => {
      console.log("123", fieldValue)
      const seperatedValue = fieldValue.split('-');
      const obj = {
        field: seperatedValue[0],
        key: seperatedValue[1]
      }
      this.tableFieldFomate.push(obj)
      console.log("push", this.tableFieldFomate)
    });
    console.log(this.tableField)
  }

  getTransactionDate() {
    this.service.getTransactionDate().subscribe(res => {
      this.transactiondate = res.body;
      console.log(this.transactiondate)
      if (this.transactiondate.length > 0) {
        this.dateValue = this.transactiondate[0].cc_transaction_date
      } else {
      }
    })
  }

  printOrderSearch(data) {
    const todayDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm');
    // this.systemDate = todayDate; 
    const date = this.datePipe.transform(data.transaction_date, 'dd-MM-yyyy');
    console.log("hi")
    const myWindow = window.open('', '', 'style="width: 80mm",height:700');
    myWindow.document.write(
      '<table style="width:100%;page-break-after:auto;"><tr><td style="font-size:20px; text-align:center; font-weight:bold;">' +
      '<img src="' + this.logo + '" style="height:70px; width:70px;" alt="Logo" />' +
      '</td></tr></table>'
    );
    myWindow.document.write(
      '<table style="width:100%;page-break-after:auto;"><tr><td style="font-size:20px; text-align:center; font-weight:bold;">' +
      data.branch.branch_name +
      '</td></tr></table>'
    );
    myWindow.document.write(`
    <table style='padding-top:0px'>
    <tr style="width:100%;">
    <td style="text-align:center; width:5%;">
    <label style="font-size:20px !important; font-family: calibri !important; font-weight: 600;">Encashment Receipt
    </label>
    <br>
    <br>
    <label style="font-size:20px;"><label>
    </td>
    <br>
    </tr>                       
    </table>
    `);
    myWindow.document.write(
      '<table style="width:100%;page-break-after:auto;"><tr style="font-size:18px !important; font-family: calibri !important; font-weight: 600;"><td style="text-align:left;"><label>' +
      data.segment_name +
      '</label>' +
      '</td><td style="text-align:right; font-weight: 600;">Order# ' +
      data.receipt_manual_id +
      '</td></tr></table>'
    );

    myWindow.document.write(
      '<table style="width:100%;page-break-after:auto;"><tr><td style="text-align:right;font-weight: 600;">' +
      date +
      '</tr></table>'
    );
    myWindow.document.write(
      '<table style="width:100%; border-bottom: solid 2px #9d9d9d; border-top: solid 2px #9d9d9d;;page-break-after:auto;"><tr><td style="width:20%; text-align:right; font-weight: 600;">' +
      'Total' +
      '</td>' +
      '<td style="width:15%; text-align:right; font-weight: 600;">' +
      'Qty' +
      '</td>' +
      '<td style="width:20%; text-align:right; font-weight: 600;">' +
      'Price' +
      '</td>' +
      '<td style="width:25%; text-align:right; font-weight: 600;">' +
      'Item' +
      '</td></tr><table><br>'
    );
    data.order_details.map(menu => {
      console.log("menu", menu)
      myWindow.document.write(
        '<table style="width:100%;page-break-after:auto;"><tr><td style="width:20%; text-align:right; font-weight: 600;">' +
        (menu.quantity * menu.item_price).toFixed(3) +
        '</td><td style="width:15%; text-align:right; font-weight: 600;">' +
        menu.quantity +
        '</td>' +
        '<td style="width:20%;text-align:right; font-weight: 600;">' +
        menu.item_price.toFixed(3) +
        '</td>' +
        '<td style="width:25%; text-align:right; font-weight: 600;">' +
        menu.menu_name_arabic +
        '<br>' +
        menu.menu_name +
        '</td>' +
        '</tr><table><br>'
      );
      menu.allsubItems.map(submenu => {
        console.log("submenu", submenu)
        myWindow.document.write(
          '<table style="width:100%;page-break-after:auto;"><tr><td style="width:20%; text-align:right">' +
          (menu.quantity * submenu.item_price).toFixed(3) +
          '</td><td style="width:15%; text-align:right">' +
          menu.quantity +
          '</td>' +
          '<td style="width:20%; text-align:right">' +
          submenu.item_price.toFixed(3) +
          '</td>' +
          '<td style="width:25%; text-align:right">' +
          submenu.item_name_arabic +
          '<br>' +
          submenu.item_name +
          '</td>' +
          '</tr><table><br>'
        );
      })

    });

    myWindow.document.write(
      '<table style="width:100%; border-bottom: solid 2px #9d9d9d; border-top: solid 2px #9d9d9d; page-break-after:auto;">' +
      '<tr><td style="text-align:right; width: 25%"><label>' +
      data.total_cost.toFixed(3) +
      '</label></td><td style="width: 75%; text-align:right;"><label>Total / مجموع</label></td></tr>' +
      '<tr><td style="text-align:right; width: 25%"><label>' +
      data.discount_amount.toFixed(3) +
      '</label></td><td style="width: 75%; text-align:right;"><label>Discount / خصم</selectOrderDetailslabel></td></tr>' +
      '<tr><td style="text-align:right; width: 25%"><label>' +
      data.net_cost.toFixed(3) +
      '</label></td><td style="width: 75%; text-align:right;;"><label>Net Amount/ المبلغ</label></td></tr>' +
      '<tr><td style="text-align:right; width: 25%"><label>' +
      data.cash_amount.toFixed(3) +
      '</label></td><td style="width: 75%; text-align:right;"><label>Cash / نقدا</label></td></tr>' +
      '<tr><td style="text-align:right; width: 25%"><label>' +
      data.card_amount.toFixed(3) +
      '</label></td><td style="width: 75%; text-align:right;"><label>Card / بطاقة' +
      ' </label></td></tr>' +
      '<tr><td style="text-align:right; width: 25%"><label>' +
      data.balance_amount.toFixed(3) +
      '</label></td><td style="width: 75%; text-align:right; "><label>Tender / المبلغ</label></td></tr>' +
      '<table>'
    );
    myWindow.document.write(
      '<table style="width:100%;page-break-after:auto; font-weight: 600;"><tr><td>' +
      data.user_name +
      '</td></table>'
    );
    myWindow.document.write(
      '<table style="width:100%;page-break-after:auto; font-weight: 600;"><tr><td><label></label>' +
      todayDate +
      '</td></table><br>'
    );
    // myWindow.document.write(
    //   '<table style="width:100%;page-break-after:auto; border-top: solid 1px #9d9d9d;"><tr><td style="text-align:center">Thank you for visiting</td></tr></table>'
    // );
    myWindow.open();
    setTimeout(() => myWindow.print(), 300);
    setTimeout(() => myWindow.close(), 300);
  }
  clearFilter() {
    this.selectedBrandName = '';
    this.selectedBranchName = '';
    this.selectedSegmentName = '';
    this.selectedAgentName = '';
    this.selectedCustomerName = '';
    this.selectedCustomerNumber = '';
    this.selectedStatus = '';
    this.selectedOnlineType = '';
    //this.selectedDriver = '';
    this.selectedUser = '';
    this.queryParams.brand_id = 0;
    this.queryParams.branch_id = 0;
    this.queryParams.segment_id = 0;
    this.queryParams.agent_id = 0;
    this.queryParams.status_id = 0;
    this.queryParams.customer_id = 0;
    this.queryParams.user_id = 0;
    this.queryParams.user_name = '';
    this.queryParams.segment_name = '';
    this.queryParams.agentname = '';
    this.queryParams.status_name = '';
    this.queryParams.customerName = '';
    this.queryParams.customer_number = '';
    this.ordersearchGet();
  }

}
