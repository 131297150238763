import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-agent-performance',
  templateUrl: './agent-performance.component.html',
  styleUrls: ['./agent-performance.component.scss']
})
export class AgentPerformanceComponent implements OnInit {
  avaAgent: any;
  allusers: any;
  order_fromDate: any;
  order_toDate: any;
  agent: FormGroup;
  agentDetails: any;
  selectedAgent: any;
  obj: { h: number; m: number; s: number; };
  avgTime: string;
  orgData: any;
  orgGet: any = [];
  brands: any;
  brandGet: any = [];
  branch: any;
  brandId: any;
  constructor(private fb: FormBuilder, public service: PosBackOfficeService,
    public ls: LocalStorageService, public datePipe: DatePipe) { }

  ngOnInit() {
    this.agent = this.fb.group({
      brand: [''],
      branch: [''],
      fromDate: [''],
      toDate: [''],
      agentName: ['']
    })
    this.agent.patchValue({ fromDate: new Date(), toDate: new Date() })
    this.getOrgDetails();
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
      this.orgData = result.body
      for (let i = 0; i < this.orgData.length; i++) {
        this.orgGet.push(this.orgData[i].Organization_id)
      }
      this.getBrand();
    });
  }
  getBrand() {
    this.brandGet = [];
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(result => {
      this.brands = result.body;
      for (let i = 0; i < this.brands.length; i++) {
        this.brandGet.push(this.brands[i].brand_id)
      }
    });
  }
  getBranch(brandId) {
    this.brandId = brandId.brand_id;
    this.service.getBranchDetail(`?filterbybrand=1&brand_id=${brandId.brand_id}`).subscribe(
      (data) => {
        if (data.status) {
          this.branch = data.body;
        }
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }
  getAllUsers(branch) {
    this.service.getAgentUser(`?brand_id=${this.brandId}&branch_id=${branch}`).subscribe(res => {
      this.allusers = res;
      this.avaAgent = this.allusers.filter(function (res) {
        return res.role == 'cc_agent'
      })
    })
  }
  getData() {
    console.log("this.agent.value.fromDate", this.agent.value)
    const sDate = this.datePipe.transform(this.agent.value.fromDate, 'yyyy-MM-dd')
    const eDate = this.datePipe.transform(this.agent.value.toDate, 'yyyy-MM-dd')
    const payload = {
      fromDate: sDate + 'T00:00:00',
      toDate: eDate + 'T23:59:59'
    }
    console.log("payload", payload)
    let querry;
    if (this.selectedAgent) {
      querry = `?filterbyBrand=1&brand_id=${this.agent.value.brand}&filterbyBranch=1&branch_id=${this.agent.value.branch}&filterbyuser=1&user=${this.selectedAgent}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    } else {
      querry = `?filterbyBrand=1&brand_id=${this.agent.value.brand}&filterbyBranch=1&branch_id=${this.agent.value.branch}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    }
    console.log("querry", querry)
    this.service.getAgentPerformance(querry).subscribe(res => {
      console.log("res", res)
      this.agentDetails = res.body;
      this.agentDetails.forEach((element, index) => {
        console.log("element", element)
        const hours = Math.floor(element.avgTalktime / (60 * 60));

        const divisor_for_minutes = element.avgTalktime % (60 * 60);
        const minutes = Math.floor(divisor_for_minutes / 60);

        const divisor_for_seconds = divisor_for_minutes % 60;
        const seconds = Math.ceil(divisor_for_seconds);

        this.obj = {
          "h": hours,
          "m": minutes,
          "s": seconds
        };
        this.avgTime = this.obj.h + ':' + this.obj.m + ':' + this.obj.s
        this.agentDetails[index]['talktime'] = this.avgTime;
        console.log(this.obj.h + ':' + this.obj.m + ':' + this.obj.s)
        return this.obj;

      })
      console.log("agent details")
    })

  }

  fromDate(event) {
    console.log("event", event.value.toISOString());
    this.getData()
  }
  toDate(event) {
    console.log("event", event.value.toISOString());
    this.getData();
  }
  selectAgent(event) {
    console.log("event", event)
    this.selectedAgent = event
    if (event) {
      this.getData()
    }
    // const querry = `?fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    // this.service.getAgentPerformance(querry).subscribe(res => {
    //   console.log("res", res)
    //   this.agentDetails = res.body;
    //   console.log("agent details")
    // })
  }
  clearFilter() {
    this.agent.patchValue({
      fromDate: new Date(),
      toDate: new Date(),
      agentName: ''
    })
    this.getData();
  }
}
