import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-customer-report',
  templateUrl: './customer-report.component.html',
  styleUrls: ['./customer-report.component.scss']
})
export class CustomerReportComponent implements OnInit {
  customer: FormGroup;
  allusers: any;
  brands: any;
  areaName: string;
  areas: any;
  areaid: any;
  blocksToDisplay: any;
  typeOfCustomer: any;
  brandId: any;
  branchId: any;
  segment: any;
  blockId: any;
  fDate: any;
  eDate: any;
  customerReport: any;
  p = 1;
  orgData:any;
  orgGet:any=[];
  brandGet:any=[];
  branches:any;

  constructor(private fb: FormBuilder, public service: PosBackOfficeService,
    public ls: LocalStorageService, public datePipe: DatePipe) { }

  ngOnInit() {
    this.customer = this.fb.group({
      fromDate: [''],
      toDate: [''],
      brand: [''],
      branch: [''],
      segment: [''],
      area: [''],
      block: ['']
    })
    this.customer.patchValue({ fromDate: new Date(), toDate: new Date() })
    this.getOrgDetails();
    this.fromDate();
    this.toDate();
  }
  getAllUsers(branch) {
    this.service.getAgentUser(`?brand_id=${this.brandId}&branch_id=${branch}`).subscribe(res => {
      this.allusers = res;
    })
  }
  fromDate() {
    console.log("date", this.datePipe.transform(this.customer.value.fromDate, 'yyyy-MM-dd'))
    const f_date = this.datePipe.transform(this.customer.value.fromDate, 'yyyy-MM-dd')
    console.log("fdate", f_date + 'T' + '00:00:00')
    this.fDate = f_date + 'T' + '00:00:00.00'
    // this.fDate.setUTCHours(0,0,0,0);
    console.log(this.fDate)
    this.getCustomerDetail()
  }
  toDate() {
    console.log("date", this.datePipe.transform(this.customer.value.toDate, 'yyyy-MM-dd'))
    const e_Date = this.datePipe.transform(this.customer.value.toDate, 'yyyy-MM-dd')
    console.log("fdate", this.eDate + 'T' + '23:59:59.59')
    this.eDate = e_Date + 'T' + '23:59:59.59'
    console.log(this.eDate)
    this.getCustomerDetail()
  }
  selectBrand(brand) {
    console.log(brand)
    this.brandId = brand.brand_id;
    this.getCustomerDetail()
  }
  selectBranch(branch) {
    console.log(branch)
    this.branchId = branch.brand_id;
    this.getCustomerDetail()
  }
  ccsegment(value, name) {
    console.log(name)
    this.segment = name;
    this.getCustomerDetail()
  }
  getAreas = (branch) => {
    console.log("english", this.ls.get('language'));
    if (this.ls.get('language') == 'en') {
      this.areaName = 'area_name_english_ASC'
    } else {
      this.areaName = 'area_name_arabic_ASC'
    }
    const query = `?brand_id=${this.brandId}&branch_id=${branch}`;
    this.service.getAreas(query).subscribe((area) => {
      this.areas = area.body;
      console.log(this.areas)
    });
  };
  selectArea(area) {
    console.log('entered', area)
    console.log('blockid', this.blockId)
    this.blockId = ''
    if (area == 'allAreas') {
      this.areaid = 'allAreas'
    } else if (area.area_id) {
      this.areaid = area.area_id
    } else {
      this.areaid = area
    }
    // .globalarea.area_id
    const query = `?area_id=${this.areaid}`;
    console.log("query", query)
    this.service.getBlocksbyAreaid(query).subscribe(res => {
      console.log("res", res)
      this.blocksToDisplay = res.body;
      console.log("this.blocksToDisplay", this.blocksToDisplay)
    })
    this.getCustomerDetail()
  }


  getOrgDetails() {
    this.orgGet = [];
    this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
      this.orgData = result.body
      for (let i = 0; i < this.orgData.length; i++) {
        this.orgGet.push(this.orgData[i].Organization_id)
      }
      this.getBrand();
    });
  }
  getBrand() {
    this.brandGet = [];
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(result => {
      this.brands = result.body;
      for (let i = 0; i < this.brands.length; i++) {
        this.brandGet.push(this.brands[i].brand_id)
      }
    });
  }
  getBranch(brand) {
    this.service.getBranchDetail(`?filterbybrand=1&brand_id=${brand}`).subscribe(
      result => {
        this.branches = result.body;

      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  customerType(value) {
    console.log(value)
    this.typeOfCustomer = value
    this.getCustomerDetail()
  }
  selectBlock(value) {
    console.log(value)
    this.blockId = value.block_id;
    this.getCustomerDetail()
  }
  getCustomerDetail() {
    let query = '?'
    if (this.typeOfCustomer) {
      query = query + `customerType=${this.typeOfCustomer}&`
    }
    if (this.brandId) {
      query = query + `brand_id=${this.brandId}&`
    }
    if (this.branchId) {
      query = query + `branch_id=${this.branchId}&`
    }
    if (this.fDate) {
      query = query + `fromdate=${this.fDate}&`
    }
    if (this.eDate) {
      query = query + `todate=${this.eDate}&`
    }
    if (this.segment) {
      query = query + `segment=${this.segment}&`
    }
    if (this.areaid) {
      if (this.areaid != 'allAreas') {
        query = query + `areaid=${this.areaid}&`
      }
      if (this.areaid == 'allAreas') {
        query = query + `area=${this.areaid}&`
      }
    }

    if (this.blockId) {
      query = query + `blockid=${this.blockId}&`
    }
    query = `${query}`

    console.log("query", query.slice(0, -1))
    this.service.getCustomerReport(query.slice(0, -1)).subscribe(res => {
      console.log("res", res)
      this.customerReport = res.body;
    })
  }
  clearFilter() {
    this.typeOfCustomer = '';
    this.brandId = '';
    this.branchId = '';
    this.fDate = '';
    this.eDate = '';
    this.segment = '';
    this.areaid = '';
    this.blockId = '';
    this.fromDate();
    this.toDate();
    this.getCustomerDetail()
  }

}
