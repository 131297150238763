import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  inventoryItem: FormGroup;
  inventoryItemEdit: FormGroup;
  brandData: any;
  formSubmitted: any;
  branches: any;
  selectDisable: boolean;
  itemID: any;
  dataItems: any;
  itemData1: any;
  measuringUnits = [
    {
      name: 'Kg'
    },
    {
      name: 'Liters'
    },
    {
      name: 'Grams'
    },
    {
      name: 'No'
    }
  ];
  categoryCode: any;
  itemData: any;
  branchID: any;
  showItemData: boolean;
  noData: boolean;
  formSubmitted1: boolean;
  showPagination: boolean;
  editItemData: any;
  orgData: any;
  brandGet: any = [];
  branchData: any;
  orgGet: any = [];
  p: any;
  constructor(private fb: FormBuilder, public service: PosBackOfficeService, public ls: LocalStorageService) { }

  ngOnInit() {
    this.inventoryItem = this.fb.group({
      brand_id: ['', Validators.required],
      // branch_id: ['', Validators.required],
      item_name_english: ['', Validators.required],
      item_name_arabic: ['', Validators.required],
      item_code: [''],
      measuring_unit: ['', Validators.required],
      average_price: [0, [Validators.min(0)]],
      category_code: ['', Validators.required],
      type: ["", Validators.required]
    });
    this.inventoryItemEdit = this.fb.group({
      brand_id: [''],
      // branch_id:[''],
      item_id: [''],
      item_name_english: ['', Validators.required],
      item_name_arabic: ['', Validators.required],
      item_code: ['', Validators.required],
      measuring_unit: ['', Validators.required],
      average_price: [0, [Validators.min(0)]],
      category_code: ['', Validators.required],

    });
    this.getOrgDetails();
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
      this.orgData = result.body
      for (let i = 0; i < this.orgData.length; i++) {
        this.orgGet.push(this.orgData[i].Organization_id)
      }
      this.getBrandDetails();
    });
  }

  getBrandDetails() {
    this.brandGet = [];
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(
      result => {
        this.brandData = result.body;
        for (let i = 0; i < this.brandData.length; i++) {
          this.brandGet.push(this.brandData[i].brand_id)
        }
        this.getItemDetails();
      },
      error => {
        console.log(error);
      }
    );
  }

  getBranch(brand) {
    this.getInventoryCategoryCode();
    this.service.getBranchDetail(`?filterbybrand=1&brand_id=${brand}`).subscribe(
      result => {
        this.branchData = result.body;
      },
      error => {
        console.log('error', error);
      }
    );
  }

  submitItemData() {
    this.selectDisable = true;
    const brandId = this.inventoryItem.value.brand_id;
    if (this.inventoryItem.value.average_price == null) {
      this.inventoryItem.patchValue({ average_price: 0 });
    }
    console.log("save item", this.inventoryItem.value)
    this.service.saveItemsInventory(this.inventoryItem.value).subscribe(
      res => {
        console.log("submit()", res)
        this.selectDisable = false;
        this.getItemDetails();
        this.inventoryItem.reset();
        this.inventoryItem.patchValue({ average_price: 0, brand_id: brandId });
        this.service.openSnackBar('Data Added!');
      },
      error => {
        this.selectDisable = false;
        console.log(error);
        this.service.openSnackBar(error.error.message);
      }
    );
  }


  updateItems(data) {
    if (data.item_name_english == this.inventoryItemEdit.value.item_name_english) {
      const payload = {
        average_price:this.inventoryItemEdit.value.average_price ,
        brand_id:data.brand_id ,
        category_code:this.inventoryItemEdit.value.category_code ,
        item_code: this.inventoryItemEdit.value.item_code,
        item_id: data.item_id,
        item_name_arabic: this.inventoryItemEdit.value.item_name_arabic,
        measuring_unit: this.inventoryItemEdit.value.measuring_unit
      }
      if (this.inventoryItemEdit.value.average_price == null) {
        this.inventoryItemEdit.patchValue({ average_price: 0 });
      }
      this.service.updateItemsInventory(payload).subscribe(
        res => {
          this.getItemDetails();
          this.itemID = '';
          this.service.openSnackBar('Data Updated!');
        },
        error => {
          this.service.openSnackBar(error.error.message);
        }
      );
    } else {
      if (this.inventoryItemEdit.value.average_price == null) {
        this.inventoryItemEdit.patchValue({ average_price: 0 });
      }
      this.service.updateItemsInventory(this.inventoryItemEdit.value).subscribe(
        res => {
          this.getItemDetails();
          this.itemID = '';
          this.service.openSnackBar('Data Updated!');
        },
        error => {
          this.service.openSnackBar(error.error.message);
        }
      );
    }
  }

  getItemDetails() {
    this.service.getItemsInventory(`?filterByBrand=1&brand_id=${this.brandGet}`).subscribe((res: any) => {
      this.itemData1 = res.body;
      if (this.itemData1.length > 0) {
        if (this.itemData1.length > 16) {
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
        this.noData = false;
      } else {
        this.noData = true;
      }
      this.showItemData = true;
      res.body.categoryResponse.map(e => {
        this.itemData1.map(r => {
          if (e.category_code == r.category_code) {
            r.category_name_english = e.category_name_english;
            r.category_name_arabic = e.category_name_arabic;
          }
        });
      });
    });
  }

  deleteItem(data) {
    console.log(data);
    this.popUp();
    this.branchID = data;
  }

  deleteItemsModal() {
    this.service
      .deleteItemsInventory(
        `?brand_id=${this.branchID.brand_id}&item_id=${this.branchID.item_id}`
      )
      .subscribe(
        res => {
          this.service.openSnackBar('Data Deleted!');
          this.getItemDetails();
          this.popUpOff();
        },
        error => {
          this.service.openSnackBar(error.error.message);
        }
      );
  }

  editItems(data) {
    this.editItemData = data;
    console.log('data', data);
    this.itemID = data.item_id;
    this.inventoryItemEdit.patchValue(data);
  }

  getInventoryCategoryCode() {
    this.service.getCategoryInventory(`?filterByBrand=1&brand_id=${this.inventoryItem.value.brand_id}`).subscribe(
      res => {
        this.categoryCode = res.body;
      },
      error => {
        console.log(error);
      }
    );
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  isFieldValid(field: string) {
    return (
      (!this.inventoryItem.get(field).valid && this.inventoryItem.get(field).touched) ||
      (this.inventoryItem.get(field).untouched && this.formSubmitted)
    );
  }

  isFieldValid1(field: string) {
    return (
      (!this.inventoryItemEdit.get(field).valid && this.inventoryItemEdit.get(field).touched) ||
      (this.inventoryItemEdit.get(field).untouched && this.formSubmitted1)
    );
  }
}