import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuGroupComponent } from './menu-group/menu-group.component';
import { MenuMasterComponent } from './menu-master/menu-master.component';
import { BrandComponent } from './brand/brand.component';
import { BranchComponent } from './branch/branch.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { MainRegistrationComponent } from './main-registration/main-registration.component';
import { RegistrationComponent } from './registration/registration.component';
import { RolesComponent } from './roles/roles.component';
import { MenuCategoryComponent } from './menu-category/menu-category.component';
import { MenuItemCategoryComponent } from './menu-item-category/menu-item-category.component';
import { DiscountComponent } from './discount/discount.component';
import { PaymentTypeComponent } from './payment-type/payment-type.component';
import { BranchSetupComponent } from './branch-setup/branch-setup.component';
import { ModifierCatalogComponent } from './modifier-catalog/modifier-catalog.component';
import { ModifierGroupComponent } from './modifier-group/modifier-group.component';
import { PrinterComponent } from './printer/printer.component';
import { SalesReportComponent } from './sales-report/sales-report.component';
import { PaymentReportsComponent } from './payment-reports/payment-reports.component';
import { ProductMixReportComponent } from './product-mix-report/product-mix-report.component';
import { DiscountReportComponent } from './discount-report/discount-report.component';
import { TableSetupComponent } from './table-setup/table-setup.component';
import { DeliverySetupComponent } from './delivery-setup/delivery-setup.component';
import { AreaSetupComponent } from './area-setup/area-setup.component';
import { OnlineSetupComponent } from './online-setup/online-setup.component';
import { SupplierComponent } from './supplier/supplier.component';
import { ItemComponent } from './item/item.component';
import { InventoryCategoryComponent } from './inventory-category/inventory-category.component';
import { InventoryReportsComponent } from './inventory-reports/inventory-reports.component';
import { SalesSummaryComponent } from './sales-summary/sales-summary.component';
import { SalesSearchComponent } from './sales-search/sales-search.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { OrdersComponent } from './orders/orders.component';
import { RecipemanagementComponent } from './recipemanagement/recipemanagement.component';
import { FoodcostComponent } from './foodcost/foodcost.component';
import { SemifinishComponent } from './semifinish/semifinish.component';
import { ComplaintsComponent } from './complaints/complaints.component';
import { TransactionDateComponent } from './transaction-date/transaction-date.component';
import { OrderSearchComponent } from './order-search/order-search.component';
import { AgentPerformanceComponent } from './agent-performance/agent-performance.component';
import { HoursReportComponent } from './hours-report/hours-report.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { CustomerReportComponent } from './customer-report/customer-report.component';
import { AddPaymentComponent } from './add-payment/add-payment.component';
import { DailySalesComponent } from './web-components/daily-sales/daily-sales.component';
import { DailySalesReportComponent } from './daily-sales-report/daily-sales-report.component';
import { GrvByItemComponent } from './grv-by-item/grv-by-item.component';
import { WastageSummaryComponent } from './wastage-summary/wastage-summary.component';
import { OrganizationComponent } from './organization/organization.component';
import { StockReportComponent } from './stock-report/stock-report.component';
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'initReg',
    component: MainRegistrationComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'menuItem',
        component: MenuItemComponent
      },
      {
        path: '',
        redirectTo: 'salesDashboard',
        pathMatch: 'full'
      },
      {
        path: 'paymentType',
        component: PaymentTypeComponent
      },
      {
        path:'dailysalesreport',
        component:DailySalesReportComponent
      },
      {
        path: 'modifierCatalog',
        component: ModifierCatalogComponent
      },
      {
        path: 'modifierGroup',
        component: ModifierGroupComponent
      },
      {
        path: 'menugroup',
        component: MenuGroupComponent
      },
      {
        path: 'menuMaster',
        component: MenuMasterComponent
      },
      {
        path: 'order',
        component: OrdersComponent
      },

      {
        path: 'newmenumaster',
        component: BranchSetupComponent
      },
      {
        path: 'printer',
        component: PrinterComponent
      },
      {
        path: 'discount',
        component: DiscountComponent
      },
      {
        path: 'organization',
        component:OrganizationComponent
      },
      {
        path: 'brand',
        component: BrandComponent
      },
      {
        path: 'branch',
        component: BranchComponent
      },
      {
        path: 'warehouse',
        component: WarehouseComponent
      },
      {
        path: 'registration',
        component: RegistrationComponent
      },
      {
        path: 'role',
        component: RolesComponent
      },
      {
        path: 'menuCategory',
        component: MenuCategoryComponent
      },
      {
        path: 'menuItemCategory',
        component: MenuItemCategoryComponent
      },
      {
        path: 'salesReport',
        component: SalesReportComponent
      },
      {
        path: 'orderSearch',
        component: OrderSearchComponent
      },
      {
        path: 'agentPerformance',
        component: AgentPerformanceComponent
      },
      {
        path: 'hoursReport',
        component: HoursReportComponent
      },
      {
        path: 'customerReport',
        component: CustomerReportComponent
      },
      {
        path: 'paymentReport',
        component: PaymentReportsComponent
      },
      {
        path: 'productMixReport',
        component: ProductMixReportComponent
      },
      {
        path: 'discountReport',
        component: DiscountReportComponent
      },
      {
        path: 'tableSetUp',
        component: TableSetupComponent
      },
      {
        path: 'deliverySetup',
        component: DeliverySetupComponent
      },
      {
        path: 'areaSetUp',
        component: AreaSetupComponent
      },
      {
        path: 'onlineSetUp',
        component: OnlineSetupComponent
      },
      {
        path: 'supplier',
        component: SupplierComponent
      },
      {
        path: 'item',
        component: ItemComponent
      },
      {
        path: 'inventoryCategory',
        component: InventoryCategoryComponent
      },
      {
        path: 'inventoryReports',
        component: InventoryReportsComponent

      },
      {
        path: 'grvbyitem',
        component: GrvByItemComponent

      },
      {
        path: 'wastagesummary',
        component: WastageSummaryComponent
      },
      {
        path: 'stockReport',
        component: StockReportComponent
      },
      {
        path: 'salesSummary',
        component: SalesSummaryComponent
      },
      {
        path: 'salesSearch',
        component: SalesSearchComponent
      },
      {
        path: 'salesDashboard',
        component: SalesDashboardComponent
      },
      {
        path: "recipemanagement",
        component: RecipemanagementComponent,
      },
      {
        path: "foodcost",
        component: FoodcostComponent,
      },
      {
        path: "semifinish",
        component: SemifinishComponent,
      },
      {
        path: "complaints",
        component: ComplaintsComponent,
      },
      {
        path: "transactionDate",
        component: TransactionDateComponent
      },
      {
        path: "addpayment",
        component: AddPaymentComponent
      }
    ]
  },
  {
    path: '**',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
