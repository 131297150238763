import { Component, Input } from '@angular/core';

@Component({
  selector: 'pos-trending-items',
  templateUrl: './trending-items.component.html',
  styleUrls: ['./trending-items.component.scss']
})
export class TrendingItemsComponent {
  @Input() trendingItemName = 'Trending Items';
  @Input() trendingItemColor;
  @Input() trendingItemFontSize;
  @Input() customClass;
  @Input() titleCustomClass;
  @Input() height = 'maxHeight51';
  @Input() trendingItemData: any = [
    {
      img: '../../assets/images/images.jpg',
      menu_name: 'Oreo Milk Shake',
      quantity: '20.000'
    }
  ];
}
