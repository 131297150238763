import { Component, OnInit } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit {
  supplier: FormGroup;
  supplierEdit: FormGroup;
  brandData = [];
  updateValue: boolean;
  supplierEditData: any;
  formSubmitted: any;
  updateValueArabic: boolean;
  expectBrand: boolean;
  dataNotFound: boolean;
  supplierData: any;
  p: any;
  supplierId: any;
  supplierID: any;
  showReport = true;
  showPagination: boolean;
  orgData:any;
  orgGet:any = [];
  brandGet:any=[];

  constructor(public service: PosBackOfficeService, private fb: FormBuilder, public ls: LocalStorageService) {}

  ngOnInit() {
    this.supplier = this.fb.group({
      brand_id: ['', Validators.required],
      supplier_name_english: ['',[Validators.required,Validators.pattern(/^[a-zA-Z]{1,30}$/)]],
      supplier_name_arabic: ['', Validators.required],
      contact_person: ['',[Validators.required,Validators.pattern(/^[a-zA-Z]{1,30}$/)]],
      contact_person_number: ['', [Validators.pattern('^[0-9]{8,10}$')]]
    });
    this.supplierEdit = this.fb.group({
      brand_id: [''],
      supplier_id: [''],
      supplier_name_english: ['', Validators.required],
      supplier_name_arabic: ['', Validators.required],
      contact_person: [''],
      contact_person_number: ['', [Validators.pattern('^[0-9]{8,10}$')]]
    });
    this.getOrgDetails();
    
  }
  patchman1(){
    const one =this.supplier.get('supplier_name_english').value;
    this.supplier.patchValue({'supplier_name_english':one.replace(/^ +/gm, '')});
    const two =this.supplier.get('contact_person').value;
    this.supplier.patchValue({'contact_person ':two.replace(/^ +/gm, '')});
  }
  getOrgDetails() {
    this.orgGet =[]; 
    this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
       this.orgData=result.body
       for(let i = 0; i < this.orgData.length; i++){
         this.orgGet.push(this.orgData[i].Organization_id)
       }
        this.getBrandDetails();    
     });
   }
  
  getBrandDetails() {
    this.brandGet =[]; 
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(result => {
      this.brandData = result.body; 
      for(let i = 0; i < this.brandData.length; i++){
        this.brandGet.push(this.brandData[i].brand_id)
      }
      this.getSupplierDetails();
      
    });
  }

  submit() {
    const arrayData = [];
    this.supplierData.map(res => {
      if (res.brand_id == this.supplier.value.brand_id){
        arrayData.push(res);
      }
    });

    if (
      !arrayData.some(
        r =>
          (r.supplier_name_english).toUpperCase().replace(/\s/g, '') ==
         (this.supplier.value.supplier_name_english).toUpperCase().replace(/\s/g, '')
      )
    ) {
      if (
        !arrayData.some(
          r =>
            (r.supplier_name_arabic).toUpperCase().replace(/\s/g, '') ==
           (this.supplier.value.supplier_name_arabic).toUpperCase().replace(/\s/g, '')
        )
      ) {
        this.saveSupplierData();
      } else {
        this.service.openSnackBar('Supplier arabic name  already exists!');
      }
    } else {
      this.service.openSnackBar('Supplier name  already exists!');
    }
  }

saveSupplierData(){
  const brandId = this.supplier.value.brand_id;
  this.service.saveSupplierInventory(this.supplier.value).subscribe(
    res => {
      this.getSupplierDetails();
      this.supplier.reset();
      this.supplier.patchValue({brand_id: brandId});
      this.service.openSnackBar('Data Added!');
    },
    error => {
      console.log(error);
      this.service.openSnackBar(error.error.message);
    }
  );
}

  getSupplierDetails() {
    this.service.getSupplierInventory(`?filterByBrand=1&brand_id=${this.brandGet}`).subscribe(
      res => {
        this.supplierData = res.body;
        if (this.supplierData.length > 20) {
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
        if (this.supplierData.length === 0) {
          this.showReport = true;
        } else {
          this.showReport = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  deleteSupplier(data) {
    this.supplierId = data.supplier_id;
    this.popUp();
  }

  deleteModal() {
    this.service.deleteSupplierInventory(`?supplier_id=${this.supplierId}`).subscribe(
      res => {
        this.getSupplierDetails();
        this.service.openSnackBar('Data Deleted!');
        this.popUpOff();
      },
      error => {
        console.log(error);
      }
    );
  }

  editSupplierData(data) {
    this.supplierEditData = data;
    this.supplierID = data.supplier_id;
    this.supplierEdit.patchValue(data);
  }

  updateCategory(data) {
    this.service.updateSupplierInventory(data).subscribe(res => {
      this.getSupplierDetails();
      this.service.openSnackBar('Data Updated!');
      this.updateValueArabic = false;
      this.updateValue = false;
      this.supplierID = '';
    });
  }

  updateSupplier(data,datas) {
    if(this.supplierEdit.value.supplier_name_english == datas.supplier_name_english){
      const payload = {
        brand_id: this.supplierEdit.value.brand_id,
        contact_person: this.supplierEdit.value.contact_person,
        contact_person_number: this.supplierEdit.value.contact_person_number,
        supplier_id:this.supplierEdit.value.supplier_id,
        supplier_name_arabic:this.supplierEdit.value.supplier_name_arabic     
        
      }
    this.updateCategory(payload);      
    }else{   
      this.updateCategory(data);        
      }

  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  isFieldValid(field: string) {
    return (
      (!this.supplier.get(field).valid && this.supplier.get(field).touched) ||
      (this.supplier.get(field).untouched && this.formSubmitted)
    );
  }
}
