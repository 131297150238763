import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControlDirective } from '@angular/forms';
import { Routes, Router, RouterModule } from '@angular/router';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import {
  CookiesStorageService,
  LocalStorageService,
  SessionStorageService,
  SharedStorageService
} from 'ngx-store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  login: FormGroup;
  showLeftBar: boolean;
  hide = true;
  formSubmitAttempt: boolean;
  showOrderMenu = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.login = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.router.navigate(['/login']);
  }

  register() {
    this.router.navigate(['/initReg']);
    this.ls.set('color', '#007171');
  }

  onSubmit() {
    this.ls.set('color', '#007171');
   // this.login.value.admin = true;
    this.service.login(this.login.value).subscribe(
      (result: any) => {
        this.service.accent('#007171');
        if (result.data.domin === 'pos') {
          this.service.openSnackBar('Invalid Credentials');
        }
        const { domin } = result.data;
        if (!domin || domin === 'pos') {
          return;
        }
        this.ls.set('token', result.token);
        this.ls.set('userId',result.data.userId);
        this.ls.set('im',result.data._id);
        this.ls.set('language', 'English');
        this.ls.set('bouser', {
          id: result.data._id,
          name: result.data.name,
          domin: result.data.domin,
          boaccess:result.data.BoAccess
        });
        this.service.User(result.data);
        this.router.navigate(['']);
        console.log(" for dropDown checking mode")
      },
      (error: any) => {

        if (error.error.message === 'This username is not registered') {
          this.service.openSnackBar('Invalid Credentials');
        } else if (error.error.message === 'Password incorrect') {
          this.service.openSnackBar('Invalid Credentials');
        } else {
          this.service.openSnackBar("Invalid Credentials");
        }
      }
    );
  }

  isFieldValid(field: string) {
    return (
      (!this.login.get(field).valid && this.login.get(field).touched) ||
      (this.login.get(field).untouched && this.formSubmitAttempt)
    );
  }

  submitFloat() {
    this.router.navigate(['/orderMenu']);
  }
}

// <div class=" row col-lg-8 pr-0">
// <div class="">
//     <div class="cur_pointer">
//         <span class="hidden-file">
//             <input type="file" #fileInput ng2FileSelect [uploader]="uploader" alt="LOGI"
//                 (onFileSelected)="onFileSelected($event)" accept="image/*" />
//         </span>

//         <div class="">
//             <img src="{{image}}" class="imageResulation" alt="upload">
//             <span class="f13">Upload Image</span>
//         </div>
//     </div>

// </div>
// </div>