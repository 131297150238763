import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-segment-sales-pie-chart',
  templateUrl: './segment-sales-pie-chart.component.html',
  styleUrls: ['./segment-sales-pie-chart.component.css']
})
export class SegmentSalesPieChartComponent {
  @Input() pieChartData;
  @Input() pieChartLabels;
  @Input() pieChartType;
}
