import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService, CookiesStorageService } from 'ngx-store';
@Component({
  selector: 'app-inventory-category',
  templateUrl: './inventory-category.component.html',
  styleUrls: ['./inventory-category.component.scss']
})
export class InventoryCategoryComponent implements OnInit {
  inventoryCategory: FormGroup;
  inventoryCategoryEdit: FormGroup;
  brandData: any;
  editInvCategoryData: any;
  formSubmitted: boolean;
  invCategory: any;
  dataNotFound: boolean;
  p: any;
  categoryID: any;
  pT6: string;
  expectBrand: boolean;
  categoryId: any;
  brandId: any;
  orgData:any;
  branchData:any;
  brandGet:any=[];
  showPagination: boolean;
  orgGet:any=[];
  constructor(private fb: FormBuilder, public service: PosBackOfficeService, public ls: LocalStorageService) {}
  ngOnInit() {
    this.inventoryCategory = this.fb.group({
      brand_id: ['', Validators.required],
      // branch_id: ['', Validators.required],
      category_name_english: ['', Validators.required],
      category_name_arabic: ['', Validators.required],
      category_code: ['', [Validators.required, Validators.pattern('^[A-Z]{1,50}$')]]
    });
    this.inventoryCategoryEdit = this.fb.group({
      brand_id: [''],
      // branch_id:[''],
      category_id: [''],
      category_name_english: ['', Validators.required],
      category_name_arabic: ['', Validators.required],
      category_code: ['', [Validators.required, Validators.pattern('^[A-Z]{1,50}$')]]
    });
    this.getOrgDetails()    
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
      this.orgData = result.body
      for (let i = 0; i < this.orgData.length; i++) {
        this.orgGet.push(this.orgData[i].Organization_id)
      } 
      this.getBrandDetails();  
    });
  }
  getBrandDetails() {
    this.brandGet =[]; 
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(result => {
      this.brandData = result.body; 
      for(let i = 0; i < this.brandData.length; i++){
        this.brandGet.push(this.brandData[i].brand_id)
      }
      this.getInventCategory();
    });
  }
  getBranches() {
    this.service.getBranchDetail(`?filterbybrand=1&brand_id=${this.inventoryCategory.value.brand_id}`).subscribe(
      result => {
        this.branchData = result.body;
        
      },
      error => {
        console.log('error', error);
      }
    );
  }
  saveData(){
    const brandID = this.inventoryCategory.value.brand_id;
    this.service.saveCategoryInventory(this.inventoryCategory.value).subscribe(res =>{
      this.getInventCategory();
      this.service.openSnackBar('Data Added!');
      this.dataNotFound = false;
      this.inventoryCategory.reset();
      this.inventoryCategory.patchValue({brand_id: brandID});
      }, error => {
        console.log(error);
        this.service.openSnackBar(error.error.message);
      });
  }
  getInventCategory() {
    this.service.getCategoryInventory(`?filterByBrand=1&brand_id=${this.brandGet}`).subscribe(
      res => {
        this.invCategory = res.body
        if (this.invCategory.length > 10) {
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
        this.categoryID = '';
      },
      error => {
        console.log(error);
      }
    );
  }
  editInvCategory(data) {
    this.editInvCategoryData = data;
    this.categoryID = data.category_id;
    this.brandId = data.brand_id;
    this.pT6 = 'pt6';
    this.inventoryCategoryEdit.patchValue(data);
  } 
  updateCategory(payload,data){
    if(data.category_name_english == this.inventoryCategoryEdit.value.category_name_english){
      const info = {
        brand_id: this.inventoryCategoryEdit.value. brand_id,
        category_code: this.inventoryCategoryEdit.value.category_code,
        category_id: this.inventoryCategoryEdit.value.category_id,
        category_name_arabic: this.inventoryCategoryEdit.value.category_name_arabic
      }
      this.service.updateCategoryInventory(info).subscribe(res => {
        this.categoryID = '';
        this.getInventCategory();
        this.service.openSnackBar('Data Updated!');
      }, error => {
        this.service.openSnackBar(error.error.message);
      });
    }else{
      this.service.updateCategoryInventory(payload).subscribe(res => {
      this.categoryID = '';
      this.getInventCategory();
      this.service.openSnackBar('Data Updated!');
    }, error => {
      this.service.openSnackBar(error.error.message);
    });
  }
  }
  deleteCategory(data) {
    this.categoryId = data.category_id;
    this.brandId = data.brand_id;
    this.popUp();
  }
  deleteCategoryModal() {
    this.service
      .deleteCategoryInventory(`?category_id=${this.categoryId}&brand_id=${this.brandId}`)
      .subscribe(
        res => {
          this.getInventCategory();
          this.service.openSnackBar('Data Deleted!');
          this.popUpOff();
        },
        error => {
          this.service.openSnackBar(error.error.message);
        }
      );
  }
  popUp() {
    document.getElementById('id01').style.display = 'block';
  }
  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }
  isFieldValid(field: string) {
    return (
      (!this.inventoryCategory.get(field).valid && this.inventoryCategory.get(field).touched) ||
      (this.inventoryCategory.get(field).untouched && this.formSubmitted)
    );
  }
}
