import { Component, OnInit } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { DatePipe } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';

@Component({
  selector: 'app-sales-search',
  templateUrl: './sales-search.component.html',
  styleUrls: ['./sales-search.component.scss']
})
export class SalesSearchComponent implements OnInit {
  brands: any;
  branches: any;
  salesReport: FormGroup;
  enableBranch = false;
  showAvailable = true;
  showGroup: boolean;
  fromdate: string;
  todate: string;
  fullQuery: string;
  allUsers: any;
  segmentTypes: { name: string; key: string; }[];
  paymentTypes: any;
  branchId: number
  constructor(public service: PosBackOfficeService,
    private fb: FormBuilder, private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.salesReport = this.fb.group({
      brand: '',
      branch: '',
      fromDate: [''],
      toDate: [''],
      segement: [''],
      user: [''],
      paymentType: [''],

    });
    this.getBrand();
    this.getUserDetails();
    this.segmentTypes = this.service.segmentForm
  }

  getBranch(brand) {
    this.salesReport.patchValue({
      'branch': ''
    })
    this.enableBranch = true;
    this.service.Branches(`?brand_id=${brand}`).subscribe(r => {
      this.branches = r.body;
    });
  }
  getOnlineSetup(branchId) {
    this.service.getOnlineDelivery(`?id=${branchId}`).subscribe(res => {
      // this.paymentTypes = res.body[0].online_payment_type;
      this.paymentTypes = res.body.length > 0 ? res.body[0].online_payment_type : res.body
    });
  }
  getBrand() {
    this.service.brands('get').subscribe(
      result => {
        this.brands = result.body;
      },
      error => {
        console.log('error', error);
      });
  }
  sortDate() {
    this.showGroup = false;
    this.fromdate =
      this.datePipe.transform(this.salesReport.value.fromDate, 'yyyy-MM-dd') ||
      this.datePipe.transform(this.salesReport.value.toDate, 'yyyy-MM-dd') || '';
    this.todate =
      this.datePipe.transform(this.salesReport.value.toDate, 'yyyy-MM-dd') || this.fromdate || '';
    const queryUser = this.salesReport.value.userReport || '';
    const queryRole = this.salesReport.value.userroleReport || '';
    const querySegment = this.salesReport.value.segmentReport || '';
    const queryBranch = this.salesReport.value.branch || '';
    const defaultQuery = `?brand_id=${this.salesReport.value.brand}&branch_id=${queryBranch}`;
    this.fullQuery = `${defaultQuery}&sDate=${this.fromdate}&eDate=${
      this.todate
      }&user=${queryUser}&role=${queryRole}&segment=${querySegment}`;
    // this.getReport();
  }
  getUserDetails() {
    this.service.getAllUser().subscribe(
      result => {
        this.allUsers = result;
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

}

