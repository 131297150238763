import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { LocalStorageService } from 'ngx-store';

@Component({
  selector: 'app-payment-type',
  templateUrl: './payment-type.component.html',
  styleUrls: ['./payment-type.component.scss']
})
export class PaymentTypeComponent implements OnInit {
  paymentType: FormGroup;
  formSubmitAttempt: boolean;
  i: number;
  textAlign: string;
  constructor(private fb: FormBuilder, public ls: LocalStorageService) {}

  ngOnInit() {
    this.paymentType = this.fb.group({
      brand: ['', Validators.required],
      paymentType: ['', Validators.required]
    });
    this.inputAlignment();
  }

  inputAlignment() {
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  submitPaymentType() {
    this.paymentType.reset();
  }

  updatePaymentType() {
    this.popUp();
  }

  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  isFieldValid(field: string) {
    return (
      (!this.paymentType.get(field).valid && this.paymentType.get(field).touched) ||
      (this.paymentType.get(field).untouched && this.formSubmitAttempt)
    );
  }
}
