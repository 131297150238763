import { Component, OnInit, HostListener, EventEmitter } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService } from 'ngx-store';
import { FileUploader } from 'ng2-file-upload';


import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.scss']
})
export class MenuCategoryComponent implements OnInit {
  p = 1;
  category: any = [];
  showAddButton = true;
  space: string;
  rule: any;
  payload: any;
  query: any;
  categoryId: '';
  i: number;
  index: any;
  menuCategory: FormGroup;
  menuCategoryEdit: FormGroup;
  formSubmitAttempt1: boolean;
  formSubmitAttempt: boolean;
  brand: any;
  showAdd = true;
  textAlign: string;
  textRight: string;
  showAvailable = true;
  categoryBackup: any;
  buttonClick = false;
  showCreateMenu = false;
  showPagination: boolean;
  showCategory: boolean;
  imagedata = '';
  orgData: any;
  orgGet: any = [];
  brandGet: any = [];
  branch: any;
  payloads:any;
  logo: any = './../../assets/images/upload.jpg';

  showPdfErrorMsg: boolean;
  public uploader: FileUploader = new FileUploader({});
  categoryEditData: any;

  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) {

  }

  ngOnInit() {
    this.menuCategory = this.fb.group({
      category_name: ['', Validators.required],
      brand_id: ['', Validators.required],
      branch_id: ['', Validators.required],
      category_id: [''],
      category_order: ['', [Validators.required, Validators.min(1)]],
      description: ['', [Validators.required]],
      category_status: [true],
      category_name_arabic: ['', Validators.required],
      image_id: [null],
    });
    this.menuCategoryEdit = this.fb.group({
      category_name: ['', Validators.required],
      brand_id: ['', Validators.required],
      branch_id: ['', Validators.required],
      category_id: [''],
      category_order: ['', [Validators.required, Validators.min(1)]],
      description: ['', [Validators.required]],
      category_status: [true],
      image_id: [null],
      category_name_arabic: ['', Validators.required]
    });
    this.getOrgDetails();
    this.inputAlignment();
  }
  inputAlignment() {
    this.textRight = 'textRight';
    const lang = this.ls.get('language');
    if (lang === 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }

  getOrgDetails() {
    this.orgGet = [];
    this.service
      .getOrgDetails(`?userId=${this.ls.get("userId")}`)
      .subscribe((result) => {
        this.orgData = result.body;
        for (const data of this.orgData) {
          this.orgGet.push(data.Organization_id);
        }
        this.brandData();
      });
  }
  brandData() {
    this.brandGet = [];
    this.service
      .getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`)
      .subscribe((result) => {
        this.brand = result.body;
        for (const datas of this.brand) {
          this.brandGet.push(datas.brand_id);
        }
        this.menuCategoryCatalog('get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`);
      });
  }

  getBranch() {
    this.service
      .getBranchDetail(
        `?filterbybrand=1&brand_id=${this.menuCategory.value.brand_id}`
      )
      .subscribe(
        (result) => {
          this.branch = result.body;
        },
        (error) => {
          this.service.openSnackBar(error.error.message)
        }
      );
  }

  menuCategoryDelete(data) {
    this.payload = data;
    this.popUp();
  }

  deleteCategory() {
    this.menuCategoryCatalog('delete', this.payload.category_id, '?id=');
  }

  createMenuData() {
    this.showCreateMenu = true;
    this.showAddButton = false;
  }

  submitMenuCategory(rule, body, query) {
    this.menuCategoryCatalog(rule, body, query);
  }

  updateMenuCategory(rule, body, query) {
    if(body.category_name == this.menuCategoryEdit.value.category_name){
    this.payloads = {
      brand_id:body.brand_id,
      branch_id:body.branch_id,
      category_name_arabic:this.menuCategoryEdit.value.category_name_arabic,
      category_id:body.category_id,
      category_order:this.menuCategoryEdit.value.category_order,
      category_status: true,
      description:this.menuCategoryEdit.value.description,
      image_id:this.menuCategoryEdit.value.image_id
    }
    this.menuCategoryCatalog(rule,this.payloads, query);
  }else{
    this.menuCategoryCatalog(rule,this.menuCategoryEdit.value,query)
  }
  }

  menuCategoryCatalog(rule, payload = '', query = '', index = 1) {
    const brandID = this.menuCategory.value.brand_id;
    this.showCategory = true;
    this.service.menuCategoryCatalog(rule, payload, query).subscribe(
      result => {
        if (rule === 'post') {
          this.menuCategory.reset();
          this.menuCategory.patchValue({ category_status: true, brand_id: brandID });
          this.menuCategoryCatalog(
            'get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`

          );
          this.logo = './../../assets/images/upload.jpg';
          this.service.openSnackBar('Category Added!');
        }
        if (rule === 'get') {
          this.category = result.body;
          this.categoryBackup = result.body;
          this.category.map(element => {
            if (element.image_id == null) {
              element.logo = './../../assets/images/noImage123.jpg';
            } else {
              this.service.getImage(element.image_id).subscribe((r: any) => {
                if (r.body.length !== 0) {
                  element.logo = r.body[0].image_data;
                }
              });
            }

          });
          this.buttonClick = true;
          if (this.category.length > 10) {
            this.showPagination = true;
          } else {
            this.showPagination = false;
          }
        }
        if (rule === 'delete') {
          this.popUpOff();
          this.service.openSnackBar('Category Deleted!');
          this.menuCategoryCatalog('get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`);
        }
        if (rule === 'put') {
          this.service.openSnackBar('Category Updated!');
          this.menuCategoryCatalog(
            'get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`
          );
          this.showAdd = true;
          this.menuCategory.reset();
          this.categoryId = '';
          this.menuCategory.patchValue({
            category_status: true, brand_id: brandID
          });
        }
      },
      error => {
        this.categoryId = '';
       this.service.openSnackBar(error.error.message);
      }
    );

  }
  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }

  edit(data, index) {
    this.categoryEditData = data;
    this.categoryId = data.category_id;
    this.menuCategoryEdit.patchValue(data);
  }

  isFieldValid(field: string) {
    return (
      (!this.menuCategory.get(field).valid && this.menuCategory.get(field).touched) ||
      (this.menuCategory.get(field).untouched && this.formSubmitAttempt)
    );
  }

  applyFilter(filter: string) {
    this.space=filter.replace(/^\s+|\s+$/gm,'')
    this.category = this.categoryBackup.filter(item => {
      if (
        item.category_name.toString().toLowerCase().indexOf(this.space.toLowerCase()) !== -1 ||  item.category_name_arabic
          .toString()
          .toLowerCase()
          .indexOf(this.space.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
  }

  // image function
  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.showPdfErrorMsg = false;
      console.log('no');
    } else {
      this.showPdfErrorMsg = true;
      console.log('yes');
    }
    const FileSize = file.size / 1024 / 1024;

    if (FileSize > 0.5) {
      return this.service.openSnackBar(`File size exceeds 500KB`);
    }

    console.log(file);
    this.imagedata = file.name;
    const formData = new FormData();
    formData.append('file', file);
    console.log('form data', formData);
    this.service.upload(formData).subscribe(
      result => {
        this.menuCategory.patchValue({ image_id: result.body.image_id });
        this.service.getImage(result.body.image_id).subscribe((r: any) => {
          this.logo = r.body[0].image_data;
        });
      },
      error =>
        this.service.openSnackBar(error.error.message)
    );
  }
  public onFileSelected1(event: EventEmitter<File[]>, i) {
    const file: File = event[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.showPdfErrorMsg = false;
      console.log('no');
    } else {
      this.showPdfErrorMsg = true;
      console.log('yes');
    }
    const FileSize = file.size / 1024 / 1024;

    if (FileSize > 0.5) {
      return this.service.openSnackBar(`File size exceeds 500KB`);
    }

    console.log(file);
    this.imagedata = file.name;
    const formData = new FormData();
    formData.append('file', file);
    console.log('form data', formData);
    this.service.upload(formData).subscribe(
      result => {
        this.menuCategoryEdit.patchValue({ image_id: result.body.image_id });
        this.service.getImage(result.body.image_id).subscribe((r: any) => {
          this.category[i].logo = r.body[0].image_data;
        });
      },
      error =>
        this.service.openSnackBar(error.error.message)
    );
  }
}
