import { NgModule, Injector, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { createCustomElement } from '@angular/elements';
import { DailySalesComponent } from './daily-sales/daily-sales.component';
import { TrendingItemsComponent } from './trending-items/trending-items.component';
import { ChartsModule } from 'ng2-charts';
import { ReportsChartComponent } from './reports-chart/reports-chart.component';
import { SegmentSalesPieChartComponent } from './segment-sales-pie-chart/segment-sales-pie-chart.component';
@NgModule({
   imports: [
       CommonModule,
       ChartsModule
   ],
   declarations: [
    DailySalesComponent,
    TrendingItemsComponent,
    ReportsChartComponent,
    SegmentSalesPieChartComponent ],
   entryComponents: [
    DailySalesComponent,
    TrendingItemsComponent,
    ReportsChartComponent,
    SegmentSalesPieChartComponent
   ],
   exports: [
    DailySalesComponent,
    TrendingItemsComponent,
    ReportsChartComponent,
    SegmentSalesPieChartComponent
   ],
   schemas: [
       CUSTOM_ELEMENTS_SCHEMA
   ]
})
export class WebComponentModule {
   constructor(private injector: Injector) {
   }
   ngDoBootstrap() {
       const elements: any[] = [
           [DailySalesComponent, 'pos-daily-sales'],
           [TrendingItemsComponent, 'pos-trending-items'],
           [ReportsChartComponent, 'pos-reports-chart']
       ];
       for (const [component, name] of elements) {
           const el = createCustomElement(component, { injector: this.injector });
           customElements.define(name, el);
       }
   }
}