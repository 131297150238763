import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { PosBackOfficeService } from "../providers/pos-back-office.service";
import { LocalStorageService, CookiesStorageService } from "ngx-store";
@Component({
  selector: "app-delivery-setup",
  templateUrl: "./delivery-setup.component.html",
  styleUrls: ["./delivery-setup.component.scss"],
})
export class DeliverySetupComponent implements OnInit {
  deliverySetUp: FormGroup;
  formAttemptSubmit: boolean;
  brands: any;
  branches: any;
  tableCount: any;
  deliveryData: any;
  resDeliveryData: any;
  branchId: any;
  showData: boolean;
  deliveryId: any;
  branchIdDelte: any;
  deliveryID: any;
  areaData: any;
  showAdd = true;
  raw = false;
  p = 1;
  resDeliveryData1: any;
  ediData: any;
  showData1: boolean;
  paddLft: string;
  paddLft2: string;
  paddLft1: string;
  disabledAmount: boolean;
  showAmount: boolean;
  txtRight: any;
  showSubmitButton = false;
  paddl34: string;
  padR70: string;
  deliveryPriceSetup: FormGroup;
  pa34: string;
  disabledData: boolean;
  disableInput: boolean;
  deliveryAmtPrice: boolean;
  areaName: string;
  showEdit: boolean;
  resPrice: any;
  showArea = false;
  areas: any = [];
  orgData: any;
  orgGet: any = [];
  brandGet: any = [];
  brandID: any;
  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.deliverySetUp = this.fb.group({
      brand_id: ["", Validators.required],
      branch_id: ["", Validators.required],
      area_list: this.fb.array([]),
    });
    this.deliveryPriceSetup = this.fb.group({
      local_amount: [0, Validators.required],
      non_local_amount: [0, Validators.required],
      delivery_time_hours: [
        "00",
        [Validators.required,Validators.min(0),Validators.max(24),Validators.pattern("^[0-9]*$")],
      ],
      delivery_time_minutes: [
        "00",
        [Validators.required,Validators.min(0),Validators.max(60),Validators, Validators.pattern("^[0-9]*$")],
      ],
      delivery_time: ["00: 00", Validators.required],
      brand_id: [""],
      branch_id: [""],
    });
    this.getOrgDetails();

  }

  addTableNumbers() {
    (<FormArray>this.deliverySetUp.get("block_list")).push(
      this.tableDataControls()
    );
  }
  areaListPush() {
    (<FormArray>this.deliverySetUp.get("area_list")).push(this.areaList());
  }
  tableDataControls() {
    return this.fb.group({
      block_Name: ["", Validators.required],
    });
  }

  setAreaDetails = (data, index) => {
    (<FormArray>this.deliverySetUp.get("area_list")).at(index).patchValue({
      area_name_arabic: data.area_name_arabic,
      area_id: data.area_id,
    });
  };

  areaList() {
    return this.fb.group({
      area_name_english: [""],
      area_name_arabic: [""],
      delivery_amount: [""],
      status: [true],
      locality: [true],
      area_id: [""],
      new: [true],
    });
  }
  addAreaList(data) {
    const control = new FormGroup({
      area_name_english: new FormControl(data.area_name_english),
      area_name_arabic: new FormControl(data.area_name_arabic),
      status: new FormControl(data.status),
      locality: new FormControl(data.locality),
      area_id: new FormControl(data.area_id),
      branch_id: new FormControl(this.deliverySetUp.value.branch_id),
      brand_id: new FormControl(this.deliverySetUp.value.brand_id),
      new: new FormControl(false),
    });
    (<FormArray>this.deliverySetUp.get("area_list")).push(control);
  }
  addAreaList2(data) {
    const control = new FormGroup({
      area_name_english: new FormControl(data.globalarea.area_name_english),
      area_name_arabic: new FormControl(data.globalarea.area_name_arabic),
      status: new FormControl(data.status),
      locality: new FormControl(data.locality),
      area_id: new FormControl(data.area_id),
      branch_id: new FormControl(this.deliverySetUp.value.branch_id),
      brand_id: new FormControl(this.deliverySetUp.value.brand_id),
      delivery_id: new FormControl(data.delivery_id),
      new: new FormControl(false),
    });
    (<FormArray>this.deliverySetUp.get("area_list")).push(control);
  }

  timeConvert = (n) => {
    const num = n;
    const hours = num / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    return `${rhours == 0
        ? "00"
        : rhours.toString().length == 1
          ? "0" + rhours.toString()
          : rhours
      }:${rminutes == 0
        ? "00"
        : rminutes.toString().length == 1
          ? "0" + rminutes.toString()
          : rminutes
      }`;
  };

  popUp() {
    document.getElementById("id01").style.display = "block";
  }

  popUpOff() {
    document.getElementById("id01").style.display = "none";
  }

  getOrgDetails() {
    this.orgGet = [];
    this.service
      .getOrgDetails(`?userId=${this.ls.get("userId")}`)
      .subscribe((result) => {
        this.orgData = result.body;
        for (const data of this.orgData) {
          this.orgGet.push(data.Organization_id);
        }
        this.getBrand();
      });
  }
  getBrand() {
    this.brandGet = [];
    this.service
      .getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`)
      .subscribe((result) => {
        this.brands = result.body;
        for (const datas of this.brands) {
          this.brandGet.push(datas.brand_id);
        }
        // this.getDeliveryDetails(this.raw, "");
      });
  }

  getBranch(branch) {
    this.brandID = branch
    this.service
      .getBranchDetail(
        `?filterbybrand=1&brand_id=${branch}`
      )
      .subscribe(
        (result) => {
          this.branches = result.body;
        },
        (error) => {
          console.log("error", error);
        }
      );
  }
  getDeliveryDetails(raw, data) {
    // this.branchId = data;
    this.service.getDeliveryData(`?raw=${raw}&id=${data}`).subscribe(
      (res) => {
        this.resDeliveryData = res.body;
        if (this.resDeliveryData.length > 0) {
          this.showData = true;
        } else {
          this.showData = false;
        }
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  getSavedAreaDetails(data) {
    this.branchId = data;
    this.getPriceDetails(data);
    this.showData1 = true;
    this.areaName = "";
    this.showArea = true;
    this.showSubmitButton = true;
    this.getAreasData(data);
    this.service.getDeliveryData(`?branch_id=${data}`).subscribe(
      (res) => {
        this.resDeliveryData1 = res.body;
        if (this.resDeliveryData1.length > 0) {
          this.showEdit = true;
          if (this.resDeliveryData1.length > 12) {
            this.pa34 = "padL34";
            this.padR70 = "pr64";
          } else {
            this.padR70 = "pr70";
            this.pa34 = "";
          }
          this.disabledData = true;
          this.paddLft = "";
          this.paddLft2 = "";
          this.paddLft1 = "";
          this.txtRight = "text-right";
          this.disabledAmount = true;
          this.showAmount = true;
          this.paddl34 = "";
          (<FormArray>this.deliverySetUp.get("area_list")).controls = [];
          this.resDeliveryData1.forEach((element) => {
            this.addAreaList2(element);
          });
          if (this.ediData === "edit") {
            this.getAreasData(data);
            this.paddLft = "pl-0";
            this.paddLft2 = "pl-2";
            this.paddLft1 = "pl-1";
            this.paddl34 = "pl34";
            this.pa34 = "";
            this.disabledData = false;
            this.showAmount = false;
            this.txtRight = "text-left";
          }
        } else {
          (<FormArray>this.deliverySetUp.get("area_list")).controls = [];
          this.getAreasData(data);
          this.showEdit = false;
          this.paddLft = "pl-0";
          this.paddLft2 = "pl-2";
          this.paddLft1 = "pl-1";
          this.disabledAmount = false;
          this.disabledData = false;
          this.showAmount = false;
          this.showSubmitButton = true;
          this.txtRight = "text-left";
          this.paddl34 = "pl34";
        }
        console.log("tthis.resDeliveryData1", this.resDeliveryData1)
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  addDeliverySetup() {
    let formValid = true;
    const newAreas = [];
    for (let i = 0; i < this.deliverySetUp.value.area_list.length; i++) {
      const obj = this.deliverySetUp.value.area_list[i];
      if (obj.new) {
        obj["brand_id"] = this.deliverySetUp.value.brand_id;
        obj["branch_id"] = this.deliverySetUp.value.branch_id;
        if (obj.area_name_english == "") {
          formValid = false;
        }
        newAreas.push(obj);
      }
    }
    if (formValid) {
      this.service.saveDeliveryData(newAreas).subscribe(
        (res) => {
          if (res.status === true) {
            this.getSavedAreaDetails(this.branchId);
            // this.getDeliveryDetails(this.raw, "");
            this.service.openSnackBar("Data Added!");
            this.showEdit = true;
          }
        },
        (error) => {
        if(error.error.message =='Area already added so u cannot add this area'){
          this.service.openSnackBar("Area already added");
        } 
        else if (error.error.message ==  "Body should be non empty array") {
          this.service.openSnackBar("Please select area")
      }
        else
        
          this.service.openSnackBar(error.error.message);

      }
      );
    } else {
      this.service.openSnackBar("Area is missing");
    }
  }

  edit(data, data1) {
    this.paddLft = "pl-0";
    this.paddLft2 = "pl-2";
    this.paddLft1 = "pl-1";
    this.ediData = data1;
    this.txtRight = "text-left";
    this.getSavedAreaDetails(data.branch_id);
    this.getBranch(data.brand_id);
    this.deliverySetUp.patchValue({
      brand_id: data.brand_id,
      branch_id: data.branch_id,
    });
    this.showAdd = false;
    this.showAmount = false;
    this.disableInput = true;
  }
  editAreaData(i, data) {
    this.areaName = data.value.area_name_english;
  }
  updateDeliverySetup() {
    this.service.updateDeliveryData(this.deliverySetUp.value).subscribe(
      (res) => {
        this.ediData = "";
        this.getSavedAreaDetails(this.branchId);
        this.service.openSnackBar("Data Updated!");
        this.showAdd = true;
        this.disableInput = false;
      },
      (error) => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  isFieldValid(field: string) {
    return (
      (this.deliverySetUp.get(field).invalid &&
        this.deliverySetUp.get(field).touched) ||
      (this.deliverySetUp.get(field).untouched && this.formAttemptSubmit)
    );
  }

  deleteDeliveryData(data) {
    this.branchIdDelte = data.branch_id;
    this.popUp();
  }

  deleteDeliveryModal() {
    this.service.deleteDeliveryData(this.branchIdDelte).subscribe((res) => {
      this.popUpOff();
      this.service.openSnackBar("Data Deleted!");
      // this.getDeliveryDetails(this.raw, "");
    });
  }

  cancelDeliverySetup() {
    (<FormArray>this.deliverySetUp.get("area_list")).controls = [];
    this.deliverySetUp.reset();
    this.showData1 = false;
    this.showAdd = true;
    this.showAmount = true;
    this.ediData = "";
    this.disableInput = false;
  }
  getAreasData(branch_id) {
    const query = `?brand_id=${this.brandID}&branch_id=${branch_id}`;
    this.service.getAreaData(query).subscribe(res => {
      this.areaData = res.body;
      this.areaData.forEach((element) => {
        if (
          !this.resDeliveryData1.some((r) => r.area_id === element.area_id)
        ) {
          if (element.branch_id == branch_id) {
            element["status"] = true;
            element["locality"] = true;
          }
        }
      });
    });
  }


  updateAreaData(data) {
    this.service.updateDeliveryData(data.value).subscribe((res) => {
      this.getSavedAreaDetails(this.branchId);
      this.service.openSnackBar("Area Updated!");
      this.areaName = "";
    });
  }
  getPriceDetails(branchId) {
    this.service
      .getDeliveryChargesData(`?branch_id=${branchId}`)
      .subscribe((res) => {
        this.resPrice = res.body;
        if (this.resPrice.length > 0) {
          const time = this.timeConvert(this.resPrice[0].delivery_time);
          const timeArray = time.split(":");
          this.deliveryPriceSetup.patchValue({
            local_amount: this.resPrice[0].local_amount,
            non_local_amount: this.resPrice[0].non_local_amount,
            delivery_time_hours: timeArray[0],
            delivery_time_minutes: timeArray[1],
            delivery_time: time,
            brand_id: this.resPrice[0].brand_id,
            branch_id: this.resPrice[0].branch_id,
          });
        } else {
          this.deliveryPriceSetup.patchValue({
            local_amount: 0,
            non_local_amount: 0,
            delivery_time_hours: +`00`,
            delivery_time_minutes: "00",
            delivery_time: "00:00",
            brand_id: "",
            branch_id: "",
          });
        }
      });
  }
  addPrices() {
    const deliveryTime = `${this.deliveryPriceSetup.value.delivery_time_hours == ""
        ? "00"
        : this.deliveryPriceSetup.value.delivery_time_hours.length == 1
          ? "0" + this.deliveryPriceSetup.value.delivery_time_hours
          : this.deliveryPriceSetup.value.delivery_time_hours
      }:${this.deliveryPriceSetup.value.delivery_time_minutes == ""
        ? "00"
        : this.deliveryPriceSetup.value.delivery_time_minutes.length == 1
          ? "0" + this.deliveryPriceSetup.value.delivery_time_minutes
          : this.deliveryPriceSetup.value.delivery_time_minutes
      }`;
    this.deliveryPriceSetup.patchValue({
      brand_id: this.deliverySetUp.value.brand_id,
      branch_id: this.deliverySetUp.value.branch_id,
      delivery_time: deliveryTime,
    });
    this.service
      .saveDeliveryChargesData(this.deliveryPriceSetup.value)
      .subscribe((res) => {
        this.getPriceDetails(this.deliverySetUp.value.branch_id);
        this.service.openSnackBar("Price Added!");
      });
  }
  updatePrices() {
    const deliveryTime = `${this.deliveryPriceSetup.value.delivery_time_hours == ""
        ? "00"
        : this.deliveryPriceSetup.value.delivery_time_hours.length == 1
          ? "0" + this.deliveryPriceSetup.value.delivery_time_hours
          : this.deliveryPriceSetup.value.delivery_time_hours
      }:${this.deliveryPriceSetup.value.delivery_time_minutes == ""
        ? "00"
        : this.deliveryPriceSetup.value.delivery_time_minutes.length == 1
          ? "0" + this.deliveryPriceSetup.value.delivery_time_minutes
          : this.deliveryPriceSetup.value.delivery_time_minutes
      }`;
    console.log("this.deliveryPriceSetup", this.deliveryPriceSetup)
    const body = {
      local_amount: this.deliveryPriceSetup.value.local_amount,
      non_local_amount: this.deliveryPriceSetup.value.non_local_amount,
      brand_id: this.deliveryPriceSetup.value.brand_id,
      branch_id: this.deliveryPriceSetup.value.branch_id,
      delivery_amount_id: this.resPrice[0].delivery_amount_id,
      delivery_time: deliveryTime,
    };
    this.service.updateDeliveryChargesData(body).subscribe((res) => {
      if (res.status) {
        this.getPriceDetails(this.deliveryPriceSetup.value.branch_id);
        this.service.openSnackBar("Price Updated!");
      }
    },
      (err) => {
        if (err) {
          this.service.openSnackBar(err.error.message)
        }
      })

  }
}
