import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, } from '@angular/forms';
import { LocalStorage } from '@ng-idle/core';
import { LocalStorageService } from 'ngx-store';
@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.scss']
})
export class SalesDashboardComponent implements OnInit {
  @ViewChild('myCanvas')
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;
  public chartType = 'line';
  public chartData: any = [];
  public chartLabels: any = [];
  public chartColors: any = [];
  public finalChartData: any = [];
  public trends: any = [];
  public pieChartLabels = ['Take Out', 'Dine In', 'Car Service', 'Catering'];
  public pieChartData = [100, 100, 100, 100];
  public pieChartType = 'pie';

  public withFilterTodaypieChartLabels = ['Take Out', 'Dine In', 'Car Service', 'Catering'];
  public withFilterTodaypieChartData = [100, 100, 100, 100];
  public withFilterTodaypieChartType = 'pie';

  public withFilterYesterdaypieChartLabels = ['Take Out', 'Dine In', 'Car Service', 'Catering'];
  public withFilterYesterdaypieChartData = [100, 100, 100, 100];
  public withFilterYesterdaypieChartType = 'pie';

  public chartOptions: any;
  dailySales: number;
  weeklySales: number;
  monthlySales: number;
  lastMonthSales: number;
  thisYearSales: any;
  pieChartData1: any;
  showPieChart: boolean;
  sDate: any;
  eDate: any;
  showToday: boolean;
  trendsyesterday: any;
  dashboardform: FormGroup;
  maxDate = new Date();
  formSubmitted;
  brand: any = [];
  branch: any = [];
  selectedBranch: any;
  selectedBrand: any;
  loading = false;
  withoutFilter = true;
  date: any;
  orgData: any;
  orgGet: any = [];
  brandGet: any = [];
  public barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Amount in KD'
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Last 6 months sales'
        }
      }]
    }
  }

  transactiondate: string;
  FilterPiecharQuery: string;
  pieChartData2: any;
  constructor(
    public service: PosBackOfficeService,
    private datePipe: DatePipe,
    public ls: LocalStorageService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.dashboardform = this.fb.group({
      brandname: [""],
      branchname: [""],
      fromDate: [""],
      toDate: [""],

    })

    this.chartData = [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Sales Data', fill: true, lineTension: 0 }
    ];


    this.chartColors = [
      {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderColor: ' gray'
      }
    ];
    this.getOrgDetails();

  }

  isFieldValid(field: string) {
    return (
      (!this.dashboardform.get(field).valid && this.dashboardform.get(field).touched) ||
      (this.dashboardform.get(field).untouched && this.formSubmitted)
    );
  }
  dashboardReport() {
    const sDate = new Date().toISOString().slice(0, 10);
    this.service.dashboard(`?sDate=${sDate}&brand_id=${this.brandGet}`).subscribe(r => {
      const data = r.body;
      this.dailySales = data.dailySales[0].net_sales == null ? 0 : data.dailySales[0].net_sales;
      this.weeklySales = data.weelkySales[0].net_sales == null ? 0 : data.weelkySales[0].net_sales;
      this.lastMonthSales =
        data.lastMonthSales[0].net_sales == null ? 0 : data.lastMonthSales[0].net_sales;
      this.monthlySales =
        data.monthlySales[0].net_sales == null ? 0 : data.monthlySales[0].net_sales;
      this.thisYearSales = data.yearlySales[0].net_sales == null ? 0 : data.yearlySales[0].net_sales;
    });
  }

  graph() {
    const sDate = new Date().toISOString().slice(0, 10);
    this.service.dashboardGraph(`?fromdate=${sDate}&brand_id=${this.brandGet}`).subscribe(async r => {
      this.chartData = await [];

      const myData = [];
      r.body.forEach((e: any) => {
        myData.push(e.net_sales.toFixed(3));
        this.chartLabels.push(e.month);
      });
      const MyObject = {
        data: myData,
        label: 'Sales',
        fill: true,
        lineTension: 0
      };
      this.chartData.push(MyObject);
      this.loading = true

    });
  }

  async netSalesLoop(params) {
    const myData = [];
    const month = []
    await params.forEach((e: any) => {
      myData.push(e.net_sales.toFixed(3));
      month.push(e.month);
    });
    const MyObject = {
      data: await myData,
      label: 'Sales',
      fill: false,
      lineTension: 0
    };
    console.log("months", month)
    this.chartLabels = month
    console.log("this.chartLabels", this.chartLabels)
    return MyObject;
  }

  trendingyesterday() {
    const todaydate = new Date();
    const yesterDayDate = todaydate.setDate(todaydate.getDate() - 1)
    const y = new Date(yesterDayDate)
    const query = `?sDate=${y.toISOString().slice(0, 10)}&brand_id=${this.brandGet}`
    this.service.dashboardTrends(query).subscribe(
      r => {
        this.trendsyesterday = r.body;
      },
      error => {
        this.service.openSnackBar('error in fetching trending items');
      }
    );
  }

  trendingMenu() {
    const sDate = new Date().toISOString().slice(0, 10);
    this.service.dashboardTrends(`?sDate=${sDate}&brand_id=${this.brandGet}`).subscribe(
      r => {
        this.trends = r.body;
      },
      error => {
        this.service.openSnackBar('error in fetching trending items');
      }
    );
  }

  getReportByDate(value) {
    console.log("vvvvvvvvvvvv", value)
    this.showPieChart = false;
    const date = new Date();
    console.log('this.date', date, value.index)
    if (value == 'default' || value.index == 0) {
      const sDate = this.datePipe.transform(date, 'yyyy-MM-dd');
      this.pieChartDetails(sDate)

    } else {

      const sDate = this.datePipe.transform(date.setDate(date.getDate() - 1), 'yyyy-MM-dd');
      this.pieChartDetails(sDate)
    }
    this.pieChartLabels = [];
    this.pieChartData = [];


  }

  pieChartDetails(sDate) {
    this.service
      .getBrandSalesReportByDate(
        `?brand_id=${this.brandGet}&branch_id=${''}&sDate=${sDate}&eDate=${sDate}`
      )

      .subscribe(r => {
        this.showPieChart = true;
        this.pieChartData1 = r.body[0].segment;
        console.log('this.pieChartData1', this.pieChartData1)
        if (this.pieChartData1.car_service) {
          this.pieChartLabels.push('Car Service');
          this.pieChartData.push(this.pieChartData1.car_service.total)
        }
        if (this.pieChartData1.delivery) {
          this.pieChartLabels.push('Delivery');
          this.pieChartData.push(this.pieChartData1.delivery.total)
        }
        if (this.pieChartData1.dine_in) {
          this.pieChartLabels.push('Dine In');
          this.pieChartData.push(this.pieChartData1.dine_in.total)
        }
        if (this.pieChartData1.take_out) {
          this.pieChartLabels.push('Take Out');
          this.pieChartData.push(this.pieChartData1.take_out.total)
        }

        if (this.pieChartData1.catering) {
          this.pieChartLabels.push('Catering');
          this.pieChartData.push(this.pieChartData1.catering.total)
        }

        if (this.pieChartData1.staff_meal) {
          this.pieChartLabels.push('Staff Meal');
          this.pieChartData.push(this.pieChartData1.staff_meal.total)
        }

        console.log('this.pieChartLabels', this.pieChartLabels)
        console.log('this.pieChartData', this.pieChartData)
      });
  }

  getOrgDetails() {
    this.orgGet = [];
    this.service
      .getOrgDetails(`?userId=${this.ls.get("userId")}`)
      .subscribe((result) => {
        this.orgData = result.body;
        for (let i = 0; i < this.orgData.length; i++) {
          this.orgGet.push(this.orgData[i].Organization_id);
        }
        this.brandData();
      });
  }

  brandData() {
    this.brandGet = [];
    this.service
      .getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`)
      .subscribe((result) => {
        this.brand = result.body;
        this.date = result.body;
        for (let i = 0; i < this.brand.length; i++) {
          this.brandGet.push(this.brand[i].brand_id);
        }
        this.dashboardReport();
        this.graph();
        this.trendingMenu();
        this.trendingyesterday()
        this.getReportByDate('default');
      });
  }
  getBranch(brand) {
    this.selectedBrand = brand
    this.service
      .getBranchDetail(`?filterbybrand=1&brand_id=${brand.brand_id}`)
      .subscribe(
        (result) => {
          this.branch = result.body;
        },
        (error) => {
          this.service.openSnackBar(error.error.message);
        }
      );
  }

  selectBranch(data) {
    this.selectedBranch = data
    this.filters()
  }
  selectsBranch() {
    this.selectedBranch = undefined;
    this.filters()
  }

  filterByDate() {
    const fromdate = this.datePipe.transform(this.dashboardform.value.fromDate, "yyyy-MM-dd");
    this.dashboardform.patchValue({ fromDate: fromdate });
    this.transactiondate = fromdate
    this.filters()
  }

  filters() {
    this.dashboardReportwithFilters();
    this.graphwithFilters();
    this.pieChartDetailswithFilters();
    this.trendingMenuWithFilterDate()
    this.trendingyesterdayWithFilter();
  }

  dashboardReportwithFilters() {
    const sDate = new Date().toISOString().slice(0, 10);
    let query = `?sDate=${sDate}`;
    if (this.selectedBrand == undefined && this.selectedBranch == undefined && this.transactiondate) {
      console.log("report: only dates no brand,branch")
      query = `?sDate=${this.transactiondate}&brand_id=${this.brandGet}&id=All`
    }
    if (this.selectedBrand && this.selectedBranch && this.transactiondate) {
      console.log("report: all")
      query = `?sDate=${this.transactiondate}&brand_id=${this.selectedBrand.brand_id}&id=${this.selectedBranch.branch_id}`
    }

    if (this.selectedBrand && this.selectedBranch == undefined && this.transactiondate) {
      console.log("report: only brand, selected date")
      query = `?sDate=${this.transactiondate}&brand_id=${this.selectedBrand.brand_id}&id=All`
    }
    if (this.selectedBrand && this.selectedBranch == undefined && this.transactiondate == undefined) {
      console.log("report: only brand, no date selected send today date")
      query = `?sDate=${sDate}&brand_id=${this.selectedBrand.brand_id}&id=All`
    }
    if (this.selectedBrand && this.selectedBranch && this.transactiondate == undefined) {
      console.log("report: only brand,branch no date selected send today date")
      query = `?sDate=${sDate}&brand_id=${this.selectedBrand.brand_id}&id=${this.selectedBranch.branch_id}`
    }

    this.service.dashboard(query).subscribe(r => {
      const data = r.body;
      this.dailySales = data.dailySales[0].net_sales == null ? 0 : data.dailySales[0].net_sales;
      this.weeklySales = data.weelkySales[0].net_sales == null ? 0 : data.weelkySales[0].net_sales;
      this.lastMonthSales =
        data.lastMonthSales[0].net_sales == null ? 0 : data.lastMonthSales[0].net_sales;
      this.monthlySales =
        data.monthlySales[0].net_sales == null ? 0 : data.monthlySales[0].net_sales;
      this.thisYearSales = data.yearlySales[0].net_sales == null ? 0 : data.yearlySales[0].net_sales;
    });
  }


  graphwithFilters() {
    console.log("graph===")
    const fromdate = new Date().toISOString().slice(0, 10);
    let query = ``;
    if (this.selectedBrand == undefined && this.selectedBranch == undefined && this.transactiondate) {
      console.log("graph: only dates no brand,branch")
      query = `?fromdate=${this.transactiondate}&brand_id=${this.brandGet}&id=All`
    }
    if (this.selectedBrand && this.selectedBranch && this.transactiondate) {
      console.log("graph:all")
      query = `?fromdate=${this.transactiondate}&brand_id=${this.selectedBrand.brand_id}&id=${this.selectedBranch.branch_id}`
    }

    if (this.selectedBrand && this.selectedBranch == undefined && this.transactiondate) {
      console.log("graph:only brand, selected date")
      query = `?fromdate=${this.transactiondate}&brand_id=${this.selectedBrand.brand_id}&id=All`
    }
    if (this.selectedBrand && this.selectedBranch == undefined && this.transactiondate == undefined) {
      console.log("graph:only brand, no date selected send today date")
      query = `?fromdate=${fromdate}&brand_id=${this.selectedBrand.brand_id}&id=All`
    }
    if (this.selectedBrand && this.selectedBranch && this.transactiondate == undefined) {
      console.log("graph:only brand,branch no date selected send today date")
      query = `?fromdate=${fromdate}&brand_id=${this.selectedBrand.brand_id}&id=${this.selectedBranch.branch_id}`
    }
    this.service.dashboardGraph(query).subscribe(async r => {
      this.chartData = await [];
      const myData = [];
      this.chartLabels = []
      r.body.forEach((e: any) => {
        myData.push(e.net_sales.toFixed(3));
        this.chartLabels.push(e.month);
      });
      const MyObject = {
        data: myData,
        label: 'Sales',
        fill: true,
        lineTension: 0
      };
      console.log("chartLables", this.chartLabels)
      this.chartData.push(MyObject);
      this.loading = true

    });
  }

  trendingMenuWithFilterDate() {
    console.log("trend called")
    const fromdate = new Date().toISOString().slice(0, 10);
    let query = ``;
    if (this.selectedBrand == undefined && this.selectedBranch == undefined && this.transactiondate) {
      console.log("today trending: only dates no brand,branch")
      query = `?sDate=${this.transactiondate}`
    }
    if (this.selectedBrand && this.selectedBranch && this.transactiondate) {
      console.log("today trending: graph:all")
      query = `?sDate=${this.transactiondate}&brand_id=${this.selectedBrand.brand_id}&id=${this.selectedBranch.branch_id}`
    }

    if (this.selectedBrand && this.selectedBranch == undefined && this.transactiondate) {
      console.log("today trending:only brand, selected date")
      query = `?sDate=${this.transactiondate}&brand_id=${this.selectedBrand.brand_id}&id=All`
    }
    if (this.selectedBrand && this.selectedBranch == undefined && this.transactiondate == undefined) {
      console.log("today trending:only brand, no date selected send today date")
      query = `?sDate=${fromdate}&brand_id=${this.selectedBrand.brand_id}&id=All`
    }
    if (this.selectedBrand && this.selectedBranch && this.transactiondate == undefined) {
      console.log("today trending:only brand,branch no date selected send today date")
      query = `?sDate=${fromdate}&brand_id=${this.selectedBrand.brand_id}&id=${this.selectedBranch.branch_id}`
    }
    this.service.dashboardTrends(query).subscribe(
      r => {
        this.trends = r.body;
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  trendingyesterdayWithFilter() {
    const fromdate = new Date();
    const yesterDayDate = fromdate.setDate(fromdate.getDate() - 1)
    const y = new Date(yesterDayDate)
    let query = ``

    if (this.selectedBrand == undefined && this.selectedBranch == undefined && this.transactiondate) {
      console.log("yesterday trending: only dates no brand,branch")
      const f1 = new Date(this.transactiondate);
      const y1 = f1.setDate(f1.getDate() - 1)
      const y = new Date(y1)
      query = `?sDate=${y.toISOString().slice(0, 10)}`
    }
    if (this.selectedBrand && this.selectedBranch && this.transactiondate) {
      console.log("yesterday trending:all")
      const f1 = new Date(this.transactiondate);
      const y1 = f1.setDate(f1.getDate() - 1)
      const y = new Date(y1)
      query = `?sDate=${y.toISOString().slice(0, 10)}&brand_id=${this.selectedBrand.brand_id}&id=${this.selectedBranch.branch_id}`
    }

    if (this.selectedBrand && this.selectedBranch == undefined && this.transactiondate) {
      console.log("yesterday trending:only brand, selected date")
      const f1 = new Date(this.transactiondate);
      const y1 = f1.setDate(f1.getDate() - 1)
      const y = new Date(y1)
      query = `?sDate=${y.toISOString().slice(0, 10)}&brand_id=${this.selectedBrand.brand_id}&id=All`
    }
    if (this.selectedBrand && this.selectedBranch == undefined && this.transactiondate == undefined) {
      console.log("yesterday trending:only brand, no date selected send today date")
      query = `?sDate=${y.toISOString().slice(0, 10)}&brand_id=${this.selectedBrand.brand_id}&id=All`
    }
    if (this.selectedBrand && this.selectedBranch && this.transactiondate == undefined) {
      console.log("yesterday trending:only brand,branch no date selected send today date")
      query = `?sDate=${y.toISOString().slice(0, 10)}&brand_id=${this.selectedBrand.brand_id}&id=${this.selectedBranch.branch_id}`
    }

    this.service.dashboardTrends(query).subscribe(
      r => {
        this.trendsyesterday = r.body;
      },
      error => {
        this.service.openSnackBar('error in fetching trending items');
      }
    );
  }

  pieChartDetailswithFilters() {
    const sDate = new Date().toISOString().slice(0, 10);
    const query = `?sDate=${sDate}`;
    if (this.selectedBrand == undefined && this.selectedBranch == undefined && this.transactiondate) {
      console.log("piechart: only dates no brand,branch")
      const date = new Date(this.transactiondate);
      const YesterdayDate = this.datePipe.transform(date.setDate(date.getDate() - 1), 'yyyy-MM-dd');
      const todaytquery = `?sDate=${this.transactiondate}&eDate=${this.transactiondate}`
      const yesterdayquery = `?sDate=${YesterdayDate}&eDate=${YesterdayDate}`
      this.pieChartDetailsWithFilterToday(todaytquery)
      this.pieChartDetailsWithFilterYesterday(yesterdayquery)
    }
    if (this.selectedBrand && this.selectedBranch && this.transactiondate) {
      console.log("piechart: all")
      const date = new Date(this.transactiondate);
      const YesterdayDate = this.datePipe.transform(date.setDate(date.getDate() - 1), 'yyyy-MM-dd');
      const Todayquery = `?brand_id=${this.selectedBrand.brand_id}&branch_id=${this.selectedBranch.branch_id}&sDate=${this.transactiondate}&eDate=${this.transactiondate}`
      const yesterdayquery = `?brand_id=${this.selectedBrand.brand_id}&branch_id=${this.selectedBranch.branch_id}&sDate=${YesterdayDate}&eDate=${YesterdayDate}`
      this.pieChartDetailsWithFilterToday(Todayquery)
      this.pieChartDetailsWithFilterYesterday(yesterdayquery)
    }

    if (this.selectedBrand && this.selectedBranch == undefined && this.transactiondate) {
      console.log("piechart: only brand, selected date")
      const date = new Date(this.transactiondate);
      const YesterdayDate = this.datePipe.transform(date.setDate(date.getDate() - 1), 'yyyy-MM-dd');
      const todayquery = `?brand_id=${this.selectedBrand.brand_id}&branch_id=All&sDate=${this.transactiondate}&eDate=${this.transactiondate}`
      const yesterdayquery = `?brand_id=${this.selectedBrand.brand_id}&branch_id=All&sDate=${YesterdayDate}&eDate=${YesterdayDate}`
      this.pieChartDetailsWithFilterToday(todayquery)
      this.pieChartDetailsWithFilterYesterday(yesterdayquery)
    }
    if (this.selectedBrand && this.selectedBranch == undefined && this.transactiondate == undefined) {
      console.log("piechart: only brand, no date selected send today date")
      const date = new Date(sDate);
      const YesterdayDate = this.datePipe.transform(date.setDate(date.getDate() - 1), 'yyyy-MM-dd');
      const todayquery = `?brand_id=${this.selectedBrand.brand_id}&sDate=${sDate}&eDate=${sDate}&branch_id=All`
      const yesterdayquery = `?brand_id=${this.selectedBrand.brand_id}&sDate=${YesterdayDate}&eDate=${YesterdayDate}&branch_id=All`
      this.pieChartDetailsWithFilterToday(todayquery)
      this.pieChartDetailsWithFilterYesterday(yesterdayquery)
    }
    if (this.selectedBrand && this.selectedBranch && this.transactiondate == undefined) {
      console.log("piechart: only brand,branch no date selected send today date")
      const date = new Date(sDate);
      const YesterdayDate = this.datePipe.transform(date.setDate(date.getDate() - 1), 'yyyy-MM-dd');
      const todayquery = `?brand_id=${this.selectedBrand.brand_id}&branch_id=${this.selectedBranch.branch_id}&sDate=${sDate}&eDate=${sDate}`
      const yesterdaydayquery = `?brand_id=${this.selectedBrand.brand_id}&branch_id=${this.selectedBranch.branch_id}&sDate=${YesterdayDate}&eDate=${YesterdayDate}`
      this.pieChartDetailsWithFilterToday(todayquery)
      this.pieChartDetailsWithFilterYesterday(yesterdaydayquery)
    }
    this.withoutFilter = false
  }

  getReportByDatewithFilter(value) {
    console.log("filterpiechart", value)
    this.showPieChart = false;
    const date = new Date(value);
    if (value == 'default' || value.index == 0) {
      const sDate = this.datePipe.transform(date, 'yyyy-MM-dd');

    } else {
      const sDate = this.datePipe.transform(date.setDate(date.getDate() - 1), 'yyyy-MM-dd');
    }
    this.pieChartLabels = [];
    this.pieChartData = [];
  }

  pieChartDetailsWithFilterToday(query) {
    this.withFilterTodaypieChartLabels = [];
    this.withFilterTodaypieChartData = [];
    this.showPieChart = false;
    this.service.getBrandSalesReportByDate(query).subscribe(r => {
      this.showPieChart = true;
      this.pieChartData1 = r.body[0].segment;
      console.log('this.pieChartData1', this.pieChartData1)
      if (this.pieChartData1.car_service) {
        this.withFilterTodaypieChartLabels.push('Car Service');
        this.withFilterTodaypieChartData.push(this.pieChartData1.car_service.total)
      }
      if (this.pieChartData1.delivery) {
        this.withFilterTodaypieChartLabels.push('Delivery');
        this.withFilterTodaypieChartData.push(this.pieChartData1.delivery.total)
      }
      if (this.pieChartData1.dine_in) {
        this.withFilterTodaypieChartLabels.push('Dine In');
        this.withFilterTodaypieChartData.push(this.pieChartData1.dine_in.total)
      }
      if (this.pieChartData1.take_out) {
        this.withFilterTodaypieChartLabels.push('Take Out');
        this.withFilterTodaypieChartData.push(this.pieChartData1.take_out.total)
      }

      if (this.pieChartData1.catering) {
        this.withFilterTodaypieChartLabels.push('Catering');
        this.withFilterTodaypieChartData.push(this.pieChartData1.catering.total)
      }

      if (this.pieChartData1.staff_meal) {
        this.withFilterTodaypieChartLabels.push('Staff Meal');
        this.withFilterTodaypieChartData.push(this.pieChartData1.staff_meal.total)
      }

      console.log('this.pieChartLabels', this.withFilterTodaypieChartLabels)
      console.log('this.pieChartData', this.withFilterTodaypieChartData)
    });
  }

  pieChartDetailsWithFilterYesterday(query) {
    console.log("yesterday99999999", query)
    this.withFilterYesterdaypieChartLabels = [];
    this.withFilterYesterdaypieChartData = [];
    this.showPieChart = false;
    this.service.getBrandSalesReportByDate(query).subscribe(r => {
      this.showPieChart = true;
      this.pieChartData2 = r.body[0].segment;
      console.log('this.pieChartData1', this.pieChartData2)
      if (this.pieChartData2.car_service) {
        this.withFilterYesterdaypieChartLabels.push('Car Service');
        this.withFilterYesterdaypieChartData.push(this.pieChartData2.car_service.total)
      }
      if (this.pieChartData2.delivery) {
        this.withFilterYesterdaypieChartLabels.push('Delivery');
        this.withFilterYesterdaypieChartData.push(this.pieChartData2.delivery.total)
      }
      if (this.pieChartData2.dine_in) {
        this.withFilterYesterdaypieChartLabels.push('Dine In');
        this.withFilterYesterdaypieChartData.push(this.pieChartData2.dine_in.total)
      }
      if (this.pieChartData2.take_out) {
        this.withFilterYesterdaypieChartLabels.push('Take Out');
        this.withFilterYesterdaypieChartData.push(this.pieChartData2.take_out.total)
      }

      if (this.pieChartData2.catering) {
        this.withFilterYesterdaypieChartLabels.push('Catering');
        this.withFilterYesterdaypieChartData.push(this.pieChartData2.catering.total)
      }

      if (this.pieChartData2.staff_meal) {
        this.withFilterYesterdaypieChartLabels.push('Staff Meal');
        this.withFilterYesterdaypieChartData.push(this.pieChartData2.staff_meal.total)
      }

      console.log('this.pieChartLabels', this.withFilterTodaypieChartLabels)
      console.log('this.pieChartData', this.withFilterYesterdaypieChartData)
    });
  }

}
