import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService } from 'ngx-store';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  menuItem: FormGroup;
  editMenuItem: FormGroup;
  resMenuItem: any;
  saveItems: [];
  space: string;
  brand: any;
  i: number;
  p;
  formSubmitAttempt: boolean;
  rule: any;
  payload: any;
  selected;
  query: any;
  index: any;
  itemID: '';
  showAvailable = true;
  textAlign: string;
  textRight: string;
  resMenuItemBackup: any;
  itemData: any;
  orgData: any;
  orgGet: any = [];
  brandGet: any = [];
  branch: any;

  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.menuItem = this.fb.group({
      item_name: ['', [Validators.required]],
      brand_id: ['', [Validators.required]],
      branch_id: ['', [Validators.required]],
      item_name_arabic: ['', [Validators.required]]
    });

    this.editMenuItem = this.fb.group({
      item_name: ['', [Validators.required]],
      brand_id: '',
      branch_id: '',
      item_id: [''],
      item_name_arabic: ['', [Validators.required]]
    });
    this.getOrgDetails();
    this.inputAlignment();
  }
  patchman1() {
    const one = this.menuItem.get('item_name').value;
    this.menuItem.patchValue({ 'item_name': one.replace(/^ +/gm, '') });
  }
  inputAlignment() {
    this.textRight = 'textRight';
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  getOrgDetails() {
    this.orgGet = [];
    this.service
      .getOrgDetails(`?userId=${this.ls.get("userId")}`)
      .subscribe((result) => {
        this.orgData = result.body;
        for (const data of this.orgData) {
          this.orgGet.push(data.Organization_id);
        }
        this.brandData();
      });
  }
  brandData() {
    this.brandGet = [];
    this.service
      .getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`)
      .subscribe((result) => {
        this.brand = result.body;
        for (const datas of this.brand) {
          this.brandGet.push(datas.brand_id);
        }
        this.Item('get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`)
      });
  }

  getBranch() {
    this.service
      .getBranchDetail(
        `?filterbybrand=1&brand_id=${this.menuItem.value.brand_id}`
      )
      .subscribe(
        (result) => {
          this.branch = result.body;
        },
        (error) => {
          this.service.openSnackBar(error.error.message)
        }
      );
  }
  edit(index, item) {
    this.itemData = item;
    this.itemID = item.item_id;
    this.editMenuItem.patchValue({
      item_id: item.item_id,
      item_name: item.item_name,
      item_name_arabic: item.item_name_arabic,
      brand_id: item.brand_id,
      branch_id: item.branch_id

    });
  }

  delete(rule, data, query, i) {
    this.rule = rule;
    this.payload = data;
    this.query = `?item_id=${data.item_id}&brand_id=${data.brand_id}&branch_id=${data.branch_id}`;
    this.index = i;
    this.popUp();
  }
  deleteMenuItem() {
    this.Item(this.rule, this.payload, this.query, this.index);
    this.popUpOff();
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }
  updateItem(rule, body, query, index) {
    if(this.itemData.item_name == this.editMenuItem.value.item_name){
      const data ={
        branch_id: this.editMenuItem.value.branch_id,
        brand_id:this.editMenuItem.value.brand_id,
        item_id: this.editMenuItem.value.item_id,
        item_name_arabic: this.editMenuItem.value.item_name_arabic
      }
      this.Item(rule, data, query, index);
    }else{
      this.Item(rule, body, query, index);

    }
  }
  postItem(rule, value) {
    
    this.Item(rule, value);
  }
  Item(rule, payload, query = '', index = 1) {
    console.log('rule payload,query', rule, payload, query)
    const brandID = this.menuItem.value.brand_id;
 
    
    this.service.Item(rule, payload, query).subscribe(
      result => {
      
        if (rule === 'get') {
          this.resMenuItem = result.body;
          this.resMenuItemBackup = result.body;
        }
        if (rule === 'delete') {
          this.service.openSnackBar('Item Removed!');
          this.Item('get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`)
        }
        if (rule === 'post') {
          
          this.Item('get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`);
          this.service.openSnackBar('Item Added');
          this.menuItem.reset();
          this.menuItem.patchValue({ brand_id: brandID });
        }
        if (rule === 'put') {
          this.service.openSnackBar('Item Updated!');
          // const response = result.body[1];
          this.itemID = '';
          this.Item('get', this.brandGet, `?filterByBrand=1&brand_id=${this.brandGet}`);
        }
      
      },
      (error) => {
       
        this.itemID = '';
    
       this.service.openSnackBar(error.error.message);
      }
    );
  }

  isFieldValid(field: string) {
    return (
      (!this.menuItem.get(field).valid && this.menuItem.get(field).touched) ||
      (this.menuItem.get(field).untouched && this.formSubmitAttempt)
    );
  }

  isFieldValid1(field: string) {
    return (
      (!this.editMenuItem.get(field).valid && this.editMenuItem.get(field).touched) ||
      (this.editMenuItem.get(field).untouched && this.formSubmitAttempt)
    );
  }
  onpaste(event:ClipboardEvent){
    let clipboardData = event.clipboardData 
    let Text = clipboardData.getData('text');
    this.space= Text.replace(/^\s+|\s+$/gm,'')
    this.resMenuItem = this.resMenuItemBackup.filter(item => {
      if (item.item_name.toString().toLowerCase().indexOf(this.space.toLowerCase()) !== -1 || item.item_name_arabic.toString().toLowerCase().indexOf(this.space.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
  
  }

  applyFilter(filter: string) {
    this.space=filter.replace(/^\s+|\s+$/gm,'')
    this.resMenuItem = this.resMenuItemBackup.filter(item => {
      if (item.item_name.toString().toLowerCase().indexOf(this.space.toLowerCase()) !== -1 || item.item_name_arabic.toString().toLowerCase().indexOf(this.space.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
  }
}
