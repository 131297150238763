import { PosBackOfficeService } from './../providers/pos-back-office.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { JwtHelperService } from '@auth0/angular-jwt';
import {
  CookiesStorageService,
  LocalStorageService,
  SessionStorageService,
  SharedStorageService
} from 'ngx-store';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(public ls: LocalStorageService, private service: PosBackOfficeService, private router:Router) {}
  public isAuthenticated(): boolean {
    const token = this.ls.get('token');
    if (token) {      
    const userType = this.ls.get('bouser');
    if (userType) {
      return userType.domin === 'backOffice' && token;
    }
    return false;    
    } else {
      this.router.navigate(['/login']);
    }
  }
}
