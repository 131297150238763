import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { PosBackOfficeService } from "../providers/pos-back-office.service";
import { LocalStorageService } from "ngx-store";
@Component({
  selector: "app-complaints",
  templateUrl: "./complaints.component.html",
  styleUrls: ["./complaints.component.scss"],
})
export class ComplaintsComponent implements OnInit {
  complainteForm: FormGroup;
  formSubmitted:any;
  brand:any;
  branch:any;
  complaints = [];
  brandIdDelete: any;
  complaintIdDelete: any;
  complaintUpdateForm: FormGroup;
  subCategory: FormGroup;
  showbtn = false;
  subcomplaintId: any;
  subcomplaint: any;
  showSubcategory: boolean;
  editSuuCat: FormGroup;
  editSubCat: FormGroup;
  orgData: any;
  orgGet: any = [];
  brandGet: any = [];

  constructor(
    private fb: FormBuilder,
    public service: PosBackOfficeService,
    public ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.complainteForm = this.fb.group({
      brand_name: ["", [Validators.required]],
      branch_name: ["", [Validators.required]],
      complaint_category_english: ["", [Validators.required]],
      complaint_category_arabic: ["", [Validators.required]],
      complaint_description_english: ["", [Validators.required]],
      complaint_description_arabic: ["", [Validators.required]],
    });

    this.complaintUpdateForm = this.fb.group({
      updatecomplaint_category_english: ["", [Validators.required]],
      updatecomplaint_category_arabic: ["", [Validators.required]],
      updatecomplaint_description_english: ["", [Validators.required]],
      updatecomplaint_description_arabic: ["", [Validators.required]],
    });
    this.subCategory = this.fb.group({
      brand: '',
      subCategoryEnglish: '',
      subCategoryArabic: '',
    })
    this.editSubCat = this.fb.group({
      subCat: '',
      subcatEng: '',
      subcatAra: ''
    })
    this.getOrgDetails();
  }

  isFieldValid(field: string) {
    return (
      (!this.complainteForm.get(field).valid &&
        this.complainteForm.get(field).touched) ||
      (this.complainteForm.get(field).untouched && this.formSubmitted)
    );
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
      this.orgData = result.body
      for (let i = 0; i < this.orgData.length; i++) {
        this.orgGet.push(this.orgData[i].Organization_id)
      }
      this.brandData();
    });
  }

  brandData() {
    this.brandGet = [];
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(result => {
      this.brand = result.body;
      for (let i = 0; i < this.brand.length; i++) {
        this.brandGet.push(this.brand[i].brand_id)
      }
      this.getComplaints();
    });
  }
  getBranch(brand) {
    this.service.getBranchDetail(`?filterbybrand=1&brand_id=${brand}`).subscribe(
      result => {
        this.branch = result.body;
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  getComplaints = () => {
    const query = `?filterByBrand=1&brand_id=${this.brandGet}`;
    this.service.getComplaints(query).subscribe((res) => {
      this.complaints = res.body;
      this.resetEdit();
    },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  };

  createComplaint = () => {
    if (this.complainteForm.valid) {
      const payload = {
        brand_id: this.complainteForm.value.brand_name,
        branch_id: this.complainteForm.value.branch_name,
        complaint_category_english: this.complainteForm.value
          .complaint_category_english,
        complaint_category_arabic: this.complainteForm.value
          .complaint_category_arabic,
        complaint_description_english: this.complainteForm.value
          .complaint_description_english,
        complaint_description_arabic: this.complainteForm.value
          .complaint_description_arabic,
      };
      this.service.addComplaint(payload).subscribe((res) => {
        if (res.status) {
          this.service.openSnackBar("Complaint added successfully");
          this.complainteForm.controls.brand_name.reset();
          this.complainteForm.controls.branch_name.reset();
          this.complainteForm.controls.complaint_category_english.reset();
          this.complainteForm.controls.complaint_category_arabic.reset();
          this.complainteForm.controls.complaint_description_arabic.reset();
          this.complainteForm.controls.complaint_description_english.reset();
          this.getComplaints();
        }
      },
        error => {
          this.service.openSnackBar(error.error.message);
        }
      );
    } else {
      this.service.openSnackBar("Please fill all the fields");
    }
  };

  removeComplaint = (id) => {
    this.complaintIdDelete = id;
    this.popUp();
  };

  resetEdit = () => {
    this.complaints.map((complaint) => {
      return (complaint["edit"] = false);
    });
  };

  confirmRemove = () => {
    const query = `?complaint_id=${this.complaintIdDelete}`;
    this.service.deleteComplaint(query).subscribe((res) => {
      if (res.status) {
        this.service.openSnackBar("Complaint deleted successfully");
        this.popUpOff();
        this.getComplaints();
      }
    });
  };

  updateComplaint = (id, data) => {
    const payload = {
      complaint_id: id,
      brand_id: data.brand_id,
      branch_id: data.branch_id,
      complaint_category_english: this.complaintUpdateForm.value
        .updatecomplaint_category_english,
      complaint_category_arabic: this.complaintUpdateForm.value
        .updatecomplaint_category_arabic,
      complaint_description_english: this.complaintUpdateForm.value
        .updatecomplaint_description_english,
      complaint_description_arabic: this.complaintUpdateForm.value
        .updatecomplaint_description_arabic,
    };
    this.service.updateComplaint(payload).subscribe((res) => {
      if (res.status) {
        this.service.openSnackBar("Complaint updated successfully");
        this.getComplaints();
      }
    },
      error => {
        this.getComplaints();
        this.service.openSnackBar(error.error.message);
      });
  };
  updateSubComplaint = (id) => {
    const payload = {
      subcomplaint_id: id,
      subcomplaint_english: this.editSubCat.value.subcatEng,
      subcomplaint_arabic: this.editSubCat.value.subcatAra
    }
    this.service.updateSubComplaint(payload).subscribe((res) => {
      if (res.status) {
        this.service.openSnackBar("Sub-Complaint updated successfully");
        this.getsubcomplaint(this.subcomplaintId);
      }
    },
      error => {
        this.service.openSnackBar(error.error.message);
      }
    );
  };

  popUp() {
    document.getElementById("id01").style.display = "block";
  }

  popUpOff() {
    document.getElementById("id01").style.display = "none";
  }

  editComplaint = (id, data) => {
    this.complaintUpdateForm.patchValue({
      updatecomplaint_category_english: data.complaint_category_english,
      updatecomplaint_category_arabic: data.complaint_category_arabic,
      updatecomplaint_description_english: data.complaint_description_english,
      updatecomplaint_description_arabic: data.complaint_description_arabic,
    });
    console.log("this.complaintUpdateForm", this.complaintUpdateForm)
    this.complaints.map((complaint) => {
      if (complaint.complaint_id == id) {
        return (complaint["edit"] = true);
      } else {
        return (complaint["edit"] = false);
      }
    });
  };
  editSubComplaint(id, data) {
    this.editSubCat.patchValue({
      subCat: data.complaint.complaint_category_english,
      subcatEng: data.subcomplaint_english,
      subcatAra: data.subcomplaint_arabic
    })
    this.subcomplaint.map((subcomplaint) => {
      console.log("subcomplaint", subcomplaint)
      if (subcomplaint.subcomplaint_id == id) {
        return (subcomplaint["edit"] = true);
      } else {
        return (subcomplaint["edit"] = false);
      }
    })
  }

  complaint() {
    this.showbtn = true;
    this.showSubcategory = true;
    this.subcomplaintId = this.subCategory.value.brand
    this.getsubcomplaint(this.subcomplaintId);
  }

  showSubcomplaints(data) {
    this.subcomplaintId = data.complaint_id
    this.getsubcomplaint(this.subcomplaintId);
    this.showSubcategory = true;
  }

  subComplaint() {
    const payload = {
      complaint_id: this.subCategory.value.brand,
      subcomplaint_english: this.subCategory.value.subCategoryEnglish,
      subcomplaint_arabic: this.subCategory.value.subCategoryArabic
    }
    this.showSubcategory = true;
    this.service.cresteSubcomplaint(payload).subscribe(res => {
      if (res.status) {
        this.subcomplaintId = this.subCategory.value.brand;
        this.showSubcategory = true;
        this.getsubcomplaint(this.subcomplaintId);
        this.subCategory.reset();
        this.service.openSnackBar("Sub-Complaint added successfully");

      }
    },
      error => {
        this.service.openSnackBar(error.error.message);
      })
  }

  getsubcomplaint(id) {
    this.service.getCreatedSubComplaint(id).subscribe(res => {
      if (res.status) {
        this.subcomplaint = res.body;
      }
    })
  }
  deleteSubcomplaints(data) {
    this.service.deletesubCompaint(data.subcomplaint_id).subscribe(res => {
      this.getsubcomplaint(this.subcomplaintId)
      this.service.openSnackBar("Sub-Complaint deleted successfully");
    },
      error => {
        this.service.openSnackBar(error.error.message);
      })
  }
}
