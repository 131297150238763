import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray
} from '@angular/forms';
import { PosBackOfficeService } from '../providers/pos-back-office.service';
import { LocalStorageService } from 'ngx-store';

@Component({
  selector: 'app-menu-item-category',
  templateUrl: './menu-item-category.component.html',
  styleUrls: ['./menu-item-category.component.scss']
})
export class MenuItemCategoryComponent implements OnInit {
  menuItemCategory: FormGroup;
  resMenuItem: any;
  saveItems: [];
  textAlign: string;
  constructor(private fb: FormBuilder, public service: PosBackOfficeService, public ls: LocalStorageService) {}

  ngOnInit() {
    this.menuItemCategory = this.fb.group({
      category: '',
      menuItemGroup: '',
      menuItem: ''
    });
    this.getAllItems();
    this.inputAlignment();
  }
  inputAlignment() {
    const lang = this.ls.get('language');
    if (lang == 'Arabic') {
      this.textAlign = 'textRight';
    } else {
      this.textAlign = 'textLeft';
    }
  }
  submit() {
    console.log('storeData', this.menuItemCategory.value);
    const itemData = this.menuItemCategory.value;
  }

  getAllItems() {
    this.service.Item('get').subscribe(result => {
      console.log('response', result);
      this.resMenuItem = result.body;
    });
  }
}
