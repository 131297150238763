import { Component, Input } from '@angular/core';

@Component({
  selector: 'pos-daily-sales',
  templateUrl: './daily-sales.component.html',
  styleUrls: ['./daily-sales.component.scss']
})
export class DailySalesComponent  {
  @Input() salesName;
  @Input() arabicName;
  @Input() dailySales;
  @Input() KD = "KD";
  @Input() customClass;
 
}
