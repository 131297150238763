import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { LocalStorageService } from 'ngx-store';
import { PosBackOfficeService } from "../providers/pos-back-office.service";
@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit {
  paymentTypeAdding: FormGroup;
  paymentNames: any;
  brand: any;
  ShowEdit: any;
  paymentTypeAddingEdit: FormGroup;
  orgData:any;
  brandGet:any = [];
  branchData:any;
  orgGet:any=[];
  paymentIdDelete:any

  constructor(public ls: LocalStorageService, public fb: FormBuilder, public service: PosBackOfficeService) { }

  ngOnInit() {
    this.paymentTypeAdding = this.fb.group({
      brand: ['', Validators.required],
      branch: ['', Validators.required],
      payment_type_english: ['', Validators.required],
      payment_type_arabic: ['', Validators.required],
      
    });
    this.paymentTypeAddingEdit = this.fb.group({
      payment_type_english: ['', Validators.required],
      payment_type_arabic: ['', Validators.required],
      brand: ['', Validators.required],
      branch: ['', Validators.required],
      payment_id: [''],
    });
    this.getOrgDetails();
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service.getOrgDetails(`?userId=${this.ls.get('userId')}`).subscribe(result => {
      this.orgData = result.body
      for (const data of this.orgData) {
        this.orgGet.push(data.Organization_id);
      }
      this.brandData();
    });
  }
  brandData() {
    this.brandGet = [];
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(
      result => {
        this.brand = result.body;
        for (const datas of this.brand) {
          this.brandGet.push(datas.brand_id);
        }
        this.addedPaymentNames();

      },
      error => {
        console.log(error);
      }
    );
  }
  getBranch(brand) {
    this.service.getBranchDetail(`?filterbybrand=1&brand_id=${brand}`).subscribe(
      result => {
        this.branchData = result.body; 
      },
      error => {
        console.log('error', error);
        
      }
    );
  } 
  addedPaymentNames() {
    this.service.getPaymentData(`?filterByBrand=1&brand_id=${this.brandGet}`).subscribe(res => {
      console.log("res", res)
      this.paymentNames = res.body;
    })
  }
  createPayment() {
    const payload = {
      payment_type_english: this.paymentTypeAdding.value.payment_type_english,
      payment_type_arabic: this.paymentTypeAdding.value.payment_type_arabic,
      brand_id: this.paymentTypeAdding.value.brand,
      branch_id: this.paymentTypeAdding.value.branch
    }
    console.log("clicked");
    if (this.paymentTypeAdding.valid) {
      this.service.cardTypesaddPayment(payload).subscribe(res => {
        console.log("res", res);
        if (res.status) {
          this.service.openSnackBar("Payment Type Added Successfully");
          this.addedPaymentNames();
          this.paymentTypeAdding.reset();
        }
      },
      error => {
        this.service.openSnackBar(error.error.message);
      }
      )
    }else{
      this.service.openSnackBar("Please Fill All the Fields")
    }
  }

  editData(data) {
    this.ShowEdit = data.payment_id;
    this.paymentTypeAddingEdit.patchValue({
      payment_id: data.payment_id,
      payment_type_english: data.payment_type_english,
      payment_type_arabic: data.payment_type_arabic,
      brand: data.brand.brand_id,
      branch: data.branch.branch_id,     
    })
  }

  updatePayment(data) {
    // alert(JSON.stringify(data.payment_type_english))
    // alert(JSON.stringify(this.paymentTypeAddingEdit.value.payment_type_english))

    if(data.payment_type_english == this.paymentTypeAddingEdit.value.payment_type_english)
    {
      const payload = {
        payment_id: this.paymentTypeAddingEdit.value.payment_id,
        payment_type_arabic: this.paymentTypeAddingEdit.value.payment_type_arabic,
        brand_id: data.brand_id,
        branch_id: data.branch_id
  
      }
      console.log("payload", payload)
        this.service.updatePaymentData(payload).subscribe(res => {
          console.log("res",)
          if (res.status) {
            this.service.openSnackBar("Updated Successfully");
            this.addedPaymentNames();
            this.ShowEdit = ''
          }
        },
        error => {
          this.ShowEdit = ''
          this.service.openSnackBar(error.error.message);           
        }
        )
    }else{
      const payload = {
        payment_id: this.paymentTypeAddingEdit.value.payment_id,
        payment_type_english: this.paymentTypeAddingEdit.value.payment_type_english,
        payment_type_arabic: this.paymentTypeAddingEdit.value.payment_type_arabic,
        brand_id: data.brand_id,
        branch_id: data.branch_id
  
      }
      console.log("payload", payload)
        this.service.updatePaymentData(payload).subscribe(res => {
          console.log("res",)
          if (res.status) {
            this.service.openSnackBar("Updated Successfully");
            this.addedPaymentNames();
            this.ShowEdit = ''
          }
        },
        error => {
          this.ShowEdit = ''
          this.service.openSnackBar(error.error.message);           
        }
        )
    }   

  }
  popUp() {
    document.getElementById('id01').style.display = 'block';
  }

  popUpOff() {
    document.getElementById('id01').style.display = 'none';
  }
  deletePaymentData(data) {
    this.paymentIdDelete = data.payment_id;
    this.popUp()
  }

  deletePayment(data) {
    const id = `?id=${ this.paymentIdDelete}`
    this.service.deletePayment(id).subscribe(res => {
      this.service.openSnackBar("Payment Deleted Successfully");
      this.popUpOff();
      this.addedPaymentNames();
    })
  }

}
