import { LocalStorageService } from "ngx-store";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControlDirective,
  FormControl,
  FormArray,
} from "@angular/forms";
import { PosBackOfficeService } from "../providers/pos-back-office.service";
import { log } from "util";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent implements OnInit {
  userId: any;
  index: any;
  i: any;
  textAlign: string;
  allUsersBackup: any;
  showAvailable: boolean;
  noMatchingResults: boolean;
  editUserDetails: any;
  showRoleCC: boolean;
  show = true;
  show1 = true;
  space:string;
  showSubmenu: boolean;
  showPosubMenu: boolean;
  reportsPage: boolean;
  reportsPages: boolean;
  showStocks: boolean;
  pageAccess: FormGroup;
  editPageAccess: FormGroup;
  editSegmentAccess: FormGroup;
  segmentAccess: FormGroup;
  allBranch: any;
  isBackoffice = false;
  hideBranch = true;
  all: any;
  orgInfo:any;
  orgGet:any=[];
  constructor(
    public ls: LocalStorageService,
    private fb: FormBuilder,
    public service: PosBackOfficeService
  ) { }
  domainStatus = true;
  brands:any;
  branches:any;
  allUsers = [];
  p: number;
  currentUser: string;
  roleEdit: FormGroup;
  hide = true;
  formSubmitAttempt: boolean;
  hide1 = true;
  registration: FormGroup;
  role: FormGroup;
  showRole = false;
  posCC = false;
  showRole1 = true;
  showBrandData: boolean;
  showBranchData: boolean;
  item;
  isBackofficeRoles = false;
  isPosRoles = false;
  backofficeroles: any = [];
  hidedone: boolean;
  permissionarr: any = [];
  seg: any;
  segs: any;
  isPos = false;
  takeout: boolean;
  staffmeal: boolean;
  cateringg: boolean;
  deliveryy: boolean;
  dinein: boolean;
  carservice: boolean;
  takeouts: boolean;
  staffmeals: boolean;
  cateringgs: boolean;
  deliveryys: boolean;
  dineins: boolean;
  carservices: boolean;
  orgData: any;


  possetupSubpage = [
    'Printers', 'Discounts & Offers', 'Table Setup', 'Delivery Setup', 'Online Setup', 'Area Setup' 
  ];
  // 'Complaints'
  posmenuSubpage = [
    'Item Catalog', 'Menu Category', 'Menu Item Group', 'Menu Master', 'Refund Order', 'RecipeManagement'
  ];
  reportsSubmenu = [
    'Sales Summary', 'Sales', 'Payment', 'Product Mix', 'Discount', 'Inventory', 'Order Search', 
  ];
  // 'Agent Performance', 'Hours Report'
  stocksSubmenu = [
    'Category', 'Supplier', 'Item', 'FoodCost'
  ]
  ngOnInit() {
    const { name } = this.ls.get("bouser");
    this.currentUser = name;
    console.log("contents", this.currentUser);

    this.roleEdit = this.fb.group({
      name: ["", [Validators.required]],
      email: [
        "",
        [
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9w.#&()_*-]+@[a-zA-Z0-9]+(.)?[a-zA-Z]{2,4}$"
          ),
        ],
      ],
      role: ["", [Validators.required]],
      phone: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{8,10}$')]],
      Gender:['',[Validators.required]],
      userName: ["", [Validators.required]],
      nameArabic: [""],
      domin: ["", [Validators.required]],
      _id: ["", [Validators.required]],
      reference: this.fb.group({
        Organization_id: [''],
        brand_id: Number,
        branch_id: Number,
        brand_name: "",
        branch_name: "",
      }),
      password: "",
    });
    this.editSegmentAccess = this.fb.group({
      take_out: [false],
      car_service: [false],
      staff_meal: [false],
      dine_in: [false],
      catering: [false],
      delivery: [false],
    });
    this.editPageAccess = this.fb.group({
      dashboard: [false],
      brand: [false],
      branch: [false],
      user: [false],
      posSetup: [false],
      printers: [false],
      discountandoffers: [false],
      tableSetup: [false],
      deliverySetup: [false],
      onlineSetup: [false],
      areaSetup: [false],
      // complaints: [false],
      posMenu: [false],
      itemCatalog: [false],
      menuCategory: [false],
      menuItemGroup: [false],
      menuMaster: [false],
      refundOrder: [false],
      recipeManagement: [false],
      reports: [false],
      inventoryReports: [false],
      salesSummary: [false],
      sales: [false],
      payment: [false],
      productMix: [false],
      discount: [false],
      inventory: [false],
      orderSearch: [false],
      // agentPerformance: [false],
      // hoursReport: [false],
      dailysales: [false],
      grvbyitem: [false],
      wastagesummary: [false],
      stock: [false],
      category: [false],
      supplier: [false],
      item: [false],
      foodCost: [false],
    })
    this.role = this.fb.group({
      email: ["", [Validators.required]],
      userName: ["", [Validators.required]],
      role: ["", [Validators.required]],
      domin: ["", [Validators.required]],
    });
    this.registration = this.fb.group({
      name: ["", [Validators.required]],
      nameArabic: ["", [Validators.required]],
      email: [
        "",
        [ Validators.required,
          Validators.email, 
          Validators.pattern( 
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ 
          ), 
        ],
      ],
      userName: ["", [Validators.required]],
      password: ["", [Validators.required]],
      role: ["", [Validators.required]],
      phone: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{8,10}$')]],
      Gender:['',[Validators.required]],
      domin: ["", [Validators.required]],
      reference: this.fb.group({
        brand_id: Number,
        branch_id: Number,
        brand_name: "",
        branch_name: "",
      }),
      im: this.ls.get('im')
    });
    this.segmentAccess = this.fb.group({
      take_out: [false],
      car_service: [false],
      staff_meal: [false],
      dine_in: [false],
      catering: [false],
      delivery: [false],
    });
    this.pageAccess = this.fb.group({
      dashboard: [false],
      brand: [false],
      branch: [false],
      user: [false],
      posSetup: [false],
      printers: [false],
      discountandoffers: [false],
      tableSetup: [false],
      deliverySetup: [false],
      onlineSetup: [false],
      areaSetup: [false],
      // complaints: [false],
      posMenu: [false],
      itemCatalog: [false],
      menuCategory: [false],
      menuItemGroup: [false],
      menuMaster: [false],
      refundOrder: [false],
      recipeManagement: [false],
      reports: [false],
      inventoryReports: [false],
      salesSummary: [false],
      sales: [false],
      payment: [false],
      productMix: [false],
      discount: [false],
      inventory: [false],
      orderSearch: [false],
      // agentPerformance: [false],
      // hoursReport: [false],
      dailysales: [false],
      grvbyitem: [false],
      wastagesummary: [false],
      stock: [false],
      category: [false],
      supplier: [false],
      item: [false],
      foodCost: [false],
    })
    this.getOrgDetails();
    this.getUserDetails();
    this.inputAlignment();
    this.backofficeroles = [
      "DashBoard",
      "Brand",
      "Branch",
      "POSSetup",
      "Printer",
      "Discount",
      "TableSetup",
      "Delivery Setup",
      "OnlineSetup",
      "AreaSetup",
      "POSMenu",
      "Reports",
      "Stock",
    ];

  }
  selectname() {
    console.log("value", this.pageAccess.value.submenu);
    if (this.pageAccess.value.posSetup == true) {
      this.showSubmenu = true;
    } if (this.pageAccess.value.posMenu == true) {
      this.showPosubMenu = true
    } if (this.pageAccess.value.reports == true) {
      this.reportsPage = true
    } if (this.pageAccess.value.inventoryReports == true) {
      this.reportsPages = true
    } if (this.pageAccess.value.stock == true) {
      this.showStocks = true
    } if (this.pageAccess.value.posSetup == false) {
      this.showSubmenu = false;
    } if (this.pageAccess.value.posMenu == false) {
      this.showPosubMenu = false
    } if (this.pageAccess.value.reports == false) {
      this.reportsPage = false
    } if (this.pageAccess.value.inventoryReports == false) {
      this.reportsPages = false
    } if (this.pageAccess.value.stock == false) {
      this.showStocks = false
    }
  }
  selectnameforEdit() {
    if (this.editPageAccess.value.posSetup == true) {
      this.showSubmenu = true;
    } if (this.editPageAccess.value.posMenu == true) {
      this.showPosubMenu = true
    } if (this.editPageAccess.value.reports == true) {
      this.reportsPage = true
    } if (this.editPageAccess.value.inventoryReports == true) {
      this.reportsPages = true
    } if (this.editPageAccess.value.stock == true) {
      this.showStocks = true
    } if (this.editPageAccess.value.posSetup == false) {
      this.showSubmenu = false;
    } if (this.editPageAccess.value.posMenu == false) {
      this.showPosubMenu = false
    } if (this.editPageAccess.value.reports == false) {
      this.reportsPage = false
    } if (this.editPageAccess.value.inventoryReports == false) {
      this.reportsPages = false
    } if (this.editPageAccess.value.stock == false) {
      this.showStocks = false
    }
  }
  
  patchforEdit(itemList) {
    console.log("itemList", itemList)
    if (itemList.posSetup == true) {
      this.showSubmenu = true;
    }
    if (itemList.posMenu == true) {
      this.showPosubMenu = true
    }
    if (itemList.reports == true) {
      this.reportsPage = true
    }
    if (itemList.inventoryReports == true) {
      this.reportsPages = true
    }
    if (itemList.stock == true) {
      this.showStocks = true
    }
    if (itemList.posSetup == false) {
      this.showSubmenu = false;
    }
    if (itemList.posMenu == false) {
      this.showPosubMenu = false
    }
    if (itemList.reports == false) {
      this.reportsPage = false
    }
    if (itemList.inventoryReports == false) {
      this.reportsPages = false
    }
    if (itemList.stock == false) {
      this.showStocks = false
    }
  }
  patchman1() {
    const one = this.registration.get("name").value;
    this.registration.patchValue({ name: one.replace(/^ +/gm, "") });
    const two = this.registration.get("userName").value;
    this.registration.patchValue({ userName: two.replace(/^ +/gm, "") });
  }

  inputAlignment() {
    const lang = this.ls.get("language");
    if (lang == "Arabic") {
      this.textAlign = "textRight";
    } else {
      this.textAlign = "textLeft";
    }
  }

  selectDomin(value) {
    console.log("value", value)
    if (value === "backOffice") {
      console.log("bo selected");
      this.registration.patchValue({ role: "bo" });
      this.showBranchData = true;
      this.showRole = true;
      this.posCC = false;
      this.isBackofficeRoles = false;
      this.isPosRoles = false;
      this.isBackoffice = true;
      this.isPos = false;
      this.hideBranch = true
    }
    if (value === "pos") {
      this.registration.patchValue({ role: "" });
      this.showBranchData = true;
      this.showRole = true;
      this.posCC = true;
      this.isBackofficeRoles = false;
      this.isPosRoles = false;
      this.isBackoffice = false;
      this.hideBranch = true;
      this.isPos = true;


    }
    if (value === "callcenter") {
      this.registration.patchValue({ role: "cc" });
      this.showBranchData = true;
      this.showRole = true;
      this.posCC = true;
      this.isBackofficeRoles = false;
      this.isPosRoles = false;
      this.isBackoffice = false;
      this.isPos = false;
      this.hideBranch = true
    }
  }

  isFieldValid(field: string) {
    return (
      (!this.registration.get(field).valid &&
        this.registration.get(field).touched) ||
      (this.registration.get(field).untouched && this.formSubmitAttempt)
    );
  }

  submit() {
    console.log("1111", this.registration.value);
    this.registration.value.im;
    const { id } = this.ls.get("bouser");
    if (this.showRole) {
      if (!this.showBrandData) {
        return this.service.openSnackBar("Please select brand details");
      }
      if (this.hideBranch == true) {
        if (!this.showBranchData) {
          return this.service.openSnackBar("Please select branch details");
        }
      } else {
      }

      if (
        !(
          this.registration.value.role !== "" &&
          this.registration.value.role !== null
        )
      ) {
        return this.service.openSnackBar("Please select role details");
      }

      if (id) {
        this.registration.value.im = id;
      }

      if (this.registration.value.domin === "backOffice") {
        this.registration.value.reference;
      }
      if (this.registration.value.role === "warehouse") {

        delete this.registration.value.reference.branch_name;
      }
      console.log("this entering the  values", this.registration.value);
      console.log("this page access ", this.pageAccess.value);
      if (this.registration.value.domin === "backOffice") {
        const BoAccess = {
          menus: this.pageAccess.value
        }
        this.registration.value['BoAccess'] = BoAccess;
      }
      if (this.registration.value.domin === "pos") {
        this.registration.value['segment_access'] = this.segmentAccess.value;
      }
      this.service.adminReg(this.registration.value).subscribe(
        (result) => {
          this.service.openSnackBar("Registration Successful");
          this.registration.reset();
          this.registration.patchValue({ reference: {} });
          this.getUserDetails();
          this.showRole = false;
          this.isBackoffice = false;
          this.isPos = false;
          this.branches = [];
        },
        (error) => {
          this.service.openSnackBar("User already exist");
          if (error.error.data === "Password must be at least 6 characters") {
            this.service.openSnackBar("Password must be at least 6 characters");
          } else {
            this.service.openSnackBar(error.error.message);
          }
        }
      );
    } else {
      console.log("this page access ", this.pageAccess.value);
      if (id) {
        this.registration.value.im = id;
      }
      if (this.registration.value.domin === "backOffice") {
        delete this.registration.value.reference;
      }

      if (this.registration.value.domin === "backOffice") {
        const BoAccess = {
          menus: this.pageAccess.value
        }
        this.registration.value['BoAccess'] = BoAccess;
      }
      console.log("this.registration.value", this.registration.value)
      this.service.adminRegister(this.registration.value).subscribe(
        (result) => {
          this.service.openSnackBar("Registration Successful");
          this.registration.reset();
          this.registration.patchValue({ reference: {} });
          this.getUserDetails();
          this.posCC = false;
          this.isBackofficeRoles = false;
          this.isPosRoles = false;
          this.isBackoffice = false;
          this.isPos = false;
        },
        (error) => {
          if (error.error.data === "Password must be at least 6 characters") {
            this.service.openSnackBar("Password must be at least 6 characters");
          } else {
            this.service.openSnackBar(error.error.message);
          }
        }
      );
    }
  }
  getOrgDetails() {
    this.orgGet = [];
    this.service
      .getOrgDetails(`?userId=${this.ls.get("userId")}`)
      .subscribe((result) => {
        this.orgData = result.body;
        for (const data of this.orgData) {
          this.orgGet.push(data.Organization_id);
        }
        this.getBrand();
    });
  }
  getBrand() {
    this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(
      (result) => {
        this.brands = result.body;
      },
      (error) => {
        console.log("error", error);
      }
    );
  }
  getBranch(brand) {
    this.showBrandData = true;
    this.service.getBranchDetail(`?filterbybrand=1&brand_id=${brand}`).subscribe((r) => {
      this.branches = r.body;

    });
  }


  async editRole(item) {
    await this.getBranch(item.reference.brand_id);
    console.log("item 123", item)
    this.editUserDetails = item;
    console.log("this.editUserDetails", this.editUserDetails)
    console.log("item", item.domin);
    if (item.domin === "pos") {
      console.log("pos entry")
      await this.getBranch(item.reference.brand_id);
      await this.getUserDetails();
      for (let i = 0; i < this.all.length; i++) {
        this.seg = this.all[i]
        console.log("why", this.seg)
        if (item.userName == this.seg.userName) {
          this.editSegmentAccess.patchValue({
            take_out: this.seg.segment_access.take_out,
            staff_meal: this.seg.segment_access.staff_meal,
            catering: this.seg.segment_access.catering,
            delivery: this.seg.segment_access.delivery,
            dine_in: this.seg.segment_access.dine_in,
            car_service: this.seg.segment_access.car_service,

          });

        }
      }

      await this.service
        .getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`)
        .subscribe(
          (result) => {
            if (result.body !== []) {
              this.roleEdit.get("reference").patchValue(
                item.reference
              );
              console.log("asdfghjk", this.roleEdit.value)
            } else {
              delete this.roleEdit.value.reference;
              delete this.roleEdit.value.brand_name;
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
      const { email, role, domin, _id, name, userName, nameArabic,Gender,phone } = item;
      await this.selectDomin1(domin);
      this.roleEdit.patchValue({ email, role, domin, _id, name, userName, nameArabic,Gender,phone })
      this.popUp();
      this.isBackofficeRoles = false;
      this.isPosRoles = true;



    } else if (item.domin === "callcenter") {
      const { email, role, domin, _id, name, userName, nameArabic } = item;
      console.log("this.roleEdit item", item)
      await this.selectDomin1(domin);
      this.roleEdit.patchValue({ email, role, domin, _id, name, userName, nameArabic });
      console.log("this.roleEdit", this.roleEdit.value)
      this.popUp();
      this.isBackofficeRoles = false;
      this.isPosRoles = false;

    } else {
      console.log("other pos")
      this.domainStatus = false;
      const { email, role, domin, _id, name, userName, nameArabic } = item;
      await this.selectDomin1(domin);
      this.roleEdit.patchValue({ email, role, domin, _id, name, userName, nameArabic });
      const itemList = item.BoAccess.menus;
      this.patchforEdit(itemList);
      this.editPageAccess.patchValue({
        dashboard: item.BoAccess.menus.dashboard,
        brand: item.BoAccess.menus.brand, branch: item.BoAccess.menus.branch, user: item.BoAccess.menus.user, posSetup: item.BoAccess.menus.posSetup,
        printers: itemList.printers, discountandoffers: itemList.discountandoffers, tableSetup: itemList.tableSetup, deliverySetup: itemList.deliverySetup,
        onlineSetup: itemList.onlineSetup, areaSetup: itemList.areaSetup,  posMenu: itemList.posMenu,
        itemCatalog: itemList.itemCatalog, menuCategory: itemList.menuCategory, menuItemGroup: itemList.menuItemGroup, menuMaster: itemList.menuMaster,
        refundOrder: itemList.refundOrder, recipeManagement: itemList.recipeManagement, reports: itemList.reports, inventoryReports: itemList.inventoryReports, salesSummary: itemList.salesSummary,
        sales: itemList.sales, payment: itemList.payment, productMix: itemList.productMix, discount: itemList.discount, inventory: itemList.inventory, orderSearch: itemList.orderSearch,  dailysales: itemList.dailysales, grvbyitem: itemList.grvbyitem, wastagesummary: itemList.wastagesummary,
        stock: itemList.stock, category: itemList.category, supplier: itemList.supplier, item: itemList.item, foodCost: itemList.foodCost
      })
      delete this.roleEdit.value.reference;
      delete this.roleEdit.value.brand_name;
      this.popUp();
    }
    // complaints: itemList.complaints,
    // agentPerformance: itemList.agentPerformance, hoursReport: itemList.hoursReport,
  }

  popUp1() {
    document.getElementById("id02").style.display = "block";
  }

  popUpOff1() {
    document.getElementById("id02").style.display = "none";
  }

  popUpUpdate1() {
    document.getElementById("updateUser").style.display = "block";
  }

  popUpOffUpdate1() {
    document.getElementById("updateUser").style.display = "none";
  }

  delete(id, index) {
    (this.userId = id), (this.index = index);
    this.popUp1();
  }

  deleteUserModal() {
    this.service.getOders(this.userId).subscribe((res) => {
      if (res.status) {
        if (res.body.length > 0) {
          this.service.openSnackBar(
            "Order is already placed for this user you cant delete"
          );
          this.popUpOff1();
        } else {
          this.service.deleteUser(this.userId).subscribe(
            (result) => {
              this.allUsers.splice(this.index, 1);
              this.getUserDetails();
              this.service.openSnackBar("User Deleted");
              this.popUpOff1();
            },
            (error) => {
              this.service.openSnackBar(error.error.message);
            }
          );
        }
      }
    });
  }

  getUserDetails() {
    this.service.getAllUsers(`?im=${this.ls.get('im')}`).subscribe(
      (result) => {
        console.log("user data", result)
        this.all = result

        const arrayData = [];
        result.forEach((element) => {
          if (element.name !== this.currentUser) {
            arrayData.push(element);
          }
        });

        this.allUsers = arrayData;
        console.log("alluserdata", this.allUsers);
        this.allUsers.reverse();
        this.showRegistrationData(this.allUsers);
        if (this.allUsers.length > 0) {
          this.showAvailable = false;
        } else {
          this.showAvailable = true;
        }
        this.allUsersBackup = result.reverse();
      },
      (error) => {
        this.service.openSnackBar(error.error.message);
      }
    );
  }

  selectDomin1(value) {
    if (value === "backOffice") {
      console.log("entry")
      this.role.patchValue({ role: "bo" });
      this.roleEdit.patchValue({ role: "bo" });
      this.showRole1 = false;
      this.showRoleCC = false;
      this.domainStatus = false;
      this.isBackoffice = false;
      this.isPos = false;
      this.isBackofficeRoles = true;
      this.isPosRoles = false;
      this.roleEdit.get("reference").patchValue({
        brand_id: null,
        branch_id: null,
        brand_name: null,
        branch_name: null,
      });
    }
    if (value === "pos") {
      console.log("pos entry")
      this.roleEdit.patchValue({ role: null });
      this.showRole1 = true;
      this.showRoleCC = true;
      this.domainStatus = true;
      this.isBackofficeRoles = false;
      this.isPosRoles = true;
      this.service.getBrandDetail(`?filterbyorganization=1&Organization_id=${this.orgGet}`).subscribe(
        (result) => {
          this.brands = result.body;
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
    if (value === "callcenter") {
      console.log("callcenter entry")
      this.showRole1 = false;
      this.showRoleCC = true;
      this.domainStatus = false;
      this.isBackofficeRoles = false;
      this.isPosRoles = false;
      this.roleEdit.get("reference").patchValue({
        brand_id: null,
        branch_id: null,
        brand_name: null,
        branch_name: null,
      });
    }
  }

  popUp() {
    document.getElementById("id01").style.display = "block";
  }

  popUpOff() {
    document.getElementById("id01").style.display = "none";
  }

  update() {
    this.popUpUpdate1();
  }

  updateUserData() {
    const BoAccess = {
      menus: this.editPageAccess.value
    }
    this.roleEdit.value['BoAccess'] = BoAccess;
    console.log("boaccess", this.roleEdit.value['BoAccess'])
    if (this.roleEdit.value.domin == 'pos') {
      this.roleEdit.value['segment_access'] = this.editSegmentAccess.value;
      console.log("pos",this.roleEdit.value['segment_access'])
      delete this.roleEdit.value['BoAccess']
    } else if (this.roleEdit.value.domin == 'callcenter') {
      delete this.roleEdit.value['BoAccess']
    }
    this.service.updateUser(this.roleEdit.value).subscribe(
      (result) => {
        this.service.openSnackBar("User Updated!");
        this.orderUserUpdate();
        this.popUpOff();
        this.getUserDetails();
        this.editUserDetails = "";
        this.popUpOffUpdate1();
        this.editPageAccess.reset();
        this.editSegmentAccess.reset();
      },
      (error) => {
        this.service.openSnackBar(error.error.body);
      }
    );

  }

  selectRole() {
    console.log("selected value", this.registration.value.role)
    if (this.registration.value.role == 'warehouse') {
      console.log('yes');
      this.hideBranch = false;
      this.isPos = false;
    } else {
      console.log('no')
      this.hideBranch = true
    }
  }

  orderUserUpdate() {
    let update;
    if (this.editUserDetails.role == 'waiter') {
      update = {
        user_name: this.roleEdit.value.userName,
        user_name_arabic: this.roleEdit.value.nameArabic,
        user_id: this.editUserDetails._id,
        last_updated_by_id: this.editUserDetails._id,
        last_updated_by_name: this.roleEdit.value.userName,
        last_updated_by_name_arabic: this.roleEdit.value.nameArabic
      }
    }

    if (this.editUserDetails.role == 'cashier') {
      update = {
        user_name: this.roleEdit.value.userName,
        user_name_arabic: this.roleEdit.value.nameArabic,
        cahier_name_arabic: this.roleEdit.value.nameArabic,
        user_id: this.editUserDetails._id,
        last_updated_by_id: this.editUserDetails._id,
        last_updated_by_name: this.roleEdit.value.userName,
        last_updated_by_name_arabic: this.roleEdit.value.nameArabic
      }
    }
      if (this.editUserDetails.role == 'manager') {
      update = {
        user_name: this.roleEdit.value.userName,
        user_name_arabic: this.roleEdit.value.nameArabic,
        user_id: this.editUserDetails._id,
        last_updated_by_id: this.editUserDetails._id,
        last_updated_by_name: this.roleEdit.value.userName,
        last_updated_by_name_arabic: this.roleEdit.value.nameArabic
      }
    }

    if (this.editUserDetails.role = 'cc_agent' || this.editUserDetails.role == 'cc_supervisor') {
      update = {
        user_name: this.roleEdit.value.userName,
        user_name_arabic: this.roleEdit.value.nameArabic,
        user_id: this.editUserDetails._id,
        last_updated_by_id: this.editUserDetails._id,
        last_updated_by_name:this.roleEdit.value.userName,
        last_updated_by_name_arabic:this.roleEdit.value.nameArabic
      }
    }
    console.log("update", update)
  }

  brand_data(data) {
    this.registration.get("reference").patchValue({
      brand_name: data.brand_name,
    });
    this.getBranch(data.brand_id);
  }

  branch_data(data) {
    this.registration.get("reference").patchValue({
      branch_name: data.branch_name,
    });
    for (let i = 0; i < this.branches.length; i++) {
      this.seg = this.branches[i]
      if (data.branch_name == this.seg.branch_name) {
        this.takeout = this.seg.take_out;
        this.staffmeal = this.seg.staff_meal;
        this.cateringg = this.seg.catering;
        this.deliveryy = this.seg.delivery;
        this.dinein = this.seg.dine_in;
        this.carservice = this.seg.car_service;
        this.segmentAccess.patchValue({
          take_out: this.seg.take_out,
          staff_meal: this.seg.staff_meal,
          catering: this.seg.catering,
          delivery: this.seg.delivery,
          dine_in: this.seg.dine_in,
          car_service: this.seg.car_service,
        });
      }
    }

  }

  brand_data_edit(data) {
    this.roleEdit.get("reference").patchValue({
      brand_name: data.brand_name,
    });
  }

  branch_data_edit(data) {
    this.roleEdit.get("reference").patchValue({
      branch_name: data.branch_name,
    });
  }

  showRegistrationData(data) {
    if (data.length === 0) {
      this.noMatchingResults = true;
    } else {
      this.noMatchingResults = false;
    }
  }
  onpaste(event:ClipboardEvent){
    let clipboardData = event.clipboardData 
    let Text = clipboardData.getData('text');
    this.space= Text.replace(/^\s+|\s+$/gm,'')
    this.allUsers = this.allUsersBackup.filter((item) => {
      if (
        item.userName.toString().toLowerCase().indexOf(this.space.toLowerCase()) !==
        -1
      ) {
        return true;
      }
      return false;
    });
    this.showRegistrationData(this.allUsers);
  }
  applyFilter(filter: string) {
    this.space=filter.replace(/^\s+|\s+$/gm,'')

    this.allUsers = this.allUsersBackup.filter((item) => {
      if (
        item.userName.toString().toLowerCase().indexOf(this.space.toLowerCase()) !==
        -1
      ) {
        return true;
      }
      return false;
    });
    this.showRegistrationData(this.allUsers);
  }
  onpasteBranch(event:ClipboardEvent){
    let clipboardData = event.clipboardData 
    let Text = clipboardData.getData('text');
    this.space= Text.replace(/^\s+|\s+$/gm,'')
    this.allUsers = this.allUsersBackup.filter((item) => {
      if (item.domin == 'pos') {
        if (item.reference.branch_name != null || item.reference.branch_name != undefined) {
          if (
            item.reference.branch_name.toString().toLowerCase().indexOf(this.space.toLowerCase()) !==
            -1
          ) {
            return true;
          }
          return false;
        }
      }
    });
    this.showRegistrationData(this.allUsers);
  }
  applyFilterforbranch(filter: string) {
    this.space=filter.replace(/^\s+|\s+$/gm,'')

    this.allUsers = this.allUsersBackup.filter((item) => {
      if (item.domin == 'pos') {
        if (item.reference.branch_name != null || item.reference.branch_name != undefined) {
          if (
            item.reference.branch_name.toString().toLowerCase().indexOf(this.space.toLowerCase()) !==
            -1
          ) {
            return true;
          }
          return false;
        }
      }
    });
    this.showRegistrationData(this.allUsers);
  }
  onpasteBrand(event:ClipboardEvent){
    let clipboardData = event.clipboardData 
    let Text = clipboardData.getData('text');
    this.space= Text.replace(/^\s+|\s+$/gm,'')
    this.allUsers = this.allUsersBackup.filter((item) => {
      if (item.domin == 'pos') {
        if (item.reference.brand_name != null || item.reference.brand_name != undefined) {
          if (
            item.reference.brand_name.toString().toLowerCase().indexOf(this.space.toLowerCase()) !==
            -1
          ) {
            return true;
          }
          return false;
        }
      }
    });
    this.showRegistrationData(this.allUsers);
  }
  applyFilterforbrand(filter: string) {
    this.space=filter.replace(/^\s+|\s+$/gm,'')

    this.allUsers = this.allUsersBackup.filter((item) => {
      if (item.domin == 'pos') {
        if (item.reference.brand_name != null || item.reference.brand_name != undefined) {
          if (
            item.reference.brand_name.toString().toLowerCase().indexOf(this.space.toLowerCase()) !==
            -1
          ) {
            return true;
          }
          return false;
        }
      }
    });
    this.showRegistrationData(this.allUsers);
  }

  SelectBoPages(data, access) {
    console.log("rar", data, access);

    const obj = { [data]: access };
    this.permissionarr.push(obj);
  }

}
